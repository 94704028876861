import React, { useCallback, useContext, useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";

import styled from "styled-components";

import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import InputData from "../../../../components/Grid/InputData";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";

import { HistoricoAba } from "./HistoricoAba";
import { ReceitaExtraAba } from "./ReceitaExtraAba";
import { TransfConcedidaAba } from "./TransfConcedidaAba";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import { useCurrentYear } from "../../../../hooks/useCurrentYear";

import api from "../../../../utils/api";
// import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import {
  BodyTable,
  TituloTable,
} from "../../../Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/EstruturaReceita";
import { AuthContext } from "../../../../contexts/AuthContext";
import { CurrentCityContext } from "../../../../contexts/CurrentCityContext";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { optionsTranferencias } from "../GreReceita/optionsTranferenciasRecebidas";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const GdeDespesa = () => {
  // const dataAtual = ReturnDataAtual()
  //   .replace(/\//g, "-")
  //   .split("-")
  //   .reverse()
  //   .join("-");

  const { id: paramsIdGdeDespesa } = useParams();

  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();
  const { userAuthData } = useContext(AuthContext);
  const { currentCity } = useContext(CurrentCityContext);

  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });

  const [inputQdrde, setInputQdrde] = useState("");
  const [inputBeneficiario, setInputBeneficiario] = useState("");
  const [inputExercicio, setInputExercicio] = useState("");
  const [inputGuiaReceita, setInputGuiaReceita] = useState("");
  const [inputUnidadeGestoraValue, setInputUnidadeGestoraValue] =
    useState(null);
  const [inputTipoTransferenciaValue, setInputTipoTransferenciaValue] =
    useState(null);

  const [infosAddReceitaExtra, setInfosAddReceitaExtra] = useState({
    exercicio: "",
    guiaReceitaId: "",
    saldo: "",
    label: "",
    numero: "",
  });

  const [optionsBeneficiario, setOptionsBeneficiario] = useState([
    { id: "", name: "", cpf: "" },
  ]);

  const [optionsUnidadesGestoras, setOptionsUnidadesGestoras] = useState([
    { label: "", value: null, tipoNaturezaJuridica: "" },
  ]);

  const [optionsQdrde, setOptionsQdrde] = useState([
    {
      id: "",
      titulo: "",
      funcao: "",
      qdrdeIsTranferencia: false,
    },
  ]);

  const [optionsTipoTransferencia, setOptionsTipoTranferencias] =
    useState(optionsTranferencias);

  const [infosCadastro, setInfosCadastro] = useState({
    clienteId: currentClient.clienteId,
    num: "",
    evb: "",
    qdrdeId: "",
    qdrdeIsTranferencia: false,
    funcao: "",
    data: "",
    beneficiarioId: "",
    valor: 0,
    historico: "",
    tipoTransferencia: "",
    unidadeGestoraId: "",
    tipoNaturezaJuridica: "",
    receitaExtra: [
      { exercicio: "", guiaReceitaId: "", saldo: "", label: "", numero: "" },
    ],
  });

  const handleSelectQdrde = (option) => {
    const { funcao, qdrdeIsTranferencia } = option;

    setInfosCadastro((prev) => ({
      ...prev,
      qdrdeId: option.id,
      funcao,
      qdrdeIsTranferencia,
      tipoNaturezaJuridica: "",
      unidadeGestoraId: "",
      tipoTransferencia: "",
      receitaExtra: [
        { exercicio: "", guiaReceitaId: "", label: "", saldo: "", numero: "" },
      ],
    }));
    setInputExercicio("");
    setInputGuiaReceita("");
    setInfosAddReceitaExtra({
      exercicio: "",
      guiaReceitaId: "",
      label: "",
      saldo: "0,00",
      numero: "",
    });
    if (
      !qdrdeIsTranferencia &&
      abaSelecionada.nomeAba === "Transferência Concedida"
    )
      setAbaSelecionada({ nomeAba: "Histórico", numeroAba: 0 });
  };

  const handleClearQdrde = () => {
    setInputExercicio("");
    setInputGuiaReceita("");
    setInfosAddReceitaExtra({
      exercicio: "",
      guiaReceitaId: "",
      label: "",
      saldo: "0,00",
      numero: "",
    });
    if (infosCadastro.qdrdeIsTranferencia) {
      setAbaSelecionada({ nomeAba: "Histórico", numeroAba: 0 });
    }
    setInfosCadastro((prevState) => ({
      ...prevState,
      qdrdeId: "",
      qdrdeIsTranferencia: false,
      tipoNaturezaJuridica: "",
      unidadeGestoraId: "",
      tipoTransferencia: "",
      receitaExtra: [
        { exercicio: "", guiaReceitaId: "", label: "", saldo: "", numero: "" },
      ],
    }));
  };

  const handleSelectBeneficiario = (optionBeneficiario) => {
    setInfosCadastro((prev) => ({
      ...prev,
      beneficiarioId: optionBeneficiario.id,
    }));
  };

  const handleChangeValor = (_, valor) => {
    const valorFloat = parseFloat(valor.replace(",", "."));

    setInfosCadastro((prevState) => ({
      ...prevState,
      valor: valorFloat,
    }));
  };

  const handleRegisterGde = async () => {
    console.log(infosCadastro);

    // ao mudar o qdrde limpar os campos de tranferencia e ano
    const infosRegisterGde = {
      clienteId: infosCadastro.clienteId,
      qdrdeId: infosCadastro.qdrdeId,
      data: infosCadastro.data,
      beneficiarioId: infosCadastro.beneficiarioId,
      valor: infosCadastro.valor,
      historico: infosCadastro.historico,
      // unidadeGestoraId: infosCadastro.unidadeGestoraId,
      // tpTransferencia: infosCadastro.tipoTransferencia,
      receitas: infosCadastro.receitaExtra,
    };
    console.log(infosRegisterGde);
    const hasInfosRegisterGde = Object.values(infosRegisterGde).every(
      (info) => info !== ""
    );

    if (!hasInfosRegisterGde) {
      toast.info("Preencha todas as informações");
      return;
    }

    const hasGuiaReceita = infosRegisterGde.receitas.every(
      (info) => info.guiaReceitaId
    );

    if (!hasGuiaReceita) {
      toast.info("Adicione uma Guia de Receita.");
      return;
    }

    console.log(infosRegisterGde.receitas);
    
    const totalReceitas = infosRegisterGde.receitas.reduce((acc, receita) =>{
      console.log(parseFloat(receita.saldo));
      return acc += parseFloat(receita.saldo)
    },0)

    console.log(totalReceitas);
    
    if(totalReceitas !== parseFloat(infosRegisterGde.valor)){
      toast.info('O valor da despesa deve ter o total das guias de receita.')
      return
    }

    const formattedInfosGuiaReceita = infosRegisterGde.receitas.map((item) => ({
      guiaId: item.guiaReceitaId,
      exercicio: item.exercicio,
      saldo: item.saldo,
    }));

    const infosGde = {
      ...infosRegisterGde,
      unidadeGestoraId:infosCadastro.qdrdeIsTranferencia ?  infosCadastro.unidadeGestoraId : '',
        tpTransferencia: infosCadastro.qdrdeIsTranferencia ? infosCadastro.tipoTransferencia : '',
      receitas: formattedInfosGuiaReceita,
    };

    // if (infosCadastro.qdrdeIsTranferencia) {
    //   const infos = {
    //     ...infosGde,
        
    //   };
    //   const hasInfosRegisterGde = Object.values(infos).every(
    //     (info) => info !== ""
    //   );

    //   if (!hasInfosRegisterGde) {
    //     toast.info("Preencha todas as informações");
    //     return;
    //   }
    //   console.log(infos);

    //   await postInfosGde(infos);
    //   return;
    // }
    console.log(infosGde);

    if(paramsIdGdeDespesa){
      await putInfosGde(infosGde)
      toast.info('Rota de atualizar não implementado')
      return
    }

    await postInfosGde(infosGde);
  };

  const resetFieldsInfosCadastro = () => {
    setInfosCadastro({
      clienteId: currentClient.clienteId,
      num: "",
      evb: "",
      qdrdeId: "",
      qdrdeIsTranferencia: false,
      funcao: "",
      data: "",
      beneficiarioId: "",
      valor: "0,00",
      historico: "",
      tipoTransferencia: "",
      unidadeGestoraId: "",
      tipoNaturezaJuridica: "",
      receitaExtra: [
        { exercicio: "", guiaReceitaId: "", saldo: "", label: "", numero: "" },
      ],
    });
    setInputQdrde("");
    setInputBeneficiario("");
    setInputExercicio("");
    setInputGuiaReceita("");
    setInfosAddReceitaExtra({
      exercicio: "",
      guiaReceitaId: "",
      saldo: "",
      label: "",
      numero: "",
    });
  };

  const postInfosGde = async (infos) => {
    try {
      await api.post("gde", infos);
      resetFieldsInfosCadastro();
      toast.success("Cadastro realizado com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao criar o cadastro. Tente novamente mais tarde.");
    }
  };

  const putInfosGde = async (infos) => {
    console.log(infos);
    
  };

  const getDataQdrde = useCallback(async () => {
    console.log("executou");
    try {
      const resp = await api.get(
        `qdrde/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;
      console.log(data);

      const dataQdrde = data.map((item) => {
        const titulo = `${item.categoriaContaPcaspInfo[0].conta} - ${item.titulo}`;

        const qdrdeIsTranferencia = item.categoriaContaPcaspInfo[0].titulo
          .toLowerCase()
          .includes("transferências");
        return {
          id: item.id,
          titulo,
          funcao: item.categoriaContaPcaspInfo[0].funcao,

          qdrdeIsTranferencia,
        };
      });
      setOptionsQdrde(dataQdrde);
    } catch (error) {
      console.log(error);
    }
  }, [currentClient, currentYear]);

  const fetchOptionsBeneficiario = useCallback(async () => {
    console.log("oi");

    try {
      const resp = await api.get(`cpf/por-cliente/${currentClient.clienteId}`);
      const data = resp.data;
      const optionsCpf = data.map((item) => {
        return {
          id: item.pessoaId,
          name: item.nomeCompleto,
          cpf: FormatCpfPaste(item.cpf),
        };
      });

      setOptionsBeneficiario(optionsCpf);
    } catch (error) {
      console.log(error);
    }
  }, [currentClient]);

  const formattedUnidadesGestoras = useCallback(() => {
    console.log("executou");

    const unidades = userAuthData.clientes
      .map((item) => {
        const hasCityUnidade =
          item.endereco.municipio === currentCity.municipio &&
          item.endereco.estado === currentCity.estado;
        const currentClientSelected =
          currentClient.clienteId === item.clienteId;

        if (!hasCityUnidade || currentClientSelected)
          return { label: "", value: null };
        return {
          label: item.nomeFantasia,
          value: item.clienteId,
          tipoNaturezaJuridica: item.tipoNaturezaJuridica,
        };
      })
      .filter((item) => item.value);
    console.log(unidades);

    setOptionsUnidadesGestoras(unidades);
  }, [currentCity, userAuthData, currentClient]);

  const formattedGdeDespesaById = (dataGde) => {
    console.log(dataGde);
    console.log(optionsQdrde);

    const infosQdrde = optionsQdrde.find(
      (option) => option.id === dataGde.qdrdeId
    );

    console.log(infosQdrde);

    if (!infosQdrde) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
      return;
    }

    // const num = dataGde.numero.replace(/^0+/, "");
    const date = dataGde.data.split("T")[0];
    const [year, month, day] = date.split("-");
    const dateFormatted = `${day}/${month}/${year}`;

    const unidadeGestora = optionsUnidadesGestoras.find(
      (unidade) => unidade.value === dataGde.unidadeGestoraId
    );

    console.log(unidadeGestora);

    if (!unidadeGestora && dataGde.unidadeGestoraId !== null) {
      toast.error("Erro ao buscar Unidade. Tente novamente mais tarde.");
      return;
    }

    const formattedReceitaExtra = dataGde.receitas.map((receita) => ({
      exercicio: receita.exercicio,
      guiaReceitaId: receita.gdeId,
      saldo: receita.saldo,
      label: `${receita.greInfo.numero.replace(/^0+/, "")} - ${
        dataGde.qdrdeInfo[0].titulo
      }`,
      numero: receita.greInfo.numero.replace(/^0+/, ""),
    }));

    const infosGde = {
      clienteId: dataGde.clienteId,
      num:dataGde.numero,
      evb: "",
      qdrdeId: dataGde.qdrdeId,
      qdrdeIsTranferencia: infosQdrde.qdrdeIsTranferencia,
      funcao: infosQdrde.funcao,
      data: dateFormatted,
      beneficiarioId: dataGde.beneficiarioId,
      valor: dataGde.valor,
      historico: dataGde.historico,
      tipoTransferencia: dataGde.tpTransferencia ?? "",
      unidadeGestoraId: dataGde.unidadeGestoraId ?? "",
      unidadeGestora: unidadeGestora?.label ?? "",
      tipoNaturezaJuridica: unidadeGestora?.tipoNaturezaJuridica ?? "",
      receitaExtra: formattedReceitaExtra,
    };
    console.log(infosGde);

    const filterOptionsTipoTranferencia = optionsTipoTransferencia.filter(
      (option) => option.label === dataGde.tpTransferencia
    );

    setInfosCadastro(infosGde);
    setInputQdrde(infosQdrde.titulo);
    setInputBeneficiario(
      `${dataGde.beneficiarioInfo.cpf} - ${dataGde.beneficiarioInfo.nomeCompleto}`
    );
    setInputUnidadeGestoraValue(unidadeGestora.value);
    setOptionsTipoTranferencias(
      filterOptionsTipoTranferencia.length
        ? filterOptionsTipoTranferencia
        : optionsTipoTransferencia
    );
    setInputTipoTransferenciaValue(parseInt(dataGde.tpTransferencia[0]) ?? "");
  };

  const fetchDataGdeDespesaById = useCallback(async () => {
    try {
      const resp = await api.get(`gde/${paramsIdGdeDespesa}`);
      const data = resp.data;
      console.log(data);

      const hasOptionsQdrde = optionsQdrde.filter((qdrde) => qdrde.id).length;
      const hasOptionsUnidade = optionsUnidadesGestoras.filter(
        (unidade) => unidade.value
      ).length;
      if (hasOptionsQdrde && hasOptionsUnidade) {
        formattedGdeDespesaById(data);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsQdrde, optionsUnidadesGestoras, paramsIdGdeDespesa]);

  useEffect(() => {
    console.log("entrou");

    if (paramsIdGdeDespesa) {
      console.log("chamado");

      fetchDataGdeDespesaById();
    }
  }, [fetchDataGdeDespesaById, paramsIdGdeDespesa]);

  useEffect(() => {
    console.log("executou");
    formattedUnidadesGestoras();
  }, [formattedUnidadesGestoras]);

  useEffect(() => {
    getDataQdrde();
    fetchOptionsBeneficiario();
  }, [fetchOptionsBeneficiario, getDataQdrde]);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"GDE - Despesa"}
        PaginaSubtitulo={"Cadastro"}
        ButtonSaveFunction={handleRegisterGde}
        RowTitle={"Despesa Extraorçamentária - GDE"}
        PaginaConsulta={"/contabilidade/extra/gde-despesa/consulta"}
        BotaoNovoAtivo={false}
      />
      <ToastContainer />
      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput label="Número" placeholder="Número" disabled />
        </CColNumero>
        <CCol md={2}>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="EVB"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="QDRDE"
            options={optionsQdrde}
            labelFormat={(item) => `${item.titulo} `}
            inputValue={inputQdrde}
            setInputValue={setInputQdrde}
            onOptionSelect={handleSelectQdrde}
            iconClearFunction={handleClearQdrde}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            onChange={(e) =>
              setInfosCadastro((prev) => ({
                ...prev,
                data: e.target.value,
              }))
            }
            externalValue={infosCadastro.data}
          />
        </CCol>
      </RowForm>

      {infosCadastro.funcao && (
        <RowInfosAcao>
          <CCol>
            <p>
              <span>{infosCadastro.funcao}</span>
            </p>
          </CCol>
        </RowInfosAcao>
      )}

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="Beneficiário"
            options={optionsBeneficiario}
            labelFormat={(item) => `${item.cpf} - ${item.name}`}
            inputValue={inputBeneficiario}
            setInputValue={setInputBeneficiario}
            onOptionSelect={handleSelectBeneficiario}
          />
        </CCol>
        <CCol md={3}>
          <InputFormatRealBrasileiro
            iconReal
            onChange={handleChangeValor}
            externalValue={infosCadastro.valor}
          />
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={[
          "Histórico",
          "Fonte de Recursos",
          "Receita Extra",
          `${
            infosCadastro.qdrdeIsTranferencia ? "Transferência Concedida" : ""
          }`,
        ]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && (
        <HistoricoAba
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
        />
      )}
      {abaSelecionada.nomeAba === "Fonte de Recursos" && <FonteDeRecursosAba />}
      {abaSelecionada.nomeAba === "Receita Extra" && (
        <ReceitaExtraAba
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
          clienteId={currentClient.clienteId}
          inputExercicio={inputExercicio}
          setInputExercicio={setInputExercicio}
          inputGuiaReceita={inputGuiaReceita}
          setInputGuiaReceita={setInputGuiaReceita}
          infosAddReceitaExtra={infosAddReceitaExtra}
          setInfosAddReceitaExtra={setInfosAddReceitaExtra}
        />
      )}
      {abaSelecionada.nomeAba === "Transferência Concedida" && (
        <TransfConcedidaAba
          optionsUnidadesGestoras={optionsUnidadesGestoras}
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
          inputUnidadeGestoraValue={inputUnidadeGestoraValue}
          setInputUnidadeGestoraValue={setInputUnidadeGestoraValue}
          inputTipoTransferenciaValue={inputTipoTransferenciaValue}
          setInputTipoTransferenciaValue={setInputTipoTransferenciaValue}
          optionsTipoTransferencia={optionsTipoTransferencia}
          setOptionsTipoTranferencias={setOptionsTipoTranferencias}
        />
      )}

      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Procedimentos Contábeis</span>
        </div>
      </RowTituloAba>
      <RowForm style={{ marginTop: "4px" }}>
        <Table responsive>
          <thead>
            <tr>
              <TituloTable style={{ borderStyle: "none" }}>Título</TituloTable>
              <TituloTable style={{ borderStyle: "none" }}>
                Categoria
              </TituloTable>
              <TituloTable style={{ borderStyle: "none" }}>
                Natureza Saldo
              </TituloTable>
            </tr>
          </thead>
          <BodyTable
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              borderStyle: "none",
              borderColor: "transparent",
              borderBottomWidth: 0,
            }}
          >
            <tr style={{ backgroundColor: "#EBEFFF" }}>
              <td
                style={{
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderBottomLeftRadius: "8px",
                  borderTopLeftRadius: "8px",
                }}
              >
                02051082442001203844901500
              </td>
              <td
                style={{
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                }}
              >
                D 1.1.1.1.1.00.00 Caixa e Equivalentes de Caixa em Moeda
                Nacional
              </td>
              <td
                style={{
                  whiteSpace: "nowrap",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderBottomRightRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                R$ 260.000,00
              </td>
            </tr>
          </BodyTable>
        </Table>
      </RowForm>
    </Container>
  );
};

export default GdeDespesa;
