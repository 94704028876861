import React, { useState, useContext, useEffect } from "react";
import SideBarLogo from "../SideBar/SideBarLogo";
import "../SideBar/SideStyles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import {
  ButtonIcon,
  DivSelectSup,
  DivUsuarioPerfil,
  HeaderDiv,
  ImgHeaderUsuario,
  ParagraphUserName,
  SelectSuperior,
  ButtonNoStyles,
} from "./StylesHeader";
import UsuarioPerfil from "../../assets/NavBars/user.png";
import IconSistemas from "./IconSistemas";
import SubMenuPerfil from "../SubMenuPerfil/SubMenuPerfil";
import LocalSelectBox from "./LocalSelectBox";
import { AuthContext } from "../../contexts/AuthContext";
import { CSSTransition } from "react-transition-group";
import SideBarSistemas from "../SideBarSistemas/SideBarSistemas";
import { CurrentClientContext } from "../../contexts/CurrentClientContext";
import { CurrentCityContext } from "../../contexts/CurrentCityContext";
import {
  ButtonItem,
  CidadeGestoraSelectContainer,
  ContainerItems,
  PTitulo,
  SelectBar,
} from "./StylesBox";
import styled, { keyframes } from "styled-components";
import { TestButton } from "../../utils/TestButton";
import SeletorAnoHeader from "./SeletorAnoHeader";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 0.4;
  }
  to {
    opacity: 0;
  }
`;

const ScrollToTopButton = styled.div`
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 999;
  cursor: ${(props) => (props.show ? "pointer" : "auto")};
  animation: ${(props) => (props.show ? fadeIn : fadeOut)} 0.3s ease-in-out
    forwards;
  pointer-events: ${(props) => (props.show ? "auto" : "none")};

  svg {
    width: 40px;
    height: 40px;
  }
`;

const ScrollToTop = ({ show, onClick }) => (
  <ScrollToTopButton show={show} onClick={onClick}>
    <Icon icon="twemoji:up-arrow" width={80} height={80} />
  </ScrollToTopButton>
);

const TimerBox = styled.div`
  width: 70px;
  background-color: #515c70;
  border-radius: 8px;
  padding: 8.8px 20px 7px 20px;
  display: flex;
  justify-content: center;
  margin-right: 20px;
  font-size: 1.1em;
  color: ${(props) => (props.timerColor ? props.timerColor : "#ffffff")};
  box-shadow: 0px 2px 2px #9399a4;
`;

const Header = () => {
  // console.log("Tem um loop no componente de Header");
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [localSelectBoxOpen, setLocalSelectBoxOpen] = useState(false);
  const [openSideSistemas, setOpenSideSistemas] = useState(false);
  const [selectBarOpen, setSelectBarOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(1800); //1800 = 30m
  const [isActive, setIsActive] = useState(true);
  const [timerColor, setTimerColor] = useState("#ffffff");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { userAuthData } = useContext(AuthContext);
  const { currentClient, setCurrentClient } = useContext(CurrentClientContext);
  const { currentCity } = useContext(CurrentCityContext);
  const location = useLocation();
  // console.log(userAuthData);
  const nameUser = userAuthData.nome ? userAuthData.nome.split(" ")[0] : "N/D";

  const handleSubMenuToggle = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleSubMenuToggleBlur = () => {
    setSubMenuOpen(false);
  };

  const handleLocalSelectBoxOpen = () => {
    setLocalSelectBoxOpen(!localSelectBoxOpen);
  };

  const handleLocalSelectBoxBlur = () => {
    setLocalSelectBoxOpen(false);
  };

  const makeNavigateUrl = () => {
    const pathPrincipal = location.pathname.split("/")[1];
    switch (pathPrincipal.toLowerCase()) {
      case "acesso-interno":
        return "/home-test";
      case "planejamento":
        return "/planejamento/inicio";
      default:
        return "/contabilidade/inicio";
    }
  };

  const handleChangeEntity = (id) => {
    const cliente = userAuthData.clientes.find((item) => item.clienteId === id);
    const obj = cliente;
    console.log(obj);
    const userHistory = JSON.parse(localStorage.getItem("userHistory"));
    const newUserHistory = { ...userHistory };
    newUserHistory.clienteId = obj.clienteId;
    localStorage.setItem("userHistory", JSON.stringify(newUserHistory));
    setCurrentClient({
      ...obj,
      endereco: {
        estado: obj.endereco.estado,
        municipio: obj.endereco.municipio,
      },
    });

    const navigateUrl = makeNavigateUrl();
    navigate(navigateUrl);
  };

  const [entidadesGestorasDoCliente, setEntidadesGestorasDoCliente] = useState(
    []
  );

  useEffect(() => {
    let interval = null;

    const resetTimer = () => {
      setTimeLeft(1800);
      setTimerColor("#ffffff");
    };

    window.addEventListener("mousemove", resetTimer);

    if (isActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      if (timeLeft === 10) {
        setTimerColor("#d9534f");
      }
    } else if (isActive && timeLeft === 0) {
      setIsActive(false);
      setTimeout(() => {
        navigate("/");
      }, 1500);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
      window.removeEventListener("mousemove", resetTimer);
    };
  }, [isActive, timeLeft, navigate]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    if (currentCity && Object.keys(userAuthData).length > 0) {
      setEntidadesGestorasDoCliente(
        userAuthData.clientes.filter(
          (item) =>
            item.endereco.municipio === currentCity.municipio &&
            item.endereco.estado === currentCity.estado
        )
      );
    }
  }, [currentCity, userAuthData]);

  // const quantityManagementEntityMunicipality =
  //   currentCity &&
  //   userAuthData.clientes.filter(
  //     (item) =>
  //       item.endereco.municipio === currentCity.municipio &&
  //       item.endereco.estado === currentCity.estado
  //   );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 0) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(userAuthData).length > 0) {
      console.log(userAuthData);
    }
  }, [userAuthData]);

  useEffect(() => {
    const userHistory = JSON.parse(localStorage.getItem("userHistory"));

    if (userHistory) {
      const path = location.pathname.split("/")[1];
      const sistema = ![
        "gerenciador",
        "planejamento",
        "contabilidade",
      ].includes(path.toLowerCase())
        ? "gerenciador"
        : path;
      localStorage.setItem(
        "userHistory",
        JSON.stringify({
          ...userHistory,
          sistema: sistema,
        })
      );
      console.log(location);
    }
  }, [location]);

  const MAX_UNIDADE_GESTORA_SEM_SCROLL = 7;
  return (
    <HeaderDiv id="header">
      <SideBarLogo />
      <label htmlFor="check" id="btn_menu">
        <Icon
          width={21}
          style={{ marginLeft: "10px", marginRight: 0 }}
          icon="teenyicons:menu-solid"
        />
      </label>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <ButtonNoStyles
          onClick={() => handleLocalSelectBoxOpen()}
          onBlur={() => handleLocalSelectBoxBlur()}
        >
          <Icon
            height={27}
            style={{
              transform: "scale(1.27,1.2)",
              marginRight: "20px",
              marginLeft: "20px",
              cursor: "pointer",
            }}
            icon="ic:baseline-place"
          />
        </ButtonNoStyles>
        <CSSTransition
          in={localSelectBoxOpen}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <LocalSelectBox userAuthData={userAuthData} />
        </CSSTransition>
      </div>
      <TestButton
        onClick={() =>
          console.log(
            "userAuthData: ",
            userAuthData,
            "currentClient: ",
            currentClient
          )
        }
      >
        user
      </TestButton>
      {/* <button onClick={() => console.log(currentCity)}>current city</button> */}
      {!location.pathname.includes("acesso-interno") &&
      !location.pathname.includes("home-test") ? (
        <DivSelectSup>
          <SelectBar
            onClick={() => setSelectBarOpen(!selectBarOpen)}
            onBlur={() => setSelectBarOpen(false)}
          >
            {currentClient.nomeFantasia}
          </SelectBar>
          <CSSTransition
            in={selectBarOpen}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <CidadeGestoraSelectContainer>
              <PTitulo>Entidade Gestora</PTitulo>
              <ContainerItems
                hasScroll={
                  entidadesGestorasDoCliente?.length >
                  MAX_UNIDADE_GESTORA_SEM_SCROLL
                }
              >
                {entidadesGestorasDoCliente?.map((cliente, index) => (
                  <React.Fragment key={index}>
                    <ButtonItem
                      key={cliente.clienteId}
                      onClick={() => handleChangeEntity(cliente.clienteId)}
                      selectedButton={
                        currentClient.nomeFantasia === cliente.nomeFantasia
                      }
                    >
                      {cliente.nomeFantasia}
                    </ButtonItem>
                  </React.Fragment>
                ))}
              </ContainerItems>
            </CidadeGestoraSelectContainer>
          </CSSTransition>
        </DivSelectSup>
      ) : (
        <DivSelectSup>
          <SelectSuperior style={{ pointerEvents: "none", opacity: "0.6" }}>
            <option>Sistema de Gerenciador</option>
          </SelectSuperior>
        </DivSelectSup>
      )}

      {/* <NavLink to="/planejamento/tarefas-prazos-hoje">
        <Icon
          style={{ transform: "scale(1.136,1.111)", marginRight: "20px" }}
          width={40}
          icon="material-symbols:calendar-month"
          color="#515c70"
        />
      </NavLink> */}
      <SeletorAnoHeader />
      {timeLeft <= 1200 && (
        <TimerBox timerColor={timerColor}>{formatTime(timeLeft)}</TimerBox>
      )}
      <ButtonIcon
        style={
          openSideSistemas
            ? { marginLeft: "4px", pointerEvents: "none" }
            : { marginLeft: "4px" }
        }
        onClick={() => setOpenSideSistemas((prev) => !prev)}
      >
        <IconSistemas />
      </ButtonIcon>
      {/* 
      <ButtonIcon>
        <Icon
          style={{ transform: "scale(1.037,1.016)", marginRight: "20px" }}
          icon="mdi:bell"
        />
      </ButtonIcon>
      */}

      <SideBarSistemas
        openSideSistemas={openSideSistemas}
        setOpenSideSistemas={setOpenSideSistemas}
      />

      <DivUsuarioPerfil>
        <ParagraphUserName>
          Olá, <span>{nameUser}</span>
        </ParagraphUserName>
        <ButtonNoStyles
          onClick={handleSubMenuToggle}
          onBlur={handleSubMenuToggleBlur}
        >
          <ImgHeaderUsuario
            src={
              ![undefined, null, ""].includes(userAuthData.fotoPerfil)
                ? userAuthData.fotoPerfil
                : UsuarioPerfil
            }
          />
        </ButtonNoStyles>
        <CSSTransition
          in={subMenuOpen}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <SubMenuPerfil />
        </CSSTransition>
      </DivUsuarioPerfil>
      <ScrollToTop show={showScrollButton} onClick={scrollToTop} />
    </HeaderDiv>
  );
};

export default Header;
