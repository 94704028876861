import React, { useState } from "react";
import { Collapse, Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowForm,
} from "../../../../components/Grid/CCol";
import { FormControlListaDeInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import RowTitulo from "../../../../components/Grid/RowTitulo";
import { Icon } from "@iconify/react/dist/iconify.js";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
import AbaPagamento from "./AbaPagamento";
import { TestButton } from "../../../../utils/TestButton";
import AbaRetencao from "./AbaRetencao";
import { ToastContainer } from "react-toastify";

const Pagamento = () => {
  const [openedRetencao, setOpenedRetencao] = useState(false);
  const [values, setValues] = useState({
    pagamentos: [],
    retencao: [],
  });

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <ToastContainer />
      <TestButton onClick={() => console.log(values)}>values</TestButton>
      <HeaderCadastros
        NomePaginaTitulo={"Pagamento"}
        PaginaSubtitulo={"Cadastro"}
      />

      <AbaPagamento values={values} setValues={setValues} />

      <RowTitulo
        style={{
          borderRadius: `${openedRetencao ? "5px 5px 0px 0px" : " 5px"}`,
        }}
      >
        <CCol style={{ display: "flex", gap: "5px" }}>
          <Icon
            icon={openedRetencao ? "ph:minus-square" : "si:add-square-line"}
            width="24"
            height="24"
            style={{ color: "#515c70", cursor: "pointer" }}
            onClick={() => setOpenedRetencao((prev) => !prev)}
          />
          {/* <Icon
            icon="ph:minus-square"
            width="24"
            height="24"
            style={{ color: "#515c70" }}
          /> */}
          <p style={{ margin: "0px" }}>Retenção</p>
        </CCol>
      </RowTitulo>
      <Collapse in={openedRetencao}>
        <RowForm $borderRadiusType={2} className="mt-1">
          <AbaRetencao values={values} setValues={setValues} />
        </RowForm>
      </Collapse>

      <RowForm style={{ marginTop: "15px" }}>
        <RowAninhada>
          <CCol md={1}></CCol>
          <CCol></CCol>
          <CCol
            md={2}
            style={{
              fontWeight: "500",
              alignItems: "center",
              display: "flex",
            }}
            className={"cor_letra_primaria"}
          >
            Valor Líquido
          </CCol>
          <CCol md={3}>
            <FormControlListaDeInputs
              style={{
                borderRadius: "10px",
                padding: "15px",
                border: "none",
                textAlign: "end",
              }}
              value={(
                values?.pagamentos.reduce((acc, item) => {
                  const valor = item.valor.includes(",")
                    ? parseFloat(
                        item.valor.replace(/\./g, "").replace(",", ".")
                      )
                    : parseFloat(item.valor);
                  return acc + valor;
                }, 0) -
                values?.retencao.reduce((acc, item) => {
                  const valor = item.valor.includes(",")
                    ? parseFloat(
                        item.valor.replace(/\./g, "").replace(",", ".")
                      )
                    : parseFloat(item.valor);
                  return acc + valor;
                }, 0)
              )
                .toFixed(2)
                .replace(".", ",")}
              disabled
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
        </RowAninhada>
      </RowForm>

      <RowTitulo>Origem Financeira</RowTitulo>

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol>
          <FloatingLabelInput disabled placeholder={"Número"} />
        </CCol>
        <CCol>
          <MyAutoComplete
            placeholder={"Caixa e Equivalente Caixa"}
            options={[{ id: 0, valor: "teste" }]}
            labelFormat={(option) => `${option.id} - ${option.valor}`}
            style={{ height: "38px" }}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput label="Remessa" placeholder={"Remessa"} />
        </CCol>
        <CCol>
          <InputData />
        </CCol>

        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol>
            <MyAutoComplete
              placeholder={"Fonte de Recursos"}
              options={[{ id: 0, valor: "teste" }]}
              labelFormat={(option) => `${option.id} - ${option.valor}`}
              style={{ height: "38px" }}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput label="Documento" placeholder={"Documento"} />
          </CCol>
        </RowAninhada>
      </RowForm>

      <div className="p-3"></div>
    </Container>
  );
};

export default Pagamento;
