import api from "../../utils/api";

export const getGuiaDeReceitaPorId = async (id, ano) => {
  try {
    const resp = await api.get(`guiaDeReceita/por-id/${id}/${ano}`);
    const data = resp.data;

    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
