import React, { useEffect, useState } from "react";
import {
  CCol,
  ContainerToModal,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Row } from "react-bootstrap";
import Participantes from "./Participantes";
import { InputDateRelatorio } from "../../../../components/Inputs/InputDateRelatorio";
import { OptionsStyle } from "../../../Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/NaturezaDaReceita";
import Aditivos from "./Aditivos";
import { tiposDeObjeto } from "./menus";
import { modalidades } from "./menus";
// import { CurrentYearContext } from "../../../../contexts/YearContext";
import { ToastContainer } from "react-toastify";
// import api from "../../../../utils/api";
// import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { formatToCurrency } from "../../../../utils/FormatacaoDeDados/FormatToCurrency";
import { formatRawDate } from "../../../../utils/FormatacaoDeDados/FormatRawDate";
import { TestButton } from "../../../../utils/TestButton";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { transformInputData } from "./TransformInputData";
import Contratos from "../../Utilitarios/Importar/ImportarLicitacao/Contratos";
import api from "../../../../utils/api";

const Licitacao = () => {
  const { acao, codigo } = useParams();
  // const { currentYear } = useContext(CurrentYearContext);

  const [abaSelecionada, setAbaSelecionada] = useState(0);
  const [values, setValues] = useState({
    licitacao: {},
    contratos: [],
    propostas: [],
    participantes: [],
    fornecedores: [],
    aditivos: [],
  });

  const addParticipante = (novoParticipante) => {
    setValues((prevValues) => ({
      ...prevValues,
      participantes: [...prevValues.participantes, novoParticipante],
    }));
  };
  const addContratos = (novoContrato) => {
    setValues((prevValues) => ({
      ...prevValues,
      contratos: [...prevValues.contratos, novoContrato],
    }));
  };
  const addAditivos = (novoAditivo) => {
    setValues((prevValues) => ({
      ...prevValues,
      aditivos: [...prevValues.aditivos, novoAditivo],
    }));
  };

  const fetchData = async () => {
    try {
      const item = false;
      if (codigo) {
        const response = await api.get(`/licitacao/get/by/codigo/${codigo}`);
        const allData = {
          licitacao: response?.data?.licitacao,
          contratos: response?.data?.contratos?.filter(
            (contrato) =>
              contrato.modalidadeLicitacao ===
              response?.data?.licitacao?.modalidadeLicitacao
          ),
          aditivos: response?.data?.aditivos,
          fornecedores: response?.data?.fornecedores,
          participantes: response?.data?.participantes,
          propostas: response?.data?.propostas?.filter(
            (proposta) =>
              proposta.modalidadeLicitacao ===
              response?.data?.licitacao?.modalidadeLicitacao
          ),
        };
        console.log("TUDO AQUI: ", allData);
        setValues(allData);
      }
      if (item) {
        const parsedItem = JSON.parse(item);
        console.log("ÓIA O OBJETO: ", parsedItem);
        setValues(parsedItem);
        // localStorage.removeItem("licitacaoItem");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (codigo) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [codigo]);

  // const postData = async () => {
  //   const valuesToPost = values;
  //   console.log(valuesToPost);
  //   try {
  //     await api.post("licitacaoContabilidade", valuesToPost);
  //     toast.success("Licitação cadastrada com sucesso.");
  //   } catch (error) {
  //     toast.error(error.response.data.error || "Erro ao cadastrar a licitação");
  //     console.log(error);
  //   }
  // };

  // const putData = async () => {
  //   const valuesToPost = values;
  //   console.log(valuesToPost);
  //   try {
  //     await api.put(`licitacaoContabilidade/${numeroLicitacao}`, valuesToPost);
  //     toast.success("Licitação atualizada com sucesso.");
  //   } catch (error) {
  //     toast.error("Erro ao cadastrar a licitação");
  //     console.log(error);
  //   }
  // };

  // const saveData = () => {
  //   if (acao && acao === "editar") {
  //     // putData();
  //   } else {
  //     // postData();
  //     let valuesToPost = {
  //       ...values,
  //       valor: values.valor.replace(/\./g, "").replace(",", "."),
  //     };
  //     console.log(valuesToPost);
  //   }
  // };

  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <ToastContainer />
      <HeaderCadastros
        PaginaConsulta={"/contabilidade/cadastros/licitacao/consulta"}
        NomePaginaTitulo={"Licitação"}
        PaginaSubtitulo={"Cadastro"}
        // ButtonSaveFunction={saveData}
        BotaoNovoAtivo={false}
        BotaoSaveAtivo={false}
      />
      <Row className="row_form mt-1">
        <TestButton onClick={() => console.log(values)}>Test</TestButton>
        <RowAninhada>
          <CCol md={2}>
            <FloatingLabelInput
              disabled={acao}
              label={"Número"}
              placeholder={"Número"}
              value={values.licitacao.numeroLicitacao}
              onChange={(e) => {
                const newValue = e.target.value.replace(/\D/g, "").slice(0, 9);
                setValues((prevValues) => ({
                  ...prevValues,
                  numeroLicitacao: newValue,
                }));
              }}
            />
          </CCol>
          <CCol md={1} style={{ width: "8em" }}>
            <InputDateRelatorio
              disabled={acao === "visualizar"}
              label={"Mês e Ano"}
              placeholder={"Mês e Ano"}
              value={
                acao === "visualizar" || values.licitacao.mesAno.length === 6
                  ? `${String(values.licitacao.mesAno).slice(0, 2)}/${String(
                      values.licitacao.mesAno
                    ).slice(2)}`
                  : values.licitacao.mesAno
              }
              onChange={(e) => {
                const input = transformInputData(e, 2);
                if (input.length <= 6) {
                  setValues((prevValues) => ({
                    ...prevValues,
                    mesAno: input,
                  }));
                }
              }}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              disabled={acao === "visualizar"}
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              label="Modalidade"
              placeholder="Modalidade"
              value={values.licitacao.modalidadeLicitacao}
              options={[{ label: "Modalidade", value: null }, ...modalidades]}
              onSelect={(option) => {
                setValues((prevValues) => ({
                  ...prevValues,
                  modalidadeLicitacao: option.value,
                }));
              }}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol>
            <FloatingLabelInput
              disabled={acao === "visualizar"}
              tipo="text_area"
              as="textarea"
              label="Objeto"
              placeholder="Objeto"
              style={{ minHeight: "98px" }}
              value={values.licitacao.descricao}
              onChange={(e) => {
                setValues((prevValues) => ({
                  ...prevValues,
                  descricao: e.target.value,
                }));
              }}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "10px" }}>
          <CCol md={1} style={{ width: "16em" }}>
            <InputFormatRealBrasileiro
              disabled={acao === "visualizar"}
              label={"R$"}
              placeholder={"R$"}
              value={
                acao === "visualizar"
                  ? formatToCurrency(values.licitacao.valor)
                  : values.licitacao.valor
              }
              onChange={(e, valor) =>
                setValues((prevValues) => ({
                  ...prevValues,
                  valor: valor,
                }))
              }
            />
          </CCol>
          <CCol md={2}>
            <InputDateRelatorio
              disabled={acao === "visualizar"}
              label={"Homologação"}
              placeholder={"Homologação"}
              value={
                values?.dataLicitacao?.length === 8
                  ? formatRawDate(values.licitacao.dataLicitacao)
                  : values.licitacao.dataLicitacao
              }
              onChange={(e) => {
                const input = transformInputData(e, 1);
                setValues((prevValues) => ({
                  ...prevValues,
                  dataLicitacao: input,
                }));
              }}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              disabled={acao === "visualizar"}
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              placeholder="Tipo do Objeto"
              value={String(values.licitacao.tipoObjeto)}
              options={[
                { label: "Tipo do Objeto", value: null },
                ...tiposDeObjeto,
              ]}
              onSelect={(option) => {
                setValues((prevValues) => ({
                  ...prevValues,
                  tipoObjeto: option.value,
                }));
              }}
            />
          </CCol>
        </RowAninhada>
      </Row>
      <Row
        style={{ backgroundColor: "#fff", borderRadius: "0px 0px 5px 5px" }}
        className={"mt-1"}
      >
        <CCol style={{ display: "flex", alignItems: "center", padding: "0px" }}>
          <OptionsStyle
            selecionado={abaSelecionada === 0}
            onClick={() => setAbaSelecionada(0)}
          >
            Propostas, Contratos e Adivitos
          </OptionsStyle>
        </CCol>
      </Row>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Participantes
          </span>
        </CCol>
      </Row>
      <Participantes
        addParticipante={addParticipante}
        propostas={values.propostas}
        participantes={values.participantes}
        fornecedores={values.fornecedores}
        acao={acao}
        numeroLicitacao={values.licitacao.numeroLicitacao}
      />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Contratos
          </span>
        </CCol>
      </Row>
      <Contratos
        addContratos={addContratos}
        contratos={values.contratos}
        fornecedores={values.fornecedores}
        participantes={values.participantes}
        numeroLicitacao={values.numeroLicitacao}
        acao={acao}
      />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Aditivos
          </span>
        </CCol>
      </Row>
      <Aditivos
        addAditivos={addAditivos}
        aditivos={values.aditivos}
        acao={acao}
        contratos={values.contratos}
      />
      <div style={{ minHeight: "120px" }}> </div>
    </ContainerToModal>
  );
};

export default Licitacao;
