import React, { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowAninhada, RowForm } from "../../../../components/Grid/CCol";
import InputData from "../../../../components/Grid/InputData";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import styled from "styled-components";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import HistoricoAba from "./HistoricoAba";
import ProdutoEAquisicaoAba from "./ProdutoEAquisicaoAba";
import ComplementoAba from "./ComplementoAba";
import SaldosAba from "./SaldosAba";
import { getCpfsCnpjsPorCliente } from "../../../../services/gets/getCpfsCnpjsPorCliente";
import { useCurrentClient } from "../../../../hooks/useCurrentClient";
import { useOpenedModals } from "../../../../hooks/useOpenedModals";
import ModalAutoComplete from "../../../../components/Modais/ModalAutoComplete";
import ComponentCadastroCpfCnpj from "../../../../components/ComponentCadastroCpfCnpj";
import { ToastContainer, toast } from "react-toastify";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import AutoCompleteFichas from "../../../../components/Inputs/AutoCompleteFichas";
import { gerenciaOpcoesOrdemCronologica } from "./gerenciaOpcoesOrdemCronologica";
import { getAllNaturezasDaDespesa } from "../../../../services/gets/getAllNaturezasDaDespesa";
import { gerenciaOpcoesSubelemento } from "./gerenciaOpcoesSubelemento";
import {
  getConveniosPbPorAno,
  getObrasTcePorCliente,
} from "../../../../services/tce/gets";
import { TestButton } from "../../../../utils/TestButton";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate, useParams } from "react-router-dom";
import { formatToCurrency } from "../../../../utils/FormatacaoDeDados/FormatToCurrency";
import api from "../../../../utils/api";
import ProcedimentosContabeisAba from "./ProcedimentosContabeisAba";

const CColNumeroAndEVB = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const RowInfosAcao = styled(RowAninhada)`
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 5px 0px;

  p {
    margin: 3px;
    color: #888a8e;
    font-weight: 500;
    font-size: 14px;
  }

  p > span {
    color: #515c70;
  }
`;

const NotaDeEmpenho = () => {
  //contexts
  const navigate = useNavigate();
  const { numero } = useParams();
  const { currentYear } = useCurrentYear();
  const { currentClient } = useCurrentClient();

  const [allNaturezasDaDespesa, setAllNaturezasDaDespesa] = useState([]);
  const [optionsCpf, setOptionsCpf] = useState([]);
  const [optionsFichas, setOptionsFichas] = useState([]);
  const [saldos, setSaldos] = useState({
    empenhado: "",
    liquidado: "",
  });
  const [optionsFontesDeRecursos, setOptionsFontesDeRecursos] = useState([]);
  const [optionsSubelementos, setOptionsSubelementos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [abas, setAbas] = useState([
    "Histórico",
    "Produto e Aquisição",
    "Complemento",
    "Saldos",
    "Procedimentos Contábeis",
  ]);
  const [optionsOrdemCronologica, setOptionsOrdemCronologica] = useState([
    {
      label: "0 - Outros",
      value: 0,
      disabled: true,
    },
    {
      label: "1 - Fornecimento de Bens",
      value: 1,
      disabled: true,
    },
    {
      label: "2 - Locações",
      value: 2,
      disabled: true,
    },
    {
      label: "3 - Prestação de Serviços",
      value: 3,
      disabled: true,
    },
    {
      label: "4 - Realização de Obras",
      value: 4,
      disabled: true,
    },
  ]);
  const [filteredOptionsFontesDeRecursos, setFilteredOptionsFontesDeRecursos] =
    useState([]);
  const [inputFicha, setInputFicha] = useState("");
  const [licitacoes, setLicitacoes] = useState([]);
  const [licitacoesFiltradas, setLicitacoesFiltradas] = useState([]);
  const [licitacoesOptions, setLicitacoesOptions] = useState([]);
  const { openedModals, setOpenedModals } = useOpenedModals();

  const [abaSelecionada, setAbaSelecionada] = useState({});
  const dataStr = ReturnDataAtual();
  const [dia, mes] = dataStr.split("/");
  const dataTemp = `${currentYear}-${mes}-${dia}`;
  const dataAtual = dataTemp;
  console.log("DATA ATUAAAAL: ", ReturnDataAtual());

  // const dataAtual = new Date(ReturnDataAtual());
  // dataAtual.setFullYear(2024); // VOLTA AQUI
  // console.log(dataAtual.toLocaleDateString("pt-BR"));
  const [produtoAquisicao, setProdutoAquisicao] = useState([]);
  const [valuesNotaEmpenho, setValuesNotaEmpenho] = useState({
    numero: "",
    fornecedor: "",
    licitacao: "",
    data: dataAtual,
    tipoDeEmpenho: "1 - Ordinário",
    ordemCronologica: 0,
    fichaId: 0,
    fonteDeRecursoId: 0,
    subelemento: "",
    valor: "00,00",
    descricao: "",
  });
  const [infosFicha, setInfosFicha] = useState({});

  //input states
  const [inputFornecedor, setInputFornecedor] = useState("");
  const [optionsObras, setOptionsObras] = useState([]);
  const [inputValueObras, setInputValueObras] = useState("");
  const [optionsConvenios, setOptionsConvenios] = useState([]);
  const [inputValueConvenios, setInputValueConvenios] = useState("");

  const fetchObras = async () => {
    const obras = await getObrasTcePorCliente(
      currentClient.clienteId,
      currentClient.codigoTCE
    );

    setOptionsObras(obras);
  };

  const fetchConvenios = async () => {
    const convenios = await getConveniosPbPorAno(currentYear);

    setOptionsConvenios(convenios);
  };

  const fetchFontesDeRecursos = async () => {
    try {
      const resp = await api.get(`/fonteDeRecurso/por-ano/${currentYear}`);
      const data = resp.data;
      setOptionsFontesDeRecursos(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCpfs = async (reqDeModal) => {
    const data = await getCpfsCnpjsPorCliente(currentClient.clienteId);

    if (data) {
      setOptionsCpf(
        data.map((item) => ({
          id: item.pessoaId,
          nome: item.nomeCompleto,
          cpf: FormatCpfPaste(item?.cpf) || "",
        }))
      );

      if (reqDeModal) {
        const dado = data.reverse()[0];

        setInputFornecedor(
          `${dado.nomeCompleto} - ${FormatCpfPaste(dado.cpf)}`
        );
        setValuesNotaEmpenho((prev) => ({
          ...prev,
          fornecedorId: dado.pessoaId,
        }));

        setOpenedModals((prev) =>
          prev.filter((item) => item.tituloModal !== "CPF/CNPJ")
        );
      }
    }
  };

  const fetchAllNaturezasDaDespesa = async () => {
    const dados = await getAllNaturezasDaDespesa(
      currentClient.clienteId,
      currentYear
    );

    setAllNaturezasDaDespesa(dados);
  };

  const fetchQddFichas = async () => {
    try {
      const resp = await api.get(
        `/qdd/${currentClient.clienteId}/${currentYear}`
      );
      const dados = resp.data;

      const arrayDeFichas = dados.map((item) => {
        const despesaFixadaQdd = item.despesaFixadaQDD;
        const fichaReferente = despesaFixadaQdd.fichasQDD;
        const fonteDeRecursosReferente = despesaFixadaQdd.fonteDeRecurso;
        const acaoGovernamentalInfo = item.acaoGovernamentalInfo;
        const exercicio = optionsFontesDeRecursos.find(
          (item) => item.e === fonteDeRecursosReferente.conta.split(".")[0]
        );

        return {
          pill: `Ficha ${fichaReferente.numeroFicha}`,
          acaoGovernamental: {
            id: acaoGovernamentalInfo.id,
            codigo: acaoGovernamentalInfo.nAcao,
            titulo: acaoGovernamentalInfo.titulo,
          },
          unidadeOrcamentaria: `${acaoGovernamentalInfo.unidadeOrcamentariaId.codigoUnidadeOrcamentaria} - ${acaoGovernamentalInfo.unidadeOrcamentariaId.tituloUnidadeOrcamentaria}`,
          funcao: `${acaoGovernamentalInfo.funcao.valor} - ${acaoGovernamentalInfo.funcao.nome}`,
          tipoDeVinculo: {
            ...acaoGovernamentalInfo.funcao,
          },
          subfuncao: `${acaoGovernamentalInfo.subFuncao.valor} - ${acaoGovernamentalInfo.subFuncao.nome}`,
          elementoDaDespesa: {
            codigo: fichaReferente.naturezaDaDespesa.codigo,
            titulo: fichaReferente.naturezaDaDespesa.nome,
            funcao: fichaReferente.naturezaDaDespesa.funcao,
          },
          fonteDeRecursos: `${fonteDeRecursosReferente.conta.split(".")[1]} - ${
            fonteDeRecursosReferente.titulo
          }`,
          fonteDeRecursosInfos: {
            conta: fonteDeRecursosReferente.conta,
          },
          programa: `${acaoGovernamentalInfo.programaPlanejamentoId.numeroPrograma} - ${acaoGovernamentalInfo.programaPlanejamentoId.tituloPrograma}`,
          ficha: {
            id: fichaReferente.id,
            ficha: fichaReferente.numeroFicha,
          },
          exercicio: `${exercicio.conta} - ${exercicio.titulo}`,
        };
      });

      setOptionsFichas(arrayDeFichas);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLicitacao = async () => {
    try {
      const response = await api.get(
        `/licitacao/${currentClient.codigoTCE}/${currentYear}`
      );
      console.log("Licitações aqui: ", response.data);
      setLicitacoes(response.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchNumero = async () => {
    try {
      if (!numero) {
        const response = await api.get(
          `/notaEmpenho/get/numero/${currentClient.clienteId}/${currentYear}`
        );
        console.log(response.data);
        setValuesNotaEmpenho((prevValues) => ({
          ...prevValues,
          numero: response.data.numeroDisponivel,
        }));
      }
    } catch (error) {
      toast.warn("Erro ao Buscar o Próximo Número");
      console.log(error);
    }
  };

  const handleAddNewCpf = () => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: "CPF/CNPJ", requisicaoDoModalConcluida: false },
    ]);
  };

  const transformarData = (dataISO) => {
    if (!dataISO) return null; // Verifica se a data é válida
    const dataObj = new Date(dataISO);
    const ano = dataObj.getUTCFullYear(); // Ano em UTC
    const mes = String(dataObj.getUTCMonth() + 1).padStart(2, "0"); // Mês em UTC (adiciona +1)
    const dia = String(dataObj.getUTCDate()).padStart(2, "0"); // Dia em UTC
    return `${ano}-${mes}-${dia}`;
  };

  const fetchAllData = async () => {
    const fornecedorData = optionsCpf;

    let newObject = {};
    if (numero) {
      try {
        const empenhoAtual = await api.get(`/notaEmpenho/get/por-id/${numero}`);
        newObject = {
          ...newObject,
          data: transformarData(empenhoAtual?.data?.data),
          numero: empenhoAtual?.data?.numero,
          fornecedor: empenhoAtual?.data?.fornecedor,
          licitacao: empenhoAtual?.data?.licitacao,
          fichaId: empenhoAtual?.data?.fichaId,
          valor: empenhoAtual?.data?.valor,
          ordemCronologica: empenhoAtual?.data?.ordemCronologica,
          tipoDeEmpenho: empenhoAtual?.data?.tipoDeEmpenho,
          descricao: empenhoAtual?.data?.descricao,
        };
        console.log("empenho atual: ", empenhoAtual.data);
        setSaldos((prevValues) => ({
          ...prevValues,
          empenhado: empenhoAtual?.data?.saldoEmpenhado || "00.00",
          liquidado: empenhoAtual?.data?.saldoLiquidado || "00.00",
        }));

        let fornecedorEncontrado = fornecedorData.find(
          (item) =>
            item?.id &&
            newObject.fornecedor &&
            item?.id === parseInt(newObject.fornecedor)
        );

        console.log("AQUIII: ", empenhoAtual?.data?.produtos);
        const produtos = empenhoAtual?.data?.produtos.map((item) => ({
          ...item,
          produtoAquisicao: {
            id: item?.produtoAquisicaoId,
            value: item?.numero,
            label: `${item?.numero} - ${item?.produtoAquisicao}`,
          },
          unidadeMedida: item?.unidadeDeMedida,
        }));

        setProdutoAquisicao(produtos || []);

        if (fornecedorEncontrado) {
          const { nome, cpf, cnpj } = fornecedorEncontrado;
          setInputFornecedor(`${nome} - ${cpf || cnpj}`);
        }

        // AQUI: Definindo a ficha primeiro
        const ficha = optionsFichas.find(
          (item) => item.ficha.id === newObject.fichaId
        );

        // AQUI: Usando a ficha depois de ser definida
        if (ficha) {
          setInputFicha(
            `${ficha.acaoGovernamental.codigo} - ${ficha.acaoGovernamental.titulo}`
          );

          const { tipoDeVinculo, ...rest } = ficha;

          // Filtrando as fontes que podem ser vinculadas
          const filteredFontes = optionsFontesDeRecursos
            .filter(
              (item) =>
                (item.conta.includes(rest.fonteDeRecursosInfos.conta) &&
                  item.funcaoId === tipoDeVinculo.id) ||
                item.conta === rest.fonteDeRecursosInfos.conta
            )
            .map((item) => ({
              label: `${item.conta} - ${item.titulo}`,
              value: item.id,
            }));

          console.log(filteredFontes);

          if (filteredFontes.length > 0) {
            setValuesNotaEmpenho((prev) => ({
              ...prev,
              fonteDeRecursoId: filteredFontes[filteredFontes.length - 1].value,
            }));
          }

          setFilteredOptionsFontesDeRecursos(filteredFontes);

          // Setando opcoes de subelemento
          const filteredOptionsSubelementos = gerenciaOpcoesSubelemento(
            rest.elementoDaDespesa.codigo.substring(0, 9),
            allNaturezasDaDespesa
          );
          console.log("\n\n");
          console.log(filteredOptionsSubelementos);
          setOptionsSubelementos(filteredOptionsSubelementos);

          // Setando opcoes de Ordem Cronologica
          const { newOptions, opcaoPadrao } = gerenciaOpcoesOrdemCronologica(
            rest.elementoDaDespesa.codigo,
            optionsOrdemCronologica
          );
          console.log(newOptions);
          console.log(opcaoPadrao);
          setOptionsOrdemCronologica(newOptions);

          setValuesNotaEmpenho((prev) => ({
            ...prev,
            ordemCronologica: opcaoPadrao,
          }));

          // Atualizando os valores de ficha e subelemento
          setValuesNotaEmpenho((prev) => ({
            ...prev,
            fichaId: ficha.id,
            subelemento: filteredOptionsSubelementos[0]?.value,
          }));

          setInfosFicha({
            ...rest,
          });
        }

        console.log("empenhoAtual: ", empenhoAtual);
      } catch (error) {
        console.error("Erro ao buscar dados do empenho:", error);
      }
      setValuesNotaEmpenho((prevValues) => ({
        ...prevValues,
        data: newObject.data,
        numero: newObject.numero,
        fornecedor: newObject.fornecedor,
        licitacao: newObject.licitacao,
        fichaId: newObject.fichaId,
        ordemCronologica: newObject.ordemCronologica,
        tipoDeEmpenho: newObject.tipoDeEmpenho,
        valor: formatToCurrency(newObject.valor),
        descricao: newObject.descricao,
      }));
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchCpfs();
    fetchFontesDeRecursos();
    fetchAllNaturezasDaDespesa();
    fetchObras();
    fetchConvenios();
    fetchLicitacao();
    fetchNumero();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchAllData();
    // eslint-disable-next-line
  }, [
    numero,
    optionsCpf,
    optionsFichas,
    optionsFontesDeRecursos,
    allNaturezasDaDespesa,
  ]);

  useEffect(() => {
    if (optionsFontesDeRecursos.length > 0) {
      fetchQddFichas();
    }

    //eslint-disable-next-line
  }, [optionsFontesDeRecursos]);

  useEffect(() => {
    const modalCpfCnpj = openedModals.find(
      (item) => item.tituloModal === "CPF/CNPJ"
    );

    if (modalCpfCnpj && modalCpfCnpj.requisicaoDoModalConcluida) {
      fetchCpfs(true);
    }

    //eslint-disable-next-line
  }, [openedModals]);

  const handleSelectFicha = (option) => {
    console.log(option);
    const { ficha, tipoDeVinculo, ...rest } = option;
    console.log(tipoDeVinculo);
    console.log(rest.fonteDeRecursosInfos);

    //filtrando as fontes que podem ser vinculadas
    const filteredFontes = optionsFontesDeRecursos
      .filter(
        (item) =>
          (item.conta.includes(rest.fonteDeRecursosInfos.conta) &&
            item.funcaoId === tipoDeVinculo.id) ||
          item.conta === rest.fonteDeRecursosInfos.conta
      )
      .map((item) => ({
        label: `${item.conta} - ${item.titulo}`,
        value: item.id,
      }));

    console.log(filteredFontes);

    if (filteredFontes.length > 0) {
      setValuesNotaEmpenho((prev) => ({
        ...prev,
        fonteDeRecursoId: filteredFontes[filteredFontes.length - 1].value,
      }));
    }

    setFilteredOptionsFontesDeRecursos(filteredFontes);

    //setando opcoes de subelemento
    const filteredOptionsSubelementos = gerenciaOpcoesSubelemento(
      rest.elementoDaDespesa.codigo.substring(0, 9),
      allNaturezasDaDespesa
    );
    console.log("\n\n");
    console.log(filteredOptionsSubelementos);
    setOptionsSubelementos(filteredOptionsSubelementos);

    //setando opcoes de Ordem Cronologica

    const { newOptions, opcaoPadrao } = gerenciaOpcoesOrdemCronologica(
      rest.elementoDaDespesa.codigo,
      optionsOrdemCronologica
    );
    console.log(newOptions);
    console.log(opcaoPadrao);
    setOptionsOrdemCronologica(newOptions);

    setValuesNotaEmpenho((prev) => ({
      ...prev,
      ordemCronologica: opcaoPadrao,
    }));

    // ----------------------------------
    setValuesNotaEmpenho((prev) => ({
      ...prev,
      fichaId: ficha.id,
      subelemento: filteredOptionsSubelementos[0].value,
    }));

    setInfosFicha({
      ...rest,
    });
  };

  useEffect(() => {
    if (inputFicha.length === 0) {
      setInfosFicha({});
      setValuesNotaEmpenho((prev) => ({
        ...prev,
        fichaId: 0,
      }));
    }
  }, [inputFicha]);

  useEffect(() => {
    const licitacoesCabiveis = licitacoes?.licitacao?.map((licitacao) => {
      // Filtra e mapeia os contratos relacionados à licitação
      const contratosRelacionados = licitacoes?.contratos
        .filter(
          (contrato) => contrato.numeroLicitacao === licitacao.numeroLicitacao
        )
        .map((contrato) => {
          // Filtra os aditivos relacionados ao contrato
          const aditivosRelacionados = licitacoes?.aditivos.filter(
            (aditivo) => aditivo.numeroContrato === contrato.numeroContrato
          );
          // Retorna apenas os contratos com seus aditivos, sem as propriedades indesejadas
          return { ...contrato, aditivos: aditivosRelacionados };
        });

      // Retorna as licitações com contratos e aditivos relacionados, sem as outras propriedades
      return { ...licitacao, contratos: contratosRelacionados };
    });

    const licitacoesComVigencia = licitacoesCabiveis?.map((licitacao) => {
      // Filtra os contratos relacionados à licitação atual
      const contratosRelacionados = licitacoes?.contratos.filter(
        (contrato) => contrato.numeroLicitacao === licitacao.numeroLicitacao
      );

      // Filtra os aditivos relacionados à licitação atual
      const aditivosRelacionados = licitacoes?.aditivos.filter(
        (aditivo) =>
          aditivo.numeroContrato === contratosRelacionados.numeroContrato
      );

      // Encontrar a maior dataVigencia nos aditivos relacionados
      const maiorDataAditivos = aditivosRelacionados?.reduce(
        (maior, aditivo) =>
          aditivo.dataVigencia > maior ? aditivo.dataVigencia : maior,
        "00000000" // Valor inicial para comparação (formato "YYYYMMDD")
      );

      // Encontrar a maior dataFinal nos contratos relacionados
      const maiorDataContratos = contratosRelacionados?.reduce(
        (maior, contrato) =>
          contrato.dataFinal > maior ? contrato.dataFinal : maior,
        "00000000" // Valor inicial para comparação (formato "YYYYMMDD")
      );

      // Comparar as duas datas e pegar a maior
      const maiorDataGeral =
        maiorDataAditivos > maiorDataContratos
          ? maiorDataAditivos
          : maiorDataContratos;

      // Adicionar a chave "vigencia" à licitação com a maior data encontrada
      return {
        ...licitacao,
        vigencia: maiorDataGeral,
      };
    });

    setLicitacoesFiltradas(licitacoesComVigencia);
  }, [valuesNotaEmpenho.fornecedor, licitacoes]);

  useEffect(() => {
    console.log("Licitações Filtradas aqui: ", licitacoesFiltradas);
    const licitacoesFiltradasPorDataEFornecedor = licitacoesFiltradas?.filter(
      (licitacao) => {
        // Filtra as licitações por vigência
        const isVigenciaValid =
          licitacao.vigencia >
          valuesNotaEmpenho.data.split("-").reverse().join("");

        // Verifica se existe algum contrato com o fornecedor desejado
        const temContratoComFornecedor = licitacao.contratos?.some(
          (contrato) => {
            const fornecedorEncontrado = optionsCpf.find(
              (option) => option.id === valuesNotaEmpenho.fornecedor
            );
            return (
              contrato.fornecedor ===
              fornecedorEncontrado?.cpf?.replace(/\D/g, "")
            );
          }
        );

        console.log("tem contrato com fornecedor: ", temContratoComFornecedor);

        // A licitação será incluída se passar nas duas verificações
        return isVigenciaValid && temContratoComFornecedor;
      }
    );

    const licitacoesOptionsLocais = licitacoesFiltradasPorDataEFornecedor?.map(
      (item) => ({
        label: `${item.numeroLicitacao}`,
        value: `${item.codigoUnidadeGestora}${item.numeroLicitacao}${item.dataLicitacao}${item.mesAno}`,
        title: item?.descricao,
      })
    );
    setLicitacoesOptions(licitacoesOptionsLocais);
  }, [
    valuesNotaEmpenho.fornecedor,
    licitacoesFiltradas,
    valuesNotaEmpenho.data,
    optionsCpf,
  ]);

  const autocompleteFilterFuncion = (options) => {
    const onlyNumbers = inputFicha.replace(/[^0-9]/g, "");

    const fichaCorrespondente = options.find(
      (item) => item.ficha.ficha.toString() === onlyNumbers
    );

    const filtragemGeral = options
      .filter(
        (item) =>
          item.label.toLowerCase().includes(inputFicha.toLowerCase()) ||
          item.label
            .toLowerCase()
            .replace(/\./g, "")
            .includes(inputFicha.toLowerCase())
      )
      .filter((item) => item.ficha.ficha.toString() !== onlyNumbers)
      .sort((a, b) => {
        if (a.ficha.ficha < b.ficha.ficha) {
          return -1;
        } else {
          return 1;
        }
      })
      .sort((a, b) => {
        const cleanedAcao = a.acaoGovernamental.codigo.replace(/[^0-9]/g, "");
        const cleanedAcaoB = b.acaoGovernamental.codigo.replace(/[^0-9]/g, "");

        if (
          cleanedAcao.startsWith(onlyNumbers) &&
          !cleanedAcaoB.startsWith(onlyNumbers)
        ) {
          return -1;
        } else if (
          !cleanedAcao.startsWith(onlyNumbers) &&
          cleanedAcaoB.startsWith(onlyNumbers)
        ) {
          return 1;
        }

        return 0;
      });

    console.log(fichaCorrespondente);

    if (fichaCorrespondente) {
      return [fichaCorrespondente, ...filtragemGeral];
    } else {
      return [...filtragemGeral];
    }
  };

  const handleSelectFornecedor = (option) => {
    console.log("AAAAAAAAAA: ", option);
    setValuesNotaEmpenho((prevValues) => ({
      ...prevValues,
      fornecedor: option?.id,
    }));
  };

  const handleAddNotaEmpenho = async () => {
    if (numero) {
      try {
        const valuesToUpdate = {
          ...valuesNotaEmpenho,
          valor: valuesNotaEmpenho.valor.replace(/\./g, "").replace(",", "."),
          produtos: produtoAquisicao.map((produto) => ({
            produtoAquisicaoId: produto?.produtoAquisicao?.id,
            quantidade: produto?.quantidade,
            valor: produto?.valor.replace(/\./g, ""),
          })),
        };

        if (parseFloat(valuesToUpdate.valor) <= 0) {
          toast.warn("O campo 'valor' deve ser maior que 0");
          return;
        }
        const response = await api.put(`notaEmpenho/${numero}`, valuesToUpdate);
        console.log(response.data);
        toast.success(
          response.data.message || "Empenho atualizada com sucesso!"
        );
      } catch (error) {
        toast.error(
          error.response.data.message ||
            "Houve um Problema ao Atualizar um Empenho!"
        );
        console.log(error);
      }
    } else {
      try {
        const valuesToPost = {
          ...valuesNotaEmpenho,
          valor: valuesNotaEmpenho.valor.replace(/\./g, "").replace(",", "."),
          produtos:
            produtoAquisicao.length > 0
              ? produtoAquisicao.map((produto) => ({
                  produtoAquisicaoId: produto?.produtoAquisicao?.id,
                  quantidade: produto?.quantidade,
                  valor: produto?.valor.replace(/\./g, "").replace(",", "."),
                }))
              : null,
        };
        if (parseFloat(valuesToPost.valor) <= 0) {
          toast.warn("O campo 'valor' deve ser maior que 0");
          return;
        }
        const response = await api.post(
          `notaEmpenho/${currentClient.clienteId}`,
          valuesToPost
        );
        console.log(response.data);
        toast.success(response.data.error || "Empenho cadastrado com sucesso!");
        navigate("/contabilidade/despesa/consulta/nota-de-empenho");
      } catch (error) {
        toast.error(
          error.response.data.message ||
            "Houve um Problema ao Cadastrar um Empenho!"
        );
        console.log(error);
      }
    }
  };

  const elementos = [
    `30`,
    `31`,
    `32`,
    `33`,
    `34`,
    `35`,
    `36`,
    `37`,
    `38`,
    `39`,
    `51`,
    `52`,
  ];

  useEffect(() => {
    const codigo = infosFicha?.elementoDaDespesa?.codigo?.split(".")[3];
    console.log(codigo, elementos.includes(codigo));
    if (codigo && elementos.includes(codigo)) {
      console.log("ENTROOU AQUIII, O SET DA ABAAA");
      setAbas([
        "Histórico",
        "Produto e Aquisição",
        "Complemento",
        "Saldos",
        "Procedimentos Contábeis",
      ]);
    } else {
      console.log("TIRANDO O SET DA ABA");
      setAbas([
        "Histórico",
        "Complemento",
        "Saldos",
        "Procedimentos Contábeis",
      ]);
    }
    // eslint-disable-next-line
  }, [infosFicha?.elementoDaDespesa?.codigo]);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <ModalAutoComplete
        tituloModal={"CPF/CNPJ"}
        Component={<ComponentCadastroCpfCnpj isAModalPage={true} />}
      />

      <ToastContainer />

      <HeaderCadastros
        NomePaginaTitulo={"Nota de Empenho"}
        PaginaSubtitulo={"Cadastro"}
        ButtonSaveFunction={handleAddNotaEmpenho}
        PaginaConsulta={"/contabilidade/despesa/consulta/nota-de-empenho"}
      />
      {loading ? (
        <Col
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
        </Col>
      ) : (
        <>
          <RowForm className="mt-1">
            <TestButton
              onClick={() =>
                console.log({
                  ...valuesNotaEmpenho,
                  valor: valuesNotaEmpenho.valor
                    .replace(/\./g, "")
                    .replace(",", "."),
                  produtos: produtoAquisicao.map((produto) => ({
                    produtoAquisicaoId: produto?.produtoAquisicao?.id,
                    quantidade: produto?.quantidade,
                    valor: produto?.valor.replace(/\./g, "").replace(",", "."),
                  })),
                })
              }
            >
              values
            </TestButton>
            <TestButton onClick={() => console.log(currentYear)}>
              Testando aqui
            </TestButton>
            <CColNumeroAndEVB md={1}>
              <FloatingLabelInput
                disabled
                label="Número"
                placeholder="Número"
                maxLength={7}
                value={valuesNotaEmpenho.numero}
                onChange={(e) =>
                  setValuesNotaEmpenho((prevValues) => ({
                    ...prevValues,
                    numero: e.target.value,
                  }))
                }
              />
            </CColNumeroAndEVB>
            <CCol>
              <MyAutoComplete
                iconsOff={numero}
                labelInput="Fornecedor"
                options={optionsCpf}
                inputValue={inputFornecedor}
                setInputValue={setInputFornecedor}
                addNewDataFunction={handleAddNewCpf}
                labelFormat={(item) => `${item.nome} - ${item.cpf}`}
                style={{ height: "37px" }}
                onOptionSelect={(option) => handleSelectFornecedor(option)}
              />
            </CCol>
            <CCol md={2}>
              <FloatingLabelInput
                disabled={numero}
                tipo="input_select"
                new
                placeholder="Licitação"
                options={licitacoesOptions}
                value={valuesNotaEmpenho.licitacao}
                onSelect={(option) => {
                  setValuesNotaEmpenho((prevValues) => ({
                    ...prevValues,
                    licitacao: option.value,
                  }));
                }}
              />
            </CCol>
            <CCol md={2}>
              <InputData
                value={valuesNotaEmpenho.data}
                onChange={(e) =>
                  setValuesNotaEmpenho((prev) => ({
                    ...prev,
                    data: e.target.value,
                  }))
                }
              />
            </CCol>
          </RowForm>

          <RowForm style={{ marginTop: "-14px" }}>
            <CCol>
              <AutoCompleteFichas
                onOptionSelect={handleSelectFicha}
                filterFunction={autocompleteFilterFuncion}
                labelInput="Ação"
                options={optionsFichas}
                inputValue={inputFicha}
                setInputValue={setInputFicha}
                rightLabelContent={(item) => item.elementoDaDespesa.codigo}
                labelFormat={(item) =>
                  `${item.acaoGovernamental.codigo} - ${item.acaoGovernamental.titulo}`
                }
                style={{ height: "37px" }}
              />
            </CCol>
          </RowForm>
          {Object.keys(infosFicha).length > 0 && (
            <RowForm
              style={{ marginTop: "-14px", paddingRight: 17, paddingLeft: 17 }}
            >
              <RowInfosAcao>
                <CCol md={7}>
                  <p>
                    Unidade Orçamentária:{" "}
                    <span>{infosFicha.unidadeOrcamentaria}</span>
                  </p>
                  <p>
                    Programa: <span>{infosFicha.programa}</span>
                  </p>
                  <p>
                    Elemento de Despesa:{" "}
                    <span>
                      {infosFicha.elementoDaDespesa.codigo.substring(0, 9)} -{" "}
                      {infosFicha.elementoDaDespesa.titulo}
                    </span>
                  </p>
                  <p>
                    Fonte de Recursos: <span>{infosFicha.fonteDeRecursos}</span>
                  </p>
                </CCol>
                <CCol>
                  <p>
                    Função: <span>{infosFicha.funcao}</span>
                  </p>
                  <p>
                    Subfunção: <span>{infosFicha.subfuncao}</span>
                  </p>
                  <p>
                    Exercício - CNP: <span>{infosFicha.exercicio}</span>
                  </p>
                </CCol>
              </RowInfosAcao>
            </RowForm>
          )}

          <RowForm style={{ marginTop: "-14px" }}>
            <CCol md={8}>
              <FloatingLabelInput
                tipo="input_select"
                new
                label="Exercício / Fonte de Recursos - FR / Complementação Orçamentária -CO"
                value={valuesNotaEmpenho.fonteDeRecursoId}
                onChange={(option) =>
                  setValuesNotaEmpenho((prev) => ({
                    ...prev,
                    fonteDeRecursoId: option.value,
                  }))
                }
                placeholder="Exercício / Fonte de Recursos - FR / Complementação Orçamentária -CO"
                options={filteredOptionsFontesDeRecursos}
              />
            </CCol>
            <CCol md={4}>
              <FloatingLabelInput
                tipo="input_select"
                new
                label="Subelemento de Despesa"
                value={valuesNotaEmpenho.subelemento}
                placeholder="Subelemento de Despesa"
                options={optionsSubelementos}
              />
            </CCol>
          </RowForm>

          {Object.keys(infosFicha).length > 0 && (
            <RowForm style={{ marginTop: "-14px" }}>
              <CCol>
                <FloatingLabelInput
                  as="textarea"
                  tipo="text_area"
                  readOnly
                  disabled
                  style={{ height: "38px", minHeight: "38px" }}
                  label="Função"
                  value={infosFicha.elementoDaDespesa.funcao}
                />
              </CCol>
            </RowForm>
          )}

          <RowForm style={{ marginTop: "-14px" }}>
            <CCol md={4}>
              <FloatingLabelInput
                tipo="input_select"
                new
                label="Ordem Cronológica"
                value={valuesNotaEmpenho.ordemCronologica}
                onChange={(option) =>
                  setValuesNotaEmpenho((prev) => ({
                    ...prev,
                    ordemCronologica: parseInt(option.value),
                  }))
                }
                placeholder="Ordem Cronológica"
                options={optionsOrdemCronologica}
              />
            </CCol>
            <CCol md={4}>
              <FloatingLabelInput
                tipo="input_select"
                new
                label="Tipo de Empenho"
                value={valuesNotaEmpenho.tipoDeEmpenho}
                onSelect={(option) => {
                  setValuesNotaEmpenho((prevValues) => ({
                    ...prevValues,
                    tipoDeEmpenho: option.value,
                  }));
                }}
                placeholder="Tipo de Empenho"
                options={[
                  { label: "1 - Ordinário", value: "1 - Ordinário" },
                  { label: "2 - Estimativo", value: "2 - Estimativo" },
                  { label: "3 - Global", value: "3 - Global" },
                ]}
              />
              <TestButton
                onClick={() =>
                  console.log(
                    parseFloat(valuesNotaEmpenho.valor),
                    saldos?.empenhado
                  )
                }
              >
                Teste
              </TestButton>
            </CCol>
            <CCol>
              <InputFormatRealBrasileiro
                iconReal
                disabled={produtoAquisicao.length > 0}
                value={valuesNotaEmpenho.valor}
                onChange={(_, valor) =>
                  setValuesNotaEmpenho((prevValues) => ({
                    ...prevValues,
                    valor: valor,
                  }))
                }
              />
            </CCol>
          </RowForm>

          <RowSelecaoAbasInferior
            abas={abas}
            abaSelecionada={abaSelecionada}
            setAbaSelecionada={setAbaSelecionada}
            alwaysSelectFirst
          />

          {abaSelecionada.nomeAba === "Histórico" && (
            <HistoricoAba
              values={valuesNotaEmpenho}
              setValues={setValuesNotaEmpenho}
            />
          )}
          {abaSelecionada.nomeAba === "Produto e Aquisição" && (
            <ProdutoEAquisicaoAba
              produtoAquisicao={produtoAquisicao}
              setProdutoAquisicao={setProdutoAquisicao}
              valorTotal={valuesNotaEmpenho.valor
                .replace(/\./g, "")
                .replace(",", ".")}
            />
          )}
          {abaSelecionada.nomeAba === "Complemento" && (
            <ComplementoAba
              optionsObras={optionsObras}
              inputValueObras={inputValueObras}
              setInputValueObras={setInputValueObras}
              optionsConvenios={optionsConvenios}
              inputValueConvenios={inputValueConvenios}
              setInputValueConvenios={setInputValueConvenios}
            />
          )}
          {abaSelecionada.nomeAba === "Saldos" && <SaldosAba saldos={saldos} />}
          {abaSelecionada.nomeAba === "Procedimentos Contábeis" && (
            <ProcedimentosContabeisAba />
          )}
        </>
      )}

      <div className="p-3" style={{ minHeight: "80px" }}></div>
    </Container>
  );
};

export default NotaDeEmpenho;
