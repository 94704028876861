import React, { useContext, useEffect, useState, useRef } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../components/HeaderCadastros/HeaderCadastros";
import { CCol } from "../../../components/Grid/CCol";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
// import FonteRecursosQDD from "./FonteRecursosQDD";
import { ToastContainer, toast } from "react-toastify";
// import { FormatContaNatDesp } from "../../../utils/FormatacaoDeDados/FormatContaNatDesp";
import { CurrentClientContext } from "../../../contexts/CurrentClientContext";
// import InputFormatRealBrasileiro from "../../../components/Grid/InputFormatRealBrasileiro";
import DespesaFixada from "./DespesaFixada";
import MyAutoComplete from "../../../components/Inputs/MyAutoComplete";
import { useParams, useLocation } from "react-router-dom";
import api from "../../../utils/api";
import styled from "styled-components";
import { OpenedModalsContext } from "../../../contexts/OpenedModalsContext";
import ModalAutoComplete from "../../../components/Modais/ModalAutoComplete";
import AcaoGovernamental from "../Cadastros/AcaoGovernamental/AcaoGovernamental";
import { useCurrentYear } from "../../../hooks/useCurrentYear";

//style={{ height: "38px", minHeight: "38px" }}
export const RowInfosAcao = styled(Row)`
  align-content: baseline;
  height: 80px;
  min-height: 80px;
  margin: 0px;
  border-radius: 10px;
  background-color: #ebeaea;
  padding: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  resize: vertical;
  border-radius: 5px;
  border: 1px solid #ccc;
  position: relative;

  span {
    color: #73767e;
  }

  p {
    margin: 0px;
    margin-left: 10px;
    color: #515c70;
    font-weight: 500;
    font-size: 15.5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span + p {
    display: inline;
  }
`;

const QDD = () => {
  const location = useLocation();
  const { currentYear } = useCurrentYear();
  const { numeroFicha: numeroFichaParams } = useParams();
  const { currentClient } = useContext(CurrentClientContext);
  const [inputAcao, setInputAcao] = useState("");
  const [optionsAcao, setOptionsAcao] = useState([]);
  const [openRefFromFR, setOpenRefFromFR] = useState(false);
  const [infosAcao, setInfosAcao] = useState({
    // unidadeOrcamentaria: "",
    // funcao: "",
    // subFuncao: "",
    // programa: "",
  });
  const [tipoDeVinculoAcao, setTipoDeVinculoAcao] = useState("");
  const { openedModals, setOpenedModals } = useContext(OpenedModalsContext);

  // const [valorTotalPorAcao, setValorTotalPorAcao] = useState(0);

  const [newValuesQDD, setNewValuesQDD] = useState({
    ano: currentYear,
    clienteId: currentClient.clienteId,
    acaoGovernamentalId: "",
    valorTotalDaAcao: 0,
    despesasFixadas: [
      // {
      //   fonteDeRecurso: "",
      //   totalPorFonte: "",
      //   fichas: [
      //     {
      //       numeroFicha: 0,
      //       naturezaDaDespesa: "",
      //       esfera: "",
      //       valor: "",
      //     },
      //   ],
      // },
    ],
  });

  const fetchAcoes = (reqDeModal = false) => {
    api
      .get(`/acaoGovernamental/${currentClient.clienteId}/${currentYear}`)
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        setOptionsAcao(
          data.map((item) => ({
            titulo: item.titulo,
            codigo: item.nAcao,
            id: item.id,
          }))
        );

        if (reqDeModal) {
          api
            .get(`/qdd/${currentYear}/por-QddId/${numeroFichaParams}`)
            .then((resp) => {
              const lastPostedData = {
                id: resp.qdd[0].acaoGovernamentalInfo.id,
                nAcao: resp.qdd[0].acaoGovernamentalInfo.nAcao,
                titulo: resp.qdd[0].acaoGovernamentalInfo.titulo,
              };
              console.log(lastPostedData);

              console.log("reqDeModal");

              setInputAcao(
                `${lastPostedData.nAcao} - ${lastPostedData.titulo}`
              );
              setNewValuesQDD((prev) => ({
                ...prev,
                acaoGovernamentalId: lastPostedData.id,
              }));

              preencheInfosAcao(lastPostedData.id);

              setOpenedModals((prev) =>
                prev.filter((item) => item.tituloModal !== "Ação Governamental")
              );
            });
        }
      });
  };

  useEffect(() => {
    fetchAcoes();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("AQUIII: ", infosAcao);

    //eslint-disable-next-line
  }, [infosAcao]);

  const preencheInfosAcao = (idAcao) => {
    if (numeroFichaParams) {
      api
        .get(`/qdd/${currentYear}/por-QddId/${numeroFichaParams}`)
        .then((resp) => {
          const data = resp.data;
          console.log("Tá, fazendo o get aqui em infosAcao, tá assim: ", data);
          setInfosAcao({
            unidadeOrcamentaria: `${data.qdd[0].acaoGovernamentalInfo.unidadeOrcamentariaId.codigoUnidadeOrcamentaria} - ${data.qdd[0].acaoGovernamentalInfo.unidadeOrcamentariaId.tituloUnidadeOrcamentaria}`,
            programa: `${data.qdd[0].acaoGovernamentalInfo.programaPlanejamentoId.numeroPrograma} - ${data.qdd[0].acaoGovernamentalInfo.programaPlanejamentoId.tituloPrograma}`,
            funcao: `${data.qdd[0].acaoGovernamentalInfo.funcao.valor} - ${data.qdd[0].acaoGovernamentalInfo.funcao.nome}`,
            subFuncao: `${data.qdd[0].acaoGovernamentalInfo.subFuncao.valor} - ${data.qdd[0].acaoGovernamentalInfo.subFuncao.valor}`,
            objetivo: `${data.qdd[0].acaoGovernamentalInfo.objetivos}`,
          });

          setTipoDeVinculoAcao(
            data.qdd[0].acaoGovernamentalInfo.funcao.nome
              .replace(/[0-9-]/g, "")
              .trim()
          );
          // setValorTotalPorAcao(acaoFiltrada.valorFixado)
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            "Ocorreu um erro ao adquirir as informaçoes dessa Ação. Por favor, tente mais tarde."
          );
        });
    } else {
      api
        .get(`acaoGovernamental/${currentClient.clienteId}/${currentYear}`)
        .then((resp) => {
          const data = resp.data;

          const acaoFiltrada = data.find((item) => item.id === idAcao);
          console.log("ACAO FILTRADA: ", acaoFiltrada);

          setInfosAcao({
            unidadeOrcamentaria: `${acaoFiltrada.unidadeOrcamentariaId.codigoUnidadeOrcamentaria} - ${acaoFiltrada.unidadeOrcamentariaId.tituloUnidadeOrcamentaria}`,
            programa: `${acaoFiltrada.programaPlanejamentoId.numeroPrograma} - ${acaoFiltrada.programaPlanejamentoId.tituloPrograma}`,
            funcao: `${acaoFiltrada.funcaoId.valor} - ${acaoFiltrada.funcaoId.nome}`,
            subFuncao: `${acaoFiltrada.subFuncaoId.valor} - ${acaoFiltrada.subFuncaoId.nome}`,
            objetivo: `${acaoFiltrada.objetivos}`,
          });

          setTipoDeVinculoAcao(
            acaoFiltrada.funcaoId.nome.replace(/[0-9-]/g, "").trim()
          );

          // setValorTotalPorAcao(acaoFiltrada.valorFixado);
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            "Ocorreu um erro ao adquirir as informaçoes dessa Ação. Por favor, tente mais tarde."
          );
        });
    }
  };
  /*
  function capitalizeFirstLetter(str) {
    for (let i = 0; i < str.length; i++) {
      if (/[a-zA-Z]/.test(str[i])) {
        return str[i].toUpperCase() + str.slice(i + 1).toLowerCase();
      }
    }
    return str;
  }
  */

  const limparValores = () => {
    setNewValuesQDD((prevValues) => ({
      ...prevValues,
      acaoGovernamentalId: "",
      despesasFixadas: [],
    }));

    setInputAcao("");
  };

  const limparTodosValores = () => {
    setNewValuesQDD((prevValues) => ({
      ...prevValues,
      acaoGovernamentalId: "",
      despesasFixadas: [],
    }));
    setInputAcao("");
  };

  useEffect(() => {
    if (inputAcao.length === 0) {
      setInfosAcao({});
      limparValores();
      setOpenRefFromFR(false);
    }
    //eslint-disable-next-line
  }, [inputAcao]);

  const handleAutocompleteAcao = (newValue) => {
    setNewValuesQDD((prevValues) => ({
      ...prevValues,
      acaoGovernamentalId: newValue.id,
    }));

    preencheInfosAcao(newValue.id);
    if (openRefFromFR) {
      handleGoRef(inputFontRecRef);
    }
  };

  const postQDD = () => {
    let despesasFixadasRefatoradas = [...newValuesQDD.despesasFixadas].map(
      (item) => {
        let fichasRefatoradas = [];
        const { disabled, ...itemRefatorado } = item;

        item.fichas.forEach((ficha) => {
          const { disabled, fonteDeRecursoId, ...resto } = ficha;

          let newValues = {
            ...resto,
            valor: parseFloat(resto.valor.replace(/,/g, ".")),
          };

          fichasRefatoradas.push(newValues);
        });

        return { ...itemRefatorado, fichas: fichasRefatoradas };
      }
    );
    let valuesToSend = {
      ano: currentYear,
      ...newValuesQDD,
      despesasFixadas: despesasFixadasRefatoradas,
    };

    console.log("AQUI OQ ENVIA: ", valuesToSend);
    if (!numeroFichaParams || location.pathname.includes("replicar")) {
      api
        .post("qdd", valuesToSend)
        .then((resp) => {
          toast.success("Dados cadastrados com sucesso!");
          limparValores();
          console.log(resp);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.error) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Algo deu errado. Por favor, tente mais tarde.");
          }
        });
    } else {
      api
        .put(`qdd/${currentClient.clienteId}`, valuesToSend)
        .then((resp) => {
          toast.success(resp.data.message || "Dados cadastrados com sucesso!");
          console.log(resp);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.error) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Algo deu errado. Por favor, tente mais tarde.");
          }
        });
    }
  };

  const fillData = () => {
    api
      .get(`/qdd/${currentYear}/por-QddId/${numeroFichaParams}`)
      .then((response) => {
        const data = response.data;
        console.log("AQUI A RESPONSE: ", data);
        const mapData = (data) => {
          const qddItem = data.qdd[0];
          console.log("QDD ITEM: ", qddItem);
          const acao = {
            id: qddItem.acaoGovernamentalId,
            codigo: qddItem.acaoGovernamentalInfo.nAcao,
          };
          const input = data.qdd[0].acaoGovernamentalInfo;
          console.log("Esse é o input: ", input);
          setInputAcao(`${input.nAcao} - ${input.titulo}`);
          handleAutocompleteAcao(acao);
          const despesasFixadas = qddItem.despesaFixadaQDD.map((despesa) => {
            console.log("Despesa Fixada: ", despesa);
            return {
              disabled: true,
              fichas: despesa.fichasQDD.map((ficha) => {
                console.log("FICHA: ", ficha);
                return {
                  disabled: true,
                  id: ficha.id,
                  esfera: ficha.esfera,
                  fonteDeRecursoId: despesa.FonteDeRecursoId,
                  naturezaDaDespesaId: ficha.naturezaDaDespesaId,
                  numeroFicha: ficha.numeroFicha,
                  valor: ficha.valor,
                };
              }),
              id: despesa.id,
              fonteDeRecursoId: despesa.FonteDeRecursoId,
              totalPorFonte: 0,
            };
          });
          console.log("DESPESAS FIXADAS: ", despesasFixadas);
          return {
            acaoGovernamentalId: qddItem.acaoGovernamentalId,
            ano: currentYear,
            clienteId: currentClient.clienteId,
            despesasFixadas: despesasFixadas,
            valorTotalDaAcao: parseFloat(qddItem.valorTotalDaAcao),
          };
        };

        const result = mapData(data);
        console.log("Objeto preenchido: ", result);
        setNewValuesQDD(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (numeroFichaParams) {
      fillData();
    } else {
      handleGoRef(inputAcaoRef);
    }
    // eslint-disable-next-line
  }, [optionsAcao, numeroFichaParams]);

  const inputAcaoRef = useRef(null);
  const inputFontRecRef = useRef(null);

  const handleGoRef = (nextRef) => {
    nextRef.current.focus();
  };

  useEffect(() => {
    if (openRefFromFR) {
      handleGoRef(inputFontRecRef);
    }
  }, [openRefFromFR]);

  const handleOpenNewModal = () => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: "Ação Governamental", requisicaoDoModalConcluida: false },
    ]);
  };

  useEffect(() => {
    const modalAcaoGovernamental = openedModals.find(
      (modal) => modal.tituloModal === "Ação Governamental"
    );

    if (
      modalAcaoGovernamental &&
      modalAcaoGovernamental.requisicaoDoModalConcluida
    ) {
      fetchAcoes(true);
    }

    //eslint-disable-next-line
  }, [openedModals]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <ModalAutoComplete
        tituloModal={"Ação Governamental"}
        Component={<AcaoGovernamental isAModalPage={true} />}
      />
      <HeaderCadastros
        NomePaginaTitulo={"QDD - Quadro Detalhado da Despesa"}
        PaginaSubtitulo={"Cadastro"}
        ButtonSaveFunction={postQDD}
        BotaoNovoFunction={() => handleGoRef(inputAcaoRef)}
        PaginaConsulta={"/planejamento/loa/qdd/consulta"}
        PaginaRelatorio={"/planejamento/loa/qdd/relatorio"}
      />
      {/* <button
        onClick={() => {
          console.log(newValuesQDD);
        }}
      >
        console
      </button> */}
      <ToastContainer />
      <Row className="row_form_2 mt-1">
        {numeroFichaParams && !location.pathname.includes("replicar") ? (
          <CCol>
            <FloatingLabelInput label={"Ação"} value={inputAcao} disabled />
          </CCol>
        ) : (
          <React.Fragment>
            <CCol>
              <MyAutoComplete
                addNewDataFunction={handleOpenNewModal}
                ref={inputAcaoRef}
                inputValue={inputAcao}
                setInputValue={setInputAcao}
                style={{ height: "37px" }}
                labelInput="Ação"
                placeholder="Número da Ação"
                options={optionsAcao}
                onOptionSelect={handleAutocompleteAcao}
                labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
                iconClearFunction={limparTodosValores}
              />
            </CCol>
          </React.Fragment>
        )}
      </Row>

      {Object.keys(infosAcao).length > 0 && (
        <>
          <Row key="row1" className="row_form_2" style={{ marginTop: "-14px" }}>
            <CCol md={12}>
              <FloatingLabelInput
                as="textarea"
                disabled
                style={{
                  height: "38px",
                  minHeight: "38px",
                  backgroundColor: "#ebeaea",
                }}
                tipo="text_area"
                placeholder="Objetivos"
                label="Objetivos"
                value={infosAcao.objetivo}
              />
            </CCol>
          </Row>
          <Row key="row2" className="row_form_2" style={{ marginTop: "-21px" }}>
            <CCol md={12}>
              {/* <LabelStyled active={true} style={{ top: "33px" }}>
                Classificação Funcional
              </LabelStyled> */}
              <RowInfosAcao>
                <CCol md={7}>
                  <span>Unidade Orçamentária: </span>{" "}
                  <p>{infosAcao.unidadeOrcamentaria}</p>
                </CCol>
                <CCol md={5}>
                  <span>Função: </span>
                  <p>{infosAcao.funcao}</p>
                </CCol>
                <CCol md={7} style={{ marginTop: "5px" }}>
                  <span>Programa: </span>
                  <p>{infosAcao.programa}</p>
                </CCol>
                <CCol md={5} style={{ marginTop: "5px" }}>
                  <span>Subfunção: </span>
                  <p>{infosAcao.subFuncao}</p>
                </CCol>
              </RowInfosAcao>
            </CCol>
          </Row>
        </>
      )}
      {newValuesQDD.acaoGovernamentalId !== "" && (
        <>
          <Row className="mt-3">
            <CCol
              className="d-flex align-items-center p-2 header_body_conteudo"
              md={12}
            >
              <span
                style={{
                  fontSize: "16.5px",
                  fontWeight: "600",
                  marginLeft: "10px",
                }}
              >
                Despesa Fixada
              </span>
            </CCol>
          </Row>

          <DespesaFixada
            valuesQDD={newValuesQDD}
            setValuesQDD={setNewValuesQDD}
            tipoDeVinculoAcao={tipoDeVinculoAcao}
            pagDeUpdate={numeroFichaParams ? true : false}
            comportamentoUpdate={numeroFichaParams ? true : false}
            pagDeReplica={location.pathname.includes("replicar") ? true : false}
            setOpenRefFromFR={setOpenRefFromFR}
            inputFontRecRef={inputFontRecRef}
          />
        </>
      )}

      <div className="p-3"></div>
    </Container>
  );
};

export default QDD;
