import React, { useEffect, useRef, useState } from "react";
import { FormatValueToLocaleString } from "../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { FormControlListaDeInputs } from "../../styles/StyledComponents/ListaDeInputs";
import FloatingLabelInput from "./FloatingLabelInput";
import { LabelStyled } from "../Inputs/MyAutoComplete";
import styled from "styled-components";

const IconReal = styled.span`
  position: absolute;
  top: 50%;
  left: 10px;
  color: #515c70;
  transform: translateY(-50%);
`;

const InputFormatRealBrasileiro = React.forwardRef(
  (
    {
      onCustomChange,
      externalValue,
      onChange,
      radiusborder,
      listaDeInputs,
      label,
      onKeyDownFunction,
      iconReal = false,
      ...atributos
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(
      externalValue ? externalValue.toString() : "0,00"
    );

    const inputLocalRef = useRef();

    useEffect(() => {
      if (externalValue) {
        // console.log("valorExterno atualizado", externalValue.toString());
        setInputValue(externalValue.toString());
      }
    }, [externalValue, onChange]);

    const formatCurrency = (value) => {
      // Remove qualquer caractere não numérico
      const numericValue = value.replace(/[^0-9]/g, "");

      // Remove os zeros à esquerda
      const trimmedValue = numericValue.replace(/^0+/, "");

      // Adiciona zeros à esquerda para garantir que tenhamos pelo menos 3 dígitos
      const paddedValue = trimmedValue.padStart(3, "0");

      // Divide os últimos dois dígitos do restante
      const cents = paddedValue.slice(-2);
      const integerPart = paddedValue.slice(0, -2);

      // Adiciona pontos a cada três dígitos na parte inteira
      const formattedIntegerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        "."
      );

      // Formata o valor no estilo brasileiro
      const formattedValue = `${formattedIntegerPart || "0"},${cents}`;

      return formattedValue;
    };

    const selectStartPoint = () => {
      setTimeout(() => {
        if (ref && ref.current) {
          console.log("ref externa");
          ref.current.selectionStart = ref.current.selectionEnd =
            ref.current.value.length;
        }

        if (inputLocalRef.current) {
          console.log("ref local");
          inputLocalRef.current.selectionStart =
            inputLocalRef.current.selectionEnd =
              inputLocalRef.current.value.length;
        }
      }, 0);
    };

    const handleInputChange = (event) => {
      const { value } = event.target;
      const formattedValue = formatCurrency(value);
      setInputValue(formattedValue);
      console.log("handle inputFormatReal", formattedValue);

      if (onChange) {
        onChange(event, formattedValue);
      }
    };

    const handleFocus = (e) => {
      console.log("focado");
      selectStartPoint();

      if (atributos.onFocus) {
        atributos.onFocus(e);
      }
    };

    const handleClick = (e) => {
      selectStartPoint();

      if (atributos.onClick) {
        atributos.onClick(e);
      }
    };

    const handleKeyDown = (event) => {
      if (onKeyDownFunction) {
        onKeyDownFunction(event);
      }
    };

    return (
      <>
        {listaDeInputs && (
          <FormControlListaDeInputs
            label="R$"
            onChange={handleInputChange}
            onFocus={handleFocus}
            onClick={handleClick}
            radiusborder={radiusborder}
            placeholder="Digite o valor"
            style={{ textAlign: "end" }}
            value={FormatValueToLocaleString(
              parseFloat(inputValue.replace(/,/g, "."))
            )}
            ref={ref || inputLocalRef}
            onKeyDown={handleKeyDown}
            {...atributos}
          />
        )}
        {!listaDeInputs && (
          <div style={{ position: "relative" }}>
            {iconReal && <IconReal>R$</IconReal>}
            <LabelStyled active={inputValue.length > 0}>{label}</LabelStyled>

            <FloatingLabelInput
              label={atributos.label}
              onChange={handleInputChange}
              onFocus={handleFocus}
              onClick={handleClick}
              style={{ textAlign: "end" }}
              placeholder={atributos.placeholder}
              value={FormatValueToLocaleString(
                parseFloat(inputValue.replace(/,/g, "."))
              )}
              ref={ref || inputLocalRef}
              onKeyDown={handleKeyDown}
              {...atributos}
            />
          </div>
        )}
      </>
    );
  }
);

export default InputFormatRealBrasileiro;
