import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import styled from "styled-components";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import { CollapseRow } from "../../../../components/CollapseRow/CollapseRow";
import { AnulacaoCollapse } from "./AnulacaoCollapse";
import api from "../../../../utils/api";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import AutoCompleteFichas from "../../../../components/Inputs/AutoCompleteFichas";

const CColNumeroAndEVB = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const ContentInfos = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    white-space: nowrap;
    font-size: 12px;
  }

  @media (max-width: 1077px) {
    flex-wrap: wrap;
    gap: 5px;
  }
`;

const AtualizacaoOrcamentaria = () => {
  const { currentYear } = useContext(CurrentYearContext);
  const { currentClient } = useContext(CurrentClientContext);
  const [collapseAnulacao, setCollapseAnulacao] = useState(false);
  const [optionsFichas, setOptionsFichas] = useState([]);
  const [inputFicha, setInputFicha] = useState("");

  const fetchQddFichas = async () => {
    try {
      const resp = await api.get(
        `/qdd/${currentClient.clienteId}/${currentYear}`
      );
      const dados = resp.data;

      const arrayDeFichas = dados.map((item) => {
        const despesaFixadaQdd = item.despesaFixadaQDD;
        const fichaReferente = despesaFixadaQdd.fichasQDD;
        const fonteDeRecursosReferente = despesaFixadaQdd.fonteDeRecurso;
        const acaoGovernamentalInfo = item.acaoGovernamentalInfo;

        return {
          pill: `Ficha ${fichaReferente.numeroFicha}`,
          acaoGovernamental: {
            id: acaoGovernamentalInfo.id,
            codigo: acaoGovernamentalInfo.nAcao,
            titulo: acaoGovernamentalInfo.titulo,
          },
          unidadeOrcamentaria: `${acaoGovernamentalInfo.unidadeOrcamentariaId.codigoUnidadeOrcamentaria} - ${acaoGovernamentalInfo.unidadeOrcamentariaId.tituloUnidadeOrcamentaria}`,
          funcao: `${acaoGovernamentalInfo.funcao.valor} - ${acaoGovernamentalInfo.funcao.nome}`,
          tipoDeVinculo: {
            ...acaoGovernamentalInfo.funcao,
          },
          subfuncao: `${acaoGovernamentalInfo.subFuncao.valor} - ${acaoGovernamentalInfo.subFuncao.nome}`,
          elementoDaDespesa: {
            codigo: fichaReferente.naturezaDaDespesa.codigo,
            titulo: fichaReferente.naturezaDaDespesa.nome,
            funcao: fichaReferente.naturezaDaDespesa.funcao,
          },
          fonteDeRecursos: `${fonteDeRecursosReferente.conta.split(".")[1]} - ${
            fonteDeRecursosReferente.titulo
          }`,
          fonteDeRecursosInfos: {
            conta: fonteDeRecursosReferente.conta,
          },
          programa: `${acaoGovernamentalInfo.programaPlanejamentoId.numeroPrograma} - ${acaoGovernamentalInfo.programaPlanejamentoId.tituloPrograma}`,
          ficha: {
            id: fichaReferente.id,
            ficha: fichaReferente.numeroFicha,
          },
        };
      });

      setOptionsFichas(arrayDeFichas);
      console.log(arrayDeFichas);
    } catch (error) {
      console.log(error);
    }
  };

  const autocompleteFilterFuncion = (options) => {
    const onlyNumbers = inputFicha.replace(/[^0-9]/g, "");

    const fichaCorrespondente = options.find(
      (item) => item.ficha.ficha.toString() === onlyNumbers
    );

    const filtragemGeral = options
      .filter(
        (item) =>
          item.label.toLowerCase().includes(inputFicha.toLowerCase()) ||
          item.label
            .toLowerCase()
            .replace(/\./g, "")
            .includes(inputFicha.toLowerCase())
      )
      .filter((item) => item.ficha.ficha.toString() !== onlyNumbers)
      .sort((a, b) => {
        if (a.ficha.ficha < b.ficha.ficha) {
          return -1;
        } else {
          return 1;
        }
      })
      .sort((a, b) => {
        const cleanedAcao = a.acaoGovernamental.codigo.replace(/[^0-9]/g, "");
        const cleanedAcaoB = b.acaoGovernamental.codigo.replace(/[^0-9]/g, "");

        if (
          cleanedAcao.startsWith(onlyNumbers) &&
          !cleanedAcaoB.startsWith(onlyNumbers)
        ) {
          return -1;
        } else if (
          !cleanedAcao.startsWith(onlyNumbers) &&
          cleanedAcaoB.startsWith(onlyNumbers)
        ) {
          return 1;
        }

        return 0;
      });

    console.log(fichaCorrespondente);

    if (fichaCorrespondente) {
      return [fichaCorrespondente, ...filtragemGeral];
    } else {
      return [...filtragemGeral];
    }
  };

  useEffect(() => {
    fetchQddFichas();
    // eslint-disable-next-line
  }, []);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Atualização Orçamentária"}
        PaginaSubtitulo={"Cadastro"}
      />

      <RowForm className="mt-1">
        <CColNumeroAndEVB md={1}>
          <FloatingLabelInput placeholder="Número" label="Número" />
        </CColNumeroAndEVB>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Documento"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Tipo Alteração"
          />
        </CCol>
        <CCol md={2}>
          <InputData />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol md={10}>
          <CCol>
            <AutoCompleteFichas
              // iconsOff={numero}
              // disabled={numero}
              // onOptionSelect={handleSelectFicha}
              filterFunction={autocompleteFilterFuncion}
              labelInput="Ficha"
              options={optionsFichas}
              inputValue={inputFicha}
              setInputValue={setInputFicha}
              rightLabelContent={(item) => item.elementoDaDespesa.codigo}
              labelFormat={(item) =>
                `${item.acaoGovernamental.codigo} - ${item.acaoGovernamental.titulo}`
              }
              style={{ height: "37px" }}
            />
          </CCol>
        </CCol>
        <CCol>
          <InputFormatRealBrasileiro />
        </CCol>
      </RowForm>

      <RowInfosAcao contentStyle={{ overflowX: "hidden" }}>
        <ContentInfos>
          <p>
            Nº Lei: <span>1015/2022</span>
          </p>

          <p>
            Data Lei: <span>01/01/2022</span>
          </p>

          <p>
            Tipo de Crédito: <span>Orçamentário</span>
          </p>

          <p>
            Destinação:{" "}
            <span>
              Estima receita e fixa despesa para o orçamento vigente de Ita...
            </span>
          </p>
        </ContentInfos>
      </RowInfosAcao>

      <RowInfosAcao>
        <CCol md={8}>
          <p>
            Unidade Orçamentária:{" "}
            <span>02.050 - Secretaria Municipal de Educação</span>
          </p>
          <p>
            Programa:{" "}
            <span>
              2025 - Manutenção dos Serviços da Secretaria Municipal de Educação
            </span>
          </p>
          <p>
            Elemento de Despesa:{" "}
            <span>
              3.3.90.39 - OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA
            </span>
          </p>
          <p>
            Fonte de Recursos:{" "}
            <span>
              1.500.1001 - Identificação das despesas com manutenção e
              desenvolvimento do ensino
            </span>
          </p>
        </CCol>
        <CCol>
          <p>
            Função: <span>08 - Assistência Social</span>
          </p>
          <p>
            Subfunção: <span>244 - Assistência Comunitária</span>
          </p>
        </CCol>
      </RowInfosAcao>

      <RowInfosAcao
        $borderRadiusType={2}
        contentStyle={{ overflowX: "hidden" }}
      >
        <ContentInfos>
          <p>
            Dotação Inicial: <span>R$ 150.000,00</span>
          </p>

          <p>
            Utilizado: <span>R$ 150.000,00</span>
          </p>

          <p>
            Suplementação: <span>R$ 150.000,00</span>
          </p>

          <p>
            Anulação: <span>R$ 150.000,00</span>
          </p>

          <p>
            Saldo: <span>R$ 150.000,00</span>
          </p>
        </ContentInfos>
      </RowInfosAcao>

      <CollapseRow
        onClick={() => setCollapseAnulacao((prev) => !prev)}
        style={{ marginTop: "15px" }}
        collapse={collapseAnulacao}
        title={"Anulação"}
      />

      {collapseAnulacao && <AnulacaoCollapse />}

      <div className="p-3"></div>
    </Container>
  );
};

export default AtualizacaoOrcamentaria;
