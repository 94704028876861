import { createContext, useState } from "react";
import { ReturnDataAtual } from "../utils/Gerais/ReturnDataAtual";

export const CurrentYearContext = createContext();

export const CurrentYearProvider = ({ children }) => {
  const [currentYear, setCurrentYear] = useState(
    parseInt(ReturnDataAtual().split("/")[2]) - 1
  );

  return (
    <CurrentYearContext.Provider value={{ currentYear, setCurrentYear }}>
      {children}
    </CurrentYearContext.Provider>
  );
};
