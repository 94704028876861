import React from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { optionsTranferencias } from "./optionsTranferenciasRecebidas";

export const TransferenciaRecebidaAba = ({
  optionsUnidadesGestoras,
  infosCadastro,
  setInfosCadastro,
  currentClient,
  inputUnidadeGestoraValue,
  setInputUnidadeGestoraValue,
  inputTipoTransferenciaValue,
  setInputTipoTransferenciaValue,
  optionsTipoTransferencia, 
  setOptionsTipoTranferencias
}) => {
  

  const handleSelectUnidadeGestora = (optionUnidade) => {
    console.log(optionUnidade);

    const tipoNaturezaJuridicaIsCamara =
      optionUnidade.tipoNaturezaJuridica === "1 - Câmara Municipal";

    const currentClientIsPrefeitura =
      currentClient.tipoNaturezaJuridica ===
      "2 - Prefeitura Municipal/Secretarias";

    if (tipoNaturezaJuridicaIsCamara && currentClientIsPrefeitura) {
      const filterOptionsTransferencia = optionsTranferencias.filter(
        (item) => item.label === "8 - Devolução de Recursos"
      );
      setOptionsTipoTranferencias(filterOptionsTransferencia);
      setInputTipoTransferenciaValue(filterOptionsTransferencia[0].value);
      setInfosCadastro((prev) => ({
        ...prev,
        unidadeGestoraId: optionUnidade.value,
        tipoNaturezaJuridica: optionUnidade.tipoNaturezaJuridica,
        tipoTransferencia: filterOptionsTransferencia[0].label,
      }));
      return;
    }

    setOptionsTipoTranferencias(optionsTranferencias);
    setInfosCadastro((prev) => ({
      ...prev,
      unidadeGestoraId: optionUnidade.value,
      tipoNaturezaJuridica: optionUnidade.tipoNaturezaJuridica,
    }));
  };

  const handleSelectTipoTransferencia = (optionTipoTranferencia) => {
    console.log(optionTipoTranferencia);
    setInfosCadastro((prev) => ({
      ...prev,
      tipoTransferencia: optionTipoTranferencia.label,
    }));
  };

  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Transferência Recebida</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol md={8}>
          <FloatingLabelInput
            tipo="input_select"
            placeholder="Unidade Gestora Transferidora"
            label="Unidade Gestora Transferidora"
            new
            value={inputUnidadeGestoraValue}
            options={
              optionsUnidadesGestoras.length
                ? [
                    ...optionsUnidadesGestoras,
                    { label: "Unidade Gestora Transferidora", value: null },
                  ]
                : [{ label: "Unidade Gestora Transferidora", value: null }]
            }
            onSelect={(option) => {
              handleSelectUnidadeGestora(option);
              setInputUnidadeGestoraValue(option.value);
            }}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            disabled
            placeholder="Natureza Jurídica"
            label="Natureza Jurídica"
            value={infosCadastro.tipoNaturezaJuridica}
          />
        </CCol>
      </RowForm>
      <RowForm $borderRadiusType={2} style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            placeholder="Tipo de Transferência"
            label="Tipo de Transferência"
            value={inputTipoTransferenciaValue}
            options={[
              { label: "Tipo de Transferência", value: null },
              ...optionsTipoTransferencia,
            ]}
            onSelect={(option) => {
              handleSelectTipoTransferencia(option);
              setInputTipoTransferenciaValue(option.value);
            }}
          />
        </CCol>
      </RowForm>
    </>
  );
};
