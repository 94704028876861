import React, { useEffect, useState } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import { formatRawDate } from "../../../../utils/FormatacaoDeDados/FormatRawDate";
import { formatToCurrency } from "../../../../utils/FormatacaoDeDados/FormatToCurrency";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { transformInputData } from "./TransformInputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";

const Aditivos = ({ addAditivos, aditivos, acao, contratos }) => {
  const [contratosOptions, setContratosOptions] = useState([]);
  const [defValues, setDefValues] = useState({
    numeroAditivo: "",
    dataAssinatura: "",
    dataVigencia: "",
    descricao: "",
    valor: "",
  });

  const addValues = () => {
    const hasEmptyValues = Object.aditivos(defValues).some(
      (value) => value === "" || value === null || value === undefined
    );

    if (!hasEmptyValues) {
      const novoAditivo = {
        ...defValues,
        numeroAditivo: defValues.numeroAditivo,
      };

      addAditivos(novoAditivo);
      setDefValues({
        numeroAditivo: "",
        dataAssinatura: "",
        dataVigencia: "",
        descricao: "",
        valor: "",
      });
    }
  };

  useEffect(() => {
    setDefValues({
      numeroAditivo: "",
      dataAssinatura: "",
      dataVigencia: "",
      descricao: "",
      valor: "",
    });
  }, [aditivos]);

  useEffect(() => {
    console.log(contratos);
    if (contratos) {
      const allContratos = contratos.map((contrato) => ({
        label: contrato.numeroContrato,
        value: contrato.numeroContrato,
      }));
      setContratosOptions(allContratos);
    }
  }, [contratos]);

  useEffect(() => {
    console.log("ADITIVOOOS:", aditivos);
  }, [aditivos]);

  return (
    <>
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={1} style={{ width: "10em" }}>
            <PTitulosInputs>Nº Contratos</PTitulosInputs>
            <FloatingLabelInput
              new
              height={"30px"}
              tipo="input_select"
              disabled={acao === "visualizar"}
              radiusborder={aditivos?.length > 0 ? "5px 5px 0 0" : "5px"}
              value={acao === "visualizar" ? "" : defValues.numeroContrato}
              options={[
                { label: "Contratos", value: null },
                ...contratosOptions,
              ]}
              onSelect={(option) =>
                setDefValues((prevValues) => ({
                  ...prevValues,
                  numeroContrato: option.value,
                }))
              }
              noArrow={acao}
            />
          </CCol>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Nº Aditivo</PTitulosInputs>
            <FormControlListaDeInputs
              disabled={acao === "visualizar"}
              radiusborder={aditivos?.length > 0 ? "5px 5px 0 0" : "5px"}
              value={acao === "visualizar" ? "" : defValues.numeroAditivo}
              onChange={(e) =>
                setDefValues((prevValues) => ({
                  ...prevValues,
                  numeroAditivo: e.target.value,
                }))
              }
            />
          </CCol>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Data Assinatura</PTitulosInputs>
            <FormControlListaDeInputs
              disabled={acao === "visualizar"}
              radiusborder={aditivos?.length > 0 ? "5px 5px 0 0" : "5px"}
              value={
                defValues.dataAssinatura.length === 8
                  ? formatRawDate(defValues.dataAssinatura)
                  : defValues.dataAssinatura
              }
              onChange={(e) => {
                const input = transformInputData(e, 1);
                setDefValues((prevValues) => ({
                  ...prevValues,
                  dataAssinatura: input,
                }));
              }}
            />
          </CCol>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Data Vigência</PTitulosInputs>
            <FormControlListaDeInputs
              disabled={acao === "visualizar"}
              radiusborder={aditivos?.length > 0 ? "5px 5px 0 0" : "5px"}
              value={
                defValues.dataVigencia.length === 8
                  ? formatRawDate(defValues.dataVigencia)
                  : defValues.dataVigencia
              }
              onChange={(e) => {
                const input = transformInputData(e, 1);
                setDefValues((prevValues) => ({
                  ...prevValues,
                  dataVigencia: input,
                }));
              }}
            />
          </CCol>
          <CCol>
            <PTitulosInputs>Motivo</PTitulosInputs>
            <FormControlListaDeInputs
              disabled={acao === "visualizar"}
              radiusborder={aditivos?.length > 0 ? "5px 5px 0 0" : "5px"}
              value={defValues.descricao}
              onChange={(e) =>
                setDefValues((prevValues) => ({
                  ...prevValues,
                  descricao: e.target.value,
                }))
              }
            />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Valor</PTitulosInputs>
            <InputFormatRealBrasileiro
              disabled={acao === "visualizar"}
              height={"30px"}
              radiusborder={aditivos?.length > 0 ? "5px 5px 0 0" : "5px"}
              value={defValues.valor}
              onChange={(e, valor) => {
                setDefValues((prevValues) => ({
                  ...prevValues,
                  valor: valor,
                }));
              }}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <IconAdicionar
              onClick={
                acao === "visualizar"
                  ? () => console.log("Desativado")
                  : addValues
              }
              color={acao === "visualizar" ? "#bcc1c4" : "#105200"}
              style={{ cursor: acao === "visualizar" ? "default" : "pointer" }}
            />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
        {/* - Dados - */}
        {aditivos?.map((item, index) => {
          const border = index === aditivos?.length - 1 ? "0 0 5px 5px" : "0";
          return (
            <RowAninhada key={index}>
              <CCol md={1} style={{ width: "10em" }}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={item.numeroContrato}
                />
              </CCol>
              <CCol md={1} style={{ width: "9em" }}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={item.numeroAditivo}
                />
              </CCol>
              <CCol md={1} style={{ width: "9em" }}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={formatRawDate(item.dataAssinatura)}
                />
              </CCol>
              <CCol md={1} style={{ width: "9em" }}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={
                    item.dataVigencia
                      ? formatRawDate(item.dataVigencia)
                      : "Sem Data"
                  }
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={item.descricao}
                />
              </CCol>
              <CCol md={2}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={formatToCurrency(item.valor)}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {/* <IconAdicionar /> */}
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          );
        })}
      </Row>
    </>
  );
};

export default Aditivos;
