import React, { useState } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import IconsEditInfo from "../../../../components/ListaDeInputs/IconsEditInfo";

const AbaRetencao = ({ values, setValues }) => {
  const [inputDocumento, setInputDocumento] = useState("");
  const [retencao, setRetencao] = useState({
    guia: null,
    desconto: "",
    valor: "",
  });

  const handleAddRetencao = () => {
    setValues((prevValues) => ({
      ...prevValues,
      retencao: [retencao, ...prevValues.retencao],
    }));
    clearValues();
  };

  const clearValues = () => {
    setRetencao({
      guia: "",
      desconto: "",
      valor: "",
    });
    setInputDocumento("");
  };

  return (
    <>
      <RowAninhada>
        <CCol md={2}>
          <PTitulosInputs>Guia</PTitulosInputs>
          <FloatingLabelInput
            height="30px"
            value={retencao?.guia}
            onChange={(e) => {
              setRetencao((prevValues) => ({
                ...prevValues,
                guia: e.target.value,
              }));
            }}
            radiusborder={
              values?.retencao?.length === 0 ? "5px" : "5px 5px 0 0"
            }
          />
        </CCol>
        <CCol>
          <PTitulosInputs>Desconto</PTitulosInputs>
          <MyAutoComplete
            labelFormat={(item) => `${item.nome}`}
            options={[{ nome: "teste" }, { nome: "teste2" }]}
            inputValue={inputDocumento}
            setInputValue={setInputDocumento}
            onOptionSelect={(option) => {
              setRetencao((prevValues) => ({
                ...prevValues,
                desconto: option.nome,
              }));
            }}
            radiusborder={
              values?.retencao?.length === 0 ? "5px" : "5px 5px 0 0"
            }
          />
        </CCol>
        <CCol md={3}>
          <PTitulosInputs>Valor</PTitulosInputs>
          <InputFormatRealBrasileiro
            listaDeInputs
            onChange={(_, valor) => {
              // Converte o valor digitado para número
              let valorNumerico = parseFloat(
                valor.replace(/\./g, "").replace(",", ".")
              );

              // Converte o valor novamente para o formato de string (com a vírgula)
              const valorCorrigido = valorNumerico.toFixed(2).replace(".", ",");

              // Atualiza o estado com o valor corrigido
              setRetencao((prevValues) => ({
                ...prevValues,
                valor: valorCorrigido,
              }));
            }}
            value={retencao?.valor}
            radiusborder={
              values?.retencao?.length === 0 ? "5px" : "5px 5px 0 0"
            }
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar onClick={() => handleAddRetencao()} />
        </CColButtonsAcoes2Icons>
      </RowAninhada>
      {values?.retencao?.map((item, index) => (
        <RowAninhada key={index}>
          <CCol md={2}>
            <FormControlListaDeInputs
              disabled
              height="30px"
              value={item?.guia}
              radiusborder={
                values?.retencao?.length === 1
                  ? "0 0 5px 5px"
                  : index === 0
                  ? "0"
                  : "0 0 5px 5px"
              }
            />
          </CCol>
          <CCol>
            <FormControlListaDeInputs
              disabled
              height="30px"
              value={item?.desconto}
              radiusborder={
                values?.retencao?.length === 1
                  ? "0 0 5px 5px"
                  : index === 0
                  ? "0"
                  : "0 0 5px 5px"
              }
            />
          </CCol>
          <CCol md={3}>
            <InputFormatRealBrasileiro
              listaDeInputs
              disabled
              value={item?.valor}
              radiusborder={
                values?.retencao?.length === 1
                  ? "0 0 5px 5px"
                  : index === 0
                  ? "0"
                  : "0 0 5px 5px"
              }
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <IconsEditInfo
              iconSaveShow={false}
              iconEditShow={false}
              iconCancelShow={false}
            />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
      ))}

      <RowAninhada style={{ marginTop: "15px" }}>
        <CCol md={1}></CCol>
        <CCol></CCol>
        <CCol
          md={2}
          style={{
            fontWeight: "500",
            alignItems: "center",
            display: "flex",
          }}
          className={"cor_letra_primaria"}
        >
          Valor Total
        </CCol>
        <CCol md={3}>
          <FormControlListaDeInputs
            style={{
              borderRadius: "10px",
              padding: "15px",
              border: "none",
              textAlign: "end",
            }}
            value={values?.retencao
              .reduce((acc, item) => {
                const valor = item.valor.includes(",")
                  ? parseFloat(item.valor.replace(/\./g, "").replace(",", "."))
                  : parseFloat(item.valor);
                return acc + valor;
              }, 0)
              .toFixed(2)
              .replace(".", ",")}
            disabled
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
      </RowAninhada>
    </>
  );
};

export default AbaRetencao;
