import React, { useContext, useState } from "react";
import { ContainerPag } from "../../../../components/ContainerPag/ContainerPag";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import styled from "styled-components";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { HistoricoAba } from "./HistoricoAba";
import { toast, ToastContainer } from "react-toastify";
import api from "../../../../utils/api";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const MOCK_YEARS = [
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
];

const GrpDespesa = () => {
  const { currentClient } = useContext(CurrentClientContext);

  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });

  const [inputEmpenho, setInputEmpenho] = useState("");

  const [optionsNotaEmpenho, setOptionsNotaEmpenho] = useState([
    {
      empenhoId: "",
      empenhoLabel: "",
      favorecidoId: "",
      favorecidoLabel: "",
      infosEmpenho: {
        unidadeOrcamentariaInfo: {
          unidadeOrcamentariaId: "",
          unidadeOrcamentariaLabel: "",
        },
        programaInfo: {
          programaId: "",
          programaLabel: "",
        },
        acaoInfo: {
          acaoId: "",
          acaoLabel: "",
        },
        fonteInfo: {
          fonteId: "",
          fonteLabel: "",
        },
        funcaoInfo: {
          funcaoId: "",
          funcaoLabel: "",
        },
        subfuncaoInfo: {
          subfuncaoId: "",
          subfuncaoLabel: "",
        },
        elementoInfo: {
          elementoId: "",
          elementoLabel: "",
        },
      },
    },
  ]);

  const [infosCadastro, setInfosCadastro] = useState({
    numero: "",
    ano: "",
    empenhoId: "",
    data: "",
    favorecidoId: "",
    favorecidoLabel: "",
    valor: "",
    historico: "",
    infosEmpenho: {
      unidadeOrcamentariaInfo: {
        unidadeOrcamentariaId: "",
        unidadeOrcamentariaLabel: "",
      },
      programaInfo: {
        programaId: "",
        programaLabel: "",
      },
      acaoInfo: {
        acaoId: "",
        acaoLabel: "",
      },
      fonteInfo: {
        fonteId: "",
        fonteLabel: "",
      },
      funcaoInfo: {
        funcaoId: "",
        funcaoLabel: "",
      },
      subfuncaoInfo: {
        subfuncaoId: "",
        subfuncaoLabel: "",
      },
      elementoInfo: {
        elementoId: "",
        elementoLabel: "",
      },
    },
  });

  const handleSelectYear = async (option) => {
    if (infosCadastro.ano === option.value) {
      return;
    }
    console.log(option);

    setInfosCadastro((prevState) => ({
      ...prevState,
      ano: option.value,
      empenhoId: "",
      favorecidoId: "",
      favorecidoLabel: "",
      infosEmpenho: {
        unidadeOrcamentariaInfo: {
          unidadeOrcamentariaId: "",
          unidadeOrcamentariaLabel: "",
        },
        programaInfo: {
          programaId: "",
          programaLabel: "",
        },
        acaoInfo: {
          acaoId: "",
          acaoLabel: "",
        },
        fonteInfo: {
          fonteId: "",
          fonteLabel: "",
        },
        funcaoInfo: {
          funcaoId: "",
          funcaoLabel: "",
        },
        subfuncaoInfo: {
          subfuncaoId: "",
          subfuncaoLabel: "",
        },
        elementoInfo: {
          elementoId: "",
          elementoLabel: "",
        },
      },
    }));
    setInputEmpenho("");
    await fetchDataNotaEmpenho(option.value);
  };

  const handleSelectEmpenho = (option) => {
    console.log(option);

    setInfosCadastro((prevState) => ({
      ...prevState,
      empenhoId: option.empenhoId,
      favorecidoId: option.favorecidoId,
      favorecidoLabel: option.favorecidoLabel,
      infosEmpenho: option.infosEmpenho,
    }));
    // unidadeOrcamentariaId: option.infosEmpenho.unidadeOrcamentariaInfo.unidadeOrcamentariaId,
    //   programaId: option.infosEmpenho.programaInfo.programaId,
    //   acaoId: option.infosEmpenho.acaoInfo.acaoId,
    //   fonteId: option.infosEmpenho.fonteInfo.fonteId,
    //   funcaoId: option.infosEmpenho.funcaoInfo.funcaoId,
    //   subfuncaoId: option.infosEmpenho.subfuncaoInfo.subfuncaoId,
    //   elementoId: option.infosEmpenho.elementoInfo.elementoId
  };

  const handleClearEmpenho = () => {
    setInfosCadastro((prevState) => ({
      ...prevState,
      empenhoId: "",
      favorecidoId: "",
      favorecidoLabel: "",
      infosEmpenho: {
        unidadeOrcamentariaInfo: {
          unidadeOrcamentariaId: "",
          unidadeOrcamentariaLabel: "",
        },
        programaInfo: {
          programaId: "",
          programaLabel: "",
        },
        acaoInfo: {
          acaoId: "",
          acaoLabel: "",
        },
        fonteInfo: {
          fonteId: "",
          fonteLabel: "",
        },
        funcaoInfo: {
          funcaoId: "",
          funcaoLabel: "",
        },
        subfuncaoInfo: {
          subfuncaoId: "",
          subfuncaoLabel: "",
        },
        elementoInfo: {
          elementoId: "",
          elementoLabel: "",
        },
      },
    }));
  };

  const handleChangeValor = (_, valor) => {
    const valorFloat = parseFloat(valor.replace(",", "."));

    setInfosCadastro((prevState) => ({
      ...prevState,
      valor: valorFloat,
    }));
  };

  const fetchDataNotaEmpenho = async (year) => {
    try {
      const res = await api.get(
        `notaEmpenho/${currentClient.clienteId}/${year}`
      );
      console.log(res.data);
      const optionsEmpenho = formattedOptionsNotaEmpenho(res.data);
      setOptionsNotaEmpenho(optionsEmpenho);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
    }
  };

  const formattedOptionsNotaEmpenho = (dataNotaEmpenho) => {
    const formattedOptions = dataNotaEmpenho
      .map((empenhoInfo) => {
        const fonteInfo = empenhoInfo.fonteInfo.find(
          (fonte) => fonte.id === empenhoInfo.fonteDeRecursoId
        );
        console.log(fonteInfo);

        return empenhoInfo.fichaInfo.map((info) => {
          return {
            empenhoId: empenhoInfo.id,
            empenhoLabel: `${empenhoInfo.numero} - ${empenhoInfo.fornecedorInfo.dados.nomeCompleto}`,
            favorecidoId: empenhoInfo.fornecedorInfo.dados.id,
            favorecidoLabel: `${FormatCpfPaste(
              empenhoInfo.fornecedorInfo.dados.cpf
            )} - ${empenhoInfo.fornecedorInfo.dados.nomeCompleto}`,
            infosEmpenho: {
              unidadeOrcamentariaInfo: {
                unidadeOrcamentariaId:
                  info.acaoGovernamentalInfo.unidadeOrcamentariaId.id,
                unidadeOrcamentariaLabel: `${info.acaoGovernamentalInfo.unidadeOrcamentariaId.codigoUnidadeOrcamentaria} - ${info.acaoGovernamentalInfo.unidadeOrcamentariaId.tituloUnidadeOrcamentaria}`,
              },
              programaInfo: {
                programaId:
                  info.acaoGovernamentalInfo.programaPlanejamentoId.id,
                programaLabel: `${info.acaoGovernamentalInfo.programaPlanejamentoId.numeroPrograma} - ${info.acaoGovernamentalInfo.programaPlanejamentoId.tituloPrograma}`,
              },
              acaoInfo: {
                acaoId: info.acaoGovernamentalInfo.id,
                acaoLabel: `${info.acaoGovernamentalInfo.nAcao} - ${info.acaoGovernamentalInfo.titulo}`,
              },
              fonteInfo: {
                fonteId: fonteInfo?.id,
                fonteLabel: `${fonteInfo?.conta} - ${fonteInfo?.titulo}`,
              },
              funcaoInfo: {
                funcaoId: info.acaoGovernamentalInfo.funcao.id,
                funcaoLabel: `${info.acaoGovernamentalInfo.funcao.valor} - ${info.acaoGovernamentalInfo.funcao.nome}`,
              },
              subfuncaoInfo: {
                subfuncaoId: info.acaoGovernamentalInfo.subFuncao.id,
                subfuncaoLabel: `${info.acaoGovernamentalInfo.subFuncao.valor} - ${info.acaoGovernamentalInfo.subFuncao.nome}`,
              },
              elementoInfo: {
                elementoId: info.despesaFixadaQDD.fichasQDD.id,
                elementoLabel: `${info.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo} - ${info.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.nome}`,
              },
            },
          };
        });
      })
      .flat();
    console.log(formattedOptions);
    return formattedOptions;
  };

  return (
    <ContainerPag>
      <HeaderCadastros
        NomePaginaTitulo={"GRP - Despesa"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle={"Guia de Restos a Pagar"}
      />
      <ToastContainer />
      {/* <button onClick={() => fetchDataNotaEmpenho(2024)}>2024</button>
      <button onClick={() => fetchDataNotaEmpenho(2025)}>2025</button> */}
      <RowForm>
        <CColNumero md={1}>
          <FloatingLabelInput disabled label="Número" placeholder="Número" />
        </CColNumero>
        <CColNumero md={1}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={MOCK_YEARS}
            placeholder="Ano"
            onSelect={(option) => {
              handleSelectYear(option);
            }}
          />
        </CColNumero>
        <CCol>
          <MyAutoComplete
            options={optionsNotaEmpenho}
            labelFormat={(item) => `${item.empenhoLabel}`}
            labelInput="Empenho"
            style={{ height: "37px" }}
            inputValue={inputEmpenho}
            setInputValue={setInputEmpenho}
            onOptionSelect={handleSelectEmpenho}
            iconClearFunction={handleClearEmpenho}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            onChange={(e) =>
              setInfosCadastro((prev) => ({
                ...prev,
                data: e.target.value,
              }))
            }
            externalValue={infosCadastro.data}
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            disabled
            label="Favorecido"
            placeholder="Favorecido"
            value={infosCadastro.favorecidoLabel}
          />
        </CCol>
        <CCol md={2}>
          <InputFormatRealBrasileiro
            iconReal
            onChange={handleChangeValor}
            externalValue={infosCadastro.valor}
          />
        </CCol>
      </RowForm>
      {infosCadastro.empenhoId && (
        <RowInfosAcao>
          <CCol md={7}>
            <p>
              Unidade Orçamentária:{" "}
              <span>
                {
                  infosCadastro.infosEmpenho.unidadeOrcamentariaInfo
                    .unidadeOrcamentariaLabel
                }
              </span>
            </p>
            <p>
              Programa:{" "}
              <span>
                {infosCadastro.infosEmpenho.programaInfo.programaLabel}
              </span>
            </p>
            <p>
              Ação: <span>{infosCadastro.infosEmpenho.acaoInfo.acaoLabel}</span>
            </p>
            <p>
              Fonte de Recursos - CO:{" "}
              <span>{infosCadastro.infosEmpenho.fonteInfo.fonteLabel}</span>
            </p>
          </CCol>
          <CCol>
            <p>
              Função:{" "}
              <span>{infosCadastro.infosEmpenho.funcaoInfo.funcaoLabel}</span>
            </p>
            <p>
              Subfunção:{" "}
              <span>
                {infosCadastro.infosEmpenho.subfuncaoInfo.subfuncaoLabel}
              </span>
            </p>
            <p>
              Elemento:{" "}
              <span>
                {infosCadastro.infosEmpenho.elementoInfo.elementoLabel}
              </span>
            </p>
          </CCol>
        </RowInfosAcao>
      )}

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            disabled
            label="Fonte de Recursos Pagamento"
            placeholder="Fonte de Recursos Pagamento"
          />
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={["Histórico"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && (
        <HistoricoAba
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
        />
      )}

      <div className="p-3"></div>
    </ContainerPag>
  );
};

export default GrpDespesa;
