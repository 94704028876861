import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { NotaFiscal } from "./NotaFiscal";
import { OrdemCronologicaAba } from "./OrdemCronologicaAba";
import { EfdReinfAba } from "./EfdReinfAba";
import { TestButton } from "../../../../utils/TestButton";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { getCpfsCnpjsPorCliente } from "../../../../services/gets/getCpfsCnpjsPorCliente";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useOpenedModals } from "../../../../hooks/useOpenedModals";
import { getCurrentDate } from "../../../../utils/getCurrentDate";
import { Agrupamento } from "./Agrupamento";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import ProdutoEAquisicaoAba from "../NotaDeEmpenho/ProdutoEAquisicaoAba";
import { toast, ToastContainer } from "react-toastify";
import HistoricoAba from "../NotaDeEmpenho/HistoricoAba";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../utils/api";
import { formatValue } from "../../../Paginas_Sistemas_Planejamento/Impressos/Relatorios/utils/formatValue";
import SaldosAba from "../NotaDeEmpenho/SaldosAba";

const LiquidacaoDeEmpenho = () => {
  const navigate = useNavigate();
  const { liquidacaoAtualId } = useParams();
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useContext(CurrentYearContext);
  const { openedModals, setOpenedModals } = useOpenedModals();
  const [empenho, setEmpenho] = useState([]);
  const [produtoAquisicao, setProdutoAquisicao] = useState([]);
  const [abaSelecionada, setAbaSelecionada] = useState({});
  const [fichas, setOptionsFichas] = useState([]);
  const [saldos, setSaldos] = useState({
    empenhado: "",
    liquidado: "",
  });
  const [values, setValues] = useState({
    empenho: {},
    data: getCurrentDate(),
    responsavelPeloAtesto: {
      responsavel: "",
      data: getCurrentDate(),
    },
    unidadeOrcamentaria: {
      codigo: "",
      titulo: "",
    },
    elementoDespesa: {
      codigo: "",
      descricao: "",
      tipoPessoa: "",
    },
    subelemento: {
      codigo: "",
      descricao: "",
    },
    fonteRecursos: {
      codigo: "",
      tipoDeRecurso: "",
      descricao: "",
    },
    modalidade: "",
    notaFiscal: {
      numero: "",
      serie: "",
      tipo: "",
      data: "",
      valor: "",
      numeroChave: "",
    },
    acao: {},
    liquidacao: null,
    descricao: "",
  });
  const [inputFornecedor, setInputFornecedor] = useState("");
  const [inputEmpenho, setInputEmpenho] = useState("");
  const [optionsCpf, setOptionsCpf] = useState([]);
  const [abas, setAbas] = useState([
    "Nota Fiscal",
    "Produto e Aquisição",
    "Ordem Cronológica",
    "EFD-Reinf",
    "Agrupamentos",
    "Saldos",
  ]);
  const [valuesToTest, setValuesToTest] = useState({
    elemento: "",
    subElemento: "",
  });
  const [valorMaximo, setValorMaximo] = useState(null);

  const elementsList = ["30", "32", "35", "36", "39", "51", "52"];

  const fetchCpfs = async (reqDeModal) => {
    const data = await getCpfsCnpjsPorCliente(currentClient.clienteId, "cpf");

    if (data) {
      setOptionsCpf(
        data.map((item) => ({
          id: item.pessoaId,
          nome: item.nomeCompleto,
          cpf: FormatCpfPaste(item.cpf),
        }))
      );

      if (reqDeModal) {
        const dado = data.reverse()[0];

        setInputFornecedor(
          `${dado.nomeCompleto} - ${FormatCpfPaste(dado.cpf)}`
        );

        setOpenedModals((prev) =>
          prev.filter((item) => item.tituloModal !== "CPF/CNPJ")
        );
      }
    }
  };

  const fetchLiquidacao = async () => {
    if (values?.empenho?.id && !liquidacaoAtualId) {
      try {
        const response = await api.get(
          `/liquidacaoDeEmpenho/nextLiquidacao/${values.empenho?.id}`
        );
        const saldoEmpenhado = parseFloat(values?.empenho?.saldoEmpenhado || 0);
        const saldoLiquidado = parseFloat(values?.empenho?.saldoLiquidado || 0);

        const valorRestante = saldoEmpenhado - saldoLiquidado;
        console.log(
          "AQUIII: ",
          values?.empenho?.saldoEmpenhado,
          values?.empenho?.saldoLiquidado,
          valorRestante
        );
        if (valorRestante !== 0) {
          setValues((prevValues) => ({
            ...prevValues,
            liquidacao: response.data.liquidacao,
            valor: formatValue(valorRestante),
          }));
          setValorMaximo(valorRestante);
        } else {
          handleClearEmpenhoAndData();
          setInputEmpenho("");
          setInputFornecedor("");
          toast.warn("Esse empenho já está liquidado!");
        }
        console.log("RESPONSE: ", response.data);
      } catch (error) {
        console.log(
          error.response.data.error ||
            "Não foi possível buscar o número de liquidação."
        );
      }
    }
  };

  const handleAddNewCpf = () => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: "CPF/CNPJ", requisicaoDoModalConcluida: false },
    ]);
  };

  const handleOptionSelect = (item) => {
    setValues((prevValues) => ({
      ...prevValues,
      responsavelPeloAtesto: {
        ...prevValues.responsavelPeloAtesto,
        responsavel: item.id,
      },
    }));
  };

  const fetchEmpenho = async () => {
    try {
      const response = await api.get(
        `/notaEmpenho/${currentClient.clienteId}/${currentYear}`
      );
      setEmpenho(response.data);
    } catch (error) {}
  };

  const handleSelectEmpenho = (option) => {
    console.log("HANDLE SELECT EMPENHO: ", option);
    const [codigo, ...descricaoArray] = option.subelemento.split(" - ");
    setValues((prevValues) => ({
      ...prevValues,
      empenho: option,
      subelemento: {
        codigo: codigo.trim(),
        descricao: descricaoArray.join(" - ").trim(),
      },
    }));
    setProdutoAquisicao(
      option.produtos.map((item) => ({
        ...item,
        produtoAquisicao: {
          id: item?.produtoAquisicaoId,
          value: item?.numero,
          label: `${item?.numero} - ${item?.produtoAquisicao}`,
        },
        unidadeMedida: item.unidadeDeMedida,
      }))
    );
    setSaldos({
      empenhado: option.saldoEmpenhado || "00.00",
      liquidado: option.saldoLiquidado || "00.00",
    });

    if (!liquidacaoAtualId) {
      const parts = option.label.trim().split(" - ");
      const firstPart = parts[0].trim(); // Primeira parte (0000001)
      const lastPart = parts[parts.length - 1].trim();
      setInputEmpenho(`${firstPart.trim()} - ${lastPart}`);
    } else {
      setInputEmpenho(
        `${option.numero} - ${option.fornecedorInfo.dados.nomeCompleto}`
      );
    }
  };

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      empenho: {
        ...prevValues.empenho,
        produtos: produtoAquisicao, // Aqui você atualiza apenas a propriedade produtos
      },
    }));
  }, [produtoAquisicao]);

  const fetchQddFichas = async () => {
    try {
      const resp = await api.get(
        `/qdd/${currentClient.clienteId}/${currentYear}`
      );
      const dados = resp.data;

      const arrayDeFichas = dados.map((item) => {
        const fichaReferente = item.despesaFixadaQDD.fichasQDD;
        return {
          label: `Ficha ${fichaReferente.numeroFicha}`,
          value: fichaReferente.id,
          ficha: item,
        };
      });

      setOptionsFichas(arrayDeFichas);
    } catch (error) {
      console.error("Erro ao buscar fichas:", error);
    }
  };

  useEffect(() => {
    const selectedFicha = fichas.find(
      (item) => item.value === values.empenho.fichaId
    );

    if (selectedFicha) {
      const { nAcao, titulo } = selectedFicha.ficha.acaoGovernamentalInfo;
      setValues((prevValues) => ({
        ...prevValues,
        acao: `${nAcao} - ${titulo}`,
        unidadeOrcamentaria: {
          titulo:
            selectedFicha.ficha.acaoGovernamentalInfo.unidadeOrcamentariaId
              .tituloUnidadeOrcamentaria,
          codigo:
            selectedFicha.ficha.acaoGovernamentalInfo.unidadeOrcamentariaId
              .codigoUnidadeOrcamentaria,
        },
        elementoDespesa: {
          codigo:
            selectedFicha.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo.slice(
              0,
              -3
            ),
          descricao:
            selectedFicha.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
              .funcao,
          tipoPessoa:
            selectedFicha.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
              .nome,
        },
        fonteRecursos: {
          codigo: selectedFicha.ficha.despesaFixadaQDD.fonteDeRecurso.conta,
          tipoDeRecurso:
            selectedFicha.ficha.despesaFixadaQDD.fonteDeRecurso.titulo,
          descricao: selectedFicha.ficha.despesaFixadaQDD.fonteDeRecurso.funcao,
        },
        modalidade: values.empenho.tipoDeEmpenho,
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        acao: ``,
        liquidacao: null,
      }));
    }
  }, [fichas, values.empenho]);

  useEffect(() => {
    const modalCpfCnpj = openedModals.find(
      (item) => item.tituloModal === "CPF/CNPJ"
    );

    if (modalCpfCnpj && modalCpfCnpj.requisicaoDoModalConcluida) {
      fetchCpfs(true);
    }

    //eslint-disable-next-line
  }, [openedModals]);

  useEffect(() => {
    if (!elementsList.includes(valuesToTest.elemento.slice(-2))) {
      setAbas((prevAbas) =>
        prevAbas.filter(
          (aba) => aba !== "Nota Fiscal" && aba !== "Agrupamentos"
        )
      );
    } else {
      setAbas((prevAbas) => {
        const newAbas = [...prevAbas];

        if (!newAbas.includes("Nota Fiscal")) {
          newAbas.unshift("Nota Fiscal");
        }

        if (!newAbas.includes("Agrupamentos")) {
          newAbas.splice(-1, 0, "Agrupamentos");
        }

        return newAbas;
      });
    }
    // eslint-disable-next-line
  }, [valuesToTest]);

  useEffect(() => {
    setValuesToTest({
      elemento: "3.3.90.39",
      subElemento: "17",
    });
  }, []);

  useEffect(() => {
    fetchCpfs();
    fetchEmpenho();
    fetchQddFichas();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchLiquidacaoAtual();
    // eslint-disable-next-line
  }, [optionsCpf]);

  const elementos = [
    `30`,
    `31`,
    `32`,
    `33`,
    `34`,
    `35`,
    `36`,
    `37`,
    `38`,
    `39`,
    `51`,
    `52`,
  ];

  useEffect(() => {
    const codigo = values?.elementoDespesa?.codigo.split(".")[3];
    if (codigo && elementos.includes(codigo)) {
      setAbas(["Histórico", "Produto e Aquisição", "Complemento", "Saldos"]);
    } else {
      setAbas(["Histórico", "Complemento", "Saldos"]);
    }
    // eslint-disable-next-line
  }, [values]);

  const handleClearEmpenhoAndData = () => {
    setValues((prevValues) => ({
      ...prevValues,
      empenho: {},
      data: getCurrentDate(),
      responsavelPeloAtesto: {
        responsavel: "",
        data: getCurrentDate(),
      },
      unidadeOrcamentaria: {
        codigo: "",
        titulo: "",
      },
      elementoDespesa: {
        codigo: "",
        descricao: "",
        tipoPessoa: "",
      },
      subelemento: {
        codigo: "",
        descricao: "",
      },
      fonteRecursos: {
        codigo: "",
        tipoDeRecurso: "",
        descricao: "",
      },
      modalidade: "",
      notaFiscal: {
        numero: "",
        serie: "",
        tipo: "",
        data: "",
        valor: "",
        numeroChave: "",
      },
      acao: {},
      liquidacao: null,
      valor: null,
    }));
  };

  const handleSave = async () => {
    const valuesToPost = {
      clienteId: currentClient.clienteId,
      empenhoId: values.empenho.id,
      acaoId: values.empenho.fichaInfo[0].acaoGovernamentalId,
      liquidacao: String(values.liquidacao),
      descricao: values.descricao || "",
      data: values.data,
      responsavelId: values.responsavelPeloAtesto.responsavel,
      valor: values.valor.includes(",")
        ? values.valor.replace(/\./g, "").replace(",", ".")
        : values.valor,
      produtos: values.empenho.produtos.map((item) => ({
        produtoAquisicaoId: item.produtoAquisicao.id,
        quantidade: item.quantidade,
        valor: item.valor.includes(",")
          ? item.valor.replace(/\./g, "").replace(",", ".")
          : item.valor,
      })),
    };

    if (!liquidacaoAtualId) {
      try {
        const response = await api.post("/liquidacaoDeEmpenho", valuesToPost);
        toast.success("Liquidação de empenho cadastrada com sucesso!");
        console.log(response.data);
        navigate("/contabilidade/despesa/consulta/liquidacao-de-empenho");
      } catch (error) {
        const missingFields = [];
        if (!valuesToPost.responsavelId) {
          missingFields.push("Faltando Responsável");
        }

        const errorMessage =
          error.response?.data?.error ||
          "Não foi possível cadastrar essa liquidação de empenho";

        const detailedMessage = missingFields.length
          ? `${errorMessage}, ${missingFields.join(", ")}`
          : errorMessage;

        console.log(detailedMessage, "Os dados enviados foram: ", valuesToPost);

        toast.error(detailedMessage);

        console.log(error);
      }
    } else {
      console.log("VEIO PRA O ELSE");
      const valuesToPut = {
        ...valuesToPost,
        id: values?.empenhoId,
        responsavelId: values.responsavelId,
      };
      console.log("AAAAAAAAAAAAAAAAA", values);
      try {
        console.log(valuesToPut);
        const response = await api.put(
          `/liquidacaoDeEmpenho/${liquidacaoAtualId}`,
          valuesToPut
        );
        toast.success("Liquidação de empenho atualizada com sucesso!");
        console.log(response.data);
        navigate("/contabilidade/despesa/consulta/liquidacao-de-empenho");
      } catch (error) {
        const missingFields = [];
        if (!valuesToPut.responsavelId) {
          missingFields.push("Faltando Responsável");
        }

        const errorMessage =
          error.response?.data?.error ||
          "Não foi possível atualizar essa liquidação de empenho";

        const detailedMessage = missingFields.length
          ? `${errorMessage}, ${missingFields.join(", ")}`
          : errorMessage;

        console.log(detailedMessage, "Os dados enviados foram: ", valuesToPut);

        toast.error(detailedMessage);

        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchLiquidacao();
    // eslint-disable-next-line
  }, [values.empenho.id]);

  const fetchLiquidacaoAtual = async () => {
    if (liquidacaoAtualId) {
      try {
        const response = await api.get(
          `/liquidacaoDeEmpenho/${liquidacaoAtualId}`
        );
        setValues((prevValues) => ({
          ...prevValues,
          ...response.data,
          data: new Date(response.data.data).toISOString().split("T")[0],
        }));
        handleSelectEmpenho(response.data.empenho);
        const fornecedorEncontrado = optionsCpf.find(
          (option) => option.id === parseInt(response.data.responsavelId)
        );
        setInputFornecedor(fornecedorEncontrado?.nome);
        console.log("A RESPONSE VEIO ASIIIIM: ", response.data);
      } catch (error) {
        if (error?.response?.data) {
          toast.warn(
            error?.response?.data?.error ||
              "Não foi possível carregar a Liquidação!"
          );
        }
        console.log(error);
      }
    }
  };

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <ToastContainer />
      <TestButton onClick={() => console.log("valus", values)}>
        values
      </TestButton>
      <HeaderCadastros
        PaginaSubtitulo={"Cadastro"}
        NomePaginaTitulo={"Liquidação de Empenho - LE"}
        ButtonSaveFunction={handleSave}
        PaginaConsulta={"/contabilidade/despesa/consulta/liquidacao-de-empenho"}
      />

      <RowForm>
        <TestButton
          onClick={() => console.log("Produtos", values.empenho.produtos)}
        >
          produtos
        </TestButton>
        <CCol>
          <MyAutoComplete
            iconsOff={liquidacaoAtualId}
            disabled={liquidacaoAtualId}
            inputValue={inputEmpenho}
            setInputValue={setInputEmpenho}
            options={empenho}
            labelFormat={(item) => {
              const fichaCorrespondente = fichas.find(
                (ficha) => ficha.value === item.fichaId
              );
              const fornecedorEncontrado = optionsCpf.find(
                (option) => option.id === parseInt(item.fornecedor)
              );

              const numero = item.numero || "N/A";
              const nAcao =
                fichaCorrespondente?.ficha?.acaoGovernamentalInfo?.nAcao ||
                "N/A";
              const naturezaDaDespesa =
                fichaCorrespondente?.ficha?.despesaFixadaQDD?.fichasQDD?.naturezaDaDespesa?.codigo.slice(
                  0,
                  -3
                ) || "N/A";
              const fornecedor = fornecedorEncontrado
                ? fornecedorEncontrado.nome
                : "N/A";

              return `${numero} - ${nAcao} - ${naturezaDaDespesa} - ${fornecedor}`;
            }}
            labelInput="Empenho"
            style={{ height: "37px" }}
            onOptionSelect={(option) => handleSelectEmpenho(option)}
            iconClearFunction={handleClearEmpenhoAndData}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            label="Liquidação"
            placeholder="Liquidação"
            disabled
            value={values.liquidacao}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            disabled={liquidacaoAtualId}
            value={values.data}
            onInput={(e) => {
              setValues({
                ...values,
                data: e.target.value,
              });
            }}
          />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            disabled
            placeholder="Ação"
            label="Ação"
            value={values.acao}
          />
        </CCol>
        <CCol md={4}>
          <InputFormatRealBrasileiro
            disabled={
              Object.keys(values?.empenho || {}).length === 0 ||
              values?.modalidade === "1 - Ordinário" ||
              liquidacaoAtualId
            }
            iconReal
            value={values?.valor}
            onChange={(_, valor) => {
              // Converte o valor digitado para número
              let valorNumerico = parseFloat(
                valor.replace(/\./g, "").replace(",", ".")
              );

              // Se o valor for maior que valorMaximo, ajusta para valorMaximo
              if (valorNumerico > valorMaximo) {
                valorNumerico = valorMaximo;
              }

              // Converte o valor novamente para o formato de string (com a vírgula)
              const valorCorrigido = valorNumerico.toFixed(2).replace(".", ",");

              // Atualiza o estado com o valor corrigido
              setValues((prevValues) => ({
                ...prevValues,
                valor: valorCorrigido,
              }));
            }}
          />
        </CCol>
      </RowForm>

      {values.empenho.fichaId && (
        <RowInfosAcao>
          <CCol>
            <p>
              Unidade Orçamentária:{" "}
              <span>{`${values?.unidadeOrcamentaria?.codigo} - ${values?.unidadeOrcamentaria?.titulo}`}</span>
            </p>
            <p>
              Elemento de Despesa:{" "}
              <span>
                {`${values?.elementoDespesa?.codigo} - ${values?.elementoDespesa?.tipoPessoa}`}
              </span>
            </p>
            <p>
              Subelemento:{" "}
              <span>
                {`${values?.subelemento?.codigo} - ${values?.subelemento?.descricao}`}
              </span>
            </p>
            <p>
              Fonte de Recursos - CO:{" "}
              <span>
                {`${values?.fonteRecursos?.codigo} - ${values?.fonteRecursos?.tipoDeRecurso}`}
              </span>
            </p>
            <p>
              Modalidade: <span>{values?.modalidade}</span>
            </p>
          </CCol>
        </RowInfosAcao>
      )}
      <RowForm className="mt-1">
        <CCol>
          <MyAutoComplete
            iconsOff={liquidacaoAtualId}
            disabled={liquidacaoAtualId}
            labelInput="Responsável pelo Atesto"
            options={optionsCpf}
            inputValue={inputFornecedor}
            setInputValue={setInputFornecedor}
            addNewDataFunction={handleAddNewCpf}
            labelFormat={(item) => `${item.nome} - ${item.cpf}`}
            style={{ height: "37px" }}
            onOptionSelect={handleOptionSelect}
          />
        </CCol>
        {/* <CCol md={2}>
          <InputData
            value={values.responsavelPeloAtesto.data}
            onInput={(e) => {
              setValues({
                ...values,
                responsavelPeloAtesto: {
                  ...values.responsavelPeloAtesto,
                  data: e.target.value,
                },
              });
            }}
          />
        </CCol> */}
      </RowForm>

      {Object.keys(values?.empenho || {}).length > 0 ? (
        <RowSelecaoAbasInferior
          abas={abas}
          abaSelecionada={abaSelecionada}
          setAbaSelecionada={setAbaSelecionada}
          alwaysSelectFirst
        />
      ) : (
        <></>
      )}

      {abaSelecionada.nomeAba === "Histórico" && (
        <HistoricoAba values={values} setValues={setValues} />
      )}

      {abaSelecionada.nomeAba === "Nota Fiscal" && (
        <NotaFiscal values={values} setValues={setValues} />
      )}
      {abaSelecionada.nomeAba === "Produto e Aquisição" && (
        <ProdutoEAquisicaoAba
          produtoAquisicao={produtoAquisicao}
          setProdutoAquisicao={setProdutoAquisicao}
          valorTotal={
            values?.empenho?.saldoEmpenhado - values?.empenho?.saldoLiquidado
          }
          liquidacaoAtualId={liquidacaoAtualId}
        />
      )}
      {abaSelecionada.nomeAba === "Ordem Cronológica" && (
        <OrdemCronologicaAba />
      )}
      {abaSelecionada.nomeAba === "EFD-Reinf" && <EfdReinfAba />}
      {abaSelecionada.nomeAba === "Saldos" && <SaldosAba saldos={saldos} />}
      {abaSelecionada.nomeAba === "Agrupamentos" && <Agrupamento />}

      <div className="p-3" style={{ minHeight: "80px" }}></div>
    </Container>
  );
};

export default LiquidacaoDeEmpenho;
