import React, { useState } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowToModal,
} from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { Icon } from "@iconify/react/dist/iconify.js";
import api from "../../../../utils/api";
import { toast } from "react-toastify";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const ReceitaExtraAba = ({
  infosCadastro,
  setInfosCadastro,
  clienteId,
  inputExercicio,
  setInputExercicio,
  inputGuiaReceita,
  setInputGuiaReceita,
  infosAddReceitaExtra,
  setInfosAddReceitaExtra,
}) => {
  const [optionsGuiaReceita, setOptionsGuiaReceita] = useState([
    {
      exercicio: "",
      guiaReceitaId: "",
      saldo: "",
      label: "",
      numero:''
    },
  ]);

  const handleSelectExercicio = async (option) => {
    console.log(option);

    setInfosAddReceitaExtra((prevState) => ({
      ...prevState,
      exercicio: option.label,
    }));

    if (!infosCadastro.qdrdeId) {
      return;
    }

    const optionsGre = await fetchDataGRE(option.label, infosCadastro.qdrdeId);
    if (!optionsGre) {
      setOptionsGuiaReceita([
        {
          exercicio: "",
          guiaReceitaId: "",
          saldo: "",
          label: "",
          numero:''
        },
      ]);
      return;
    }
    setOptionsGuiaReceita(optionsGre);
  };

  const handleSelectGuiaReceita = (option) => {
    console.log(option);

    setInfosAddReceitaExtra(option);
  };

  const handleChangeSaldoGuia = (_, valor) => {
    const valorFloat = parseFloat(valor.replace(",", "."));

    setInfosAddReceitaExtra((prevState) => ({
      ...prevState,
      saldo: valorFloat,
    }));
  };

  const resetGuiaReceitaFields = (clearYear = false) => {
    setInfosAddReceitaExtra({
      exercicio: clearYear ? "" : infosAddReceitaExtra.exercicio,
      guiaReceitaId: "",
      saldo: "0,00",
      label: "",
      numero:''
    });
    setInputGuiaReceita("");
    if (clearYear) {
      setInputExercicio("");
    }
  };

  const handleAddReceitaExtra = () => {
    console.log(infosCadastro);

    console.log(infosAddReceitaExtra);
    const filterReceita = infosCadastro.receitaExtra.filter(
      (receita) => receita.guiaReceitaId
    );
    console.log(filterReceita);

    const hasAddedGuiaReceita = filterReceita.filter(
      (receita) =>
        receita?.guiaReceitaId === infosAddReceitaExtra.guiaReceitaId &&
        receita?.exercicio === infosAddReceitaExtra.exercicio
    ).length;
    console.log(hasAddedGuiaReceita);

    if (hasAddedGuiaReceita) {
      toast.info("Esta guia já foi adicionada.");
      return;
    }

    setInfosCadastro((prevState) => ({
      ...prevState,
      receitaExtra: [...filterReceita, infosAddReceitaExtra],
    }));
    resetGuiaReceitaFields(true);
  };

  const handleDeleteGuiaReceita = (guiaReceitaId, exercicio) => {
    console.log(guiaReceitaId);
    const filterReceitas = infosCadastro.receitaExtra.filter(
      (receita) =>
        receita.guiaReceitaId !== guiaReceitaId &&
        receita.exercicio !== exercicio
    );
    setInfosCadastro((prevState) => ({
      ...prevState,
      receitaExtra: filterReceitas,
    }));
  };

  const fetchDataGRE = async (year, qdrdeId) => {
    try {
      const resp = await api.get(
        `gre/por-qdrde/${clienteId}/${qdrdeId}/${year}`
      );
      const data = resp.data;
      const dataFormatted = data
        .map((greInfo) => {
          const infosGre = greInfo.gre.qdrdeInfo.map((qdrde) => {
            return {
              exercicio: year,
              guiaReceitaId: greInfo.gre.id,
              saldo: greInfo.gre.saldo,
              label: `${greInfo.gre.numero} - ${qdrde.titulo}`,
              numero:greInfo.gre.numero
              // label: `${greInfo.gre.numero.replace(/^0+/, '')} - ${qdrde.titulo}`,
              // numero:greInfo.gre.numero.replace(/^0+/, '')
            };
          });
          return infosGre;
        })
        .flat();
      console.log(dataFormatted);
      return dataFormatted;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  console.log(infosCadastro);

  return (
    <>
      <RowToModal
        className={"row_form mt-3"}
        style={{
          borderRadius: "5px 5px 0px 0px",
          padding: "8px 10px",
        }}
      >
        <CCol
          style={{
            fontSize: "16px",
            color: "#515c70",
            fontWeight: "700",
          }}
        >
          Receita Extra
        </CCol>
      </RowToModal>
      <RowToModal className={"row_form_2 mt-1"}>
        <CCol md={2}>
          <PTitulosInputs>Exercício</PTitulosInputs>
        </CCol>
        <CCol>
          <PTitulosInputs>Guia da Receita</PTitulosInputs>
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Saldo da Guia</PTitulosInputs>
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
        </CColButtonsAcoes2Icons>

        <RowAninhada>
          <CCol md={2}>
            <MyAutoComplete
              radiusborder={
                infosCadastro.receitaExtra.filter(
                  (receita) => receita.guiaReceitaId
                ).length > 0
                  ? "5px 5px 0px 0px"
                  : "5px"
              }
              inputValue={inputExercicio}
              setInputValue={setInputExercicio}
              options={
                [
                  { label: "2024", value: "2024" },
                  { label: "2025", value: "2025" },
                ] || []
              }
              labelFormat={(option) => `${option.label} - ${option.value}`}
              onOptionSelect={handleSelectExercicio}
              iconClearFunction={() => resetGuiaReceitaFields(true)}
            />
          </CCol>
          <CCol>
            <MyAutoComplete
              radiusborder={
                infosCadastro.receitaExtra.filter(
                  (receita) => receita.guiaReceitaId
                ).length > 0
                  ? "5px 5px 0px 0px"
                  : "5px"
              }
              inputValue={inputGuiaReceita}
              setInputValue={setInputGuiaReceita}
              options={[...optionsGuiaReceita]}
              labelFormat={(option) => `${option.label}`}
              onOptionSelect={handleSelectGuiaReceita}
              iconClearFunction={resetGuiaReceitaFields}
            />
          </CCol>
          <CCol md={2}>
            <InputFormatRealBrasileiro
              radiusborder={
                infosCadastro.receitaExtra.filter(
                  (receita) => receita.guiaReceitaId
                ).length > 0
                  ? "5px 5px 0px 0px"
                  : "5px"
              }
              disabled
              onChange={handleChangeSaldoGuia}
              listaDeInputs={true}
              externalValue={infosAddReceitaExtra.saldo}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <Icon
              icon="ic:baseline-add-box"
              color="#105200"
              height="28"
              onClick={handleAddReceitaExtra}
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "1px",
              }}
            />
          </CColButtonsAcoes2Icons>
          {infosCadastro.receitaExtra.map((item, index, array) => {
            if (!item.guiaReceitaId) return null;
            return (
              <RowAninhada key={index} style={{ marginTop: "-1px" }}>
                <CCol md={2}>
                  <FormControlListaDeInputs
                    disabled
                    value={item.exercicio}
                    radiusborder={
                      array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                    }
                  />
                </CCol>
                <CCol>
                  <FormControlListaDeInputs
                    value={item.label}
                    disabled
                    radiusborder={
                      array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                    }
                  />
                </CCol>

                <CCol md={2}>
                  <FormControlListaDeInputs
                    disabled
                    style={{ textAlign: "end" }}
                    value={FormatValueToLocaleString(item.saldo)}
                    radiusborder={
                      array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                    }
                  />
                </CCol>
                <CColButtonsAcoes2Icons md={1}>
                  <Icon
                    icon="ic:baseline-delete"
                    color="#E79900"
                    height="28"
                    onClick={() =>
                      handleDeleteGuiaReceita(
                        item.guiaReceitaId,
                        item.exercicio
                      )
                    }
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginTop: "1px",
                    }}
                  />
                </CColButtonsAcoes2Icons>
              </RowAninhada>
            );
          })}
        </RowAninhada>
      </RowToModal>
    </>
  );
};
// import React from "react";
// import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
// import { CCol, RowForm } from "../../../../components/Grid/CCol";
// import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
// import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";

// export const ReceitaExtraAba = ({ infosCadastro, setInfosCadastro }) => {
//   return (
//     <>
//       <RowTituloAba style={{ marginTop: "15px" }}>
//         <div>
//           <span>Receita Extra</span>
//         </div>
//       </RowTituloAba>

//       <RowForm $borderRadiusType={2} className="mt-1">
//         <CCol md={2}>
//           <MyAutoComplete
//             options={[{ label: "2025", value: "2025" }]}
//             labelFormat={(item) => `${item.label} - ${item.value}`}
//             labelInput="Exercício"
//             style={{ height: "37px" }}
//           />
//         </CCol>
//         <CCol>
//           <MyAutoComplete
//             options={[{ label: "teste", value: "teste" }]}
//             labelFormat={(item) => `${item.label} - ${item.value}`}
//             labelInput="Guia da Receita"
//             style={{ height: "37px" }}
//           />
//         </CCol>
//         <CCol md={2}>
//           <FloatingLabelInput
//             label="Saldo da Guia"
//             placeholder="Saldo da Guia"
//           />
//         </CCol>
//       </RowForm>
//     </>
//   );
// };
