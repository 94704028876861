import React, { useContext } from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { optionsTranferencias } from "../GreReceita/optionsTranferenciasRecebidas";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

export const TransfConcedidaAba = ({
  optionsUnidadesGestoras,
  infosCadastro,
  setInfosCadastro,
  inputUnidadeGestoraValue,
  setInputUnidadeGestoraValue,
  inputTipoTransferenciaValue,
  setInputTipoTransferenciaValue,
  optionsTipoTransferencia,
  setOptionsTipoTranferencias,
}) => {
  const { currentClient } = useContext(CurrentClientContext);

  const handleSelectUnidadeGestora = (optionUnidade) => {
    console.log(optionUnidade);

    const tipoNaturezaJuridicaIsCamara =
      optionUnidade.tipoNaturezaJuridica === "1 - Câmara Municipal";

    const currentClientIsPrefeitura =
      currentClient.tipoNaturezaJuridica ===
      "2 - Prefeitura Municipal/Secretarias";

    if (tipoNaturezaJuridicaIsCamara && currentClientIsPrefeitura) {
      const filterOptionsTransferencia = optionsTranferencias.filter(
        (item) => item.label === "1 - Duodécimo"
      );
      console.log(filterOptionsTransferencia);

      setOptionsTipoTranferencias(filterOptionsTransferencia);
      setInputTipoTransferenciaValue(filterOptionsTransferencia[0].value);
      setInfosCadastro((prev) => ({
        ...prev,
        unidadeGestoraId: optionUnidade.value,
        tipoNaturezaJuridica: optionUnidade.tipoNaturezaJuridica,
        tipoTransferencia: filterOptionsTransferencia[0].label,
      }));
      return;
    }
    console.log(optionsTranferencias);

    setOptionsTipoTranferencias(optionsTranferencias);
    setInfosCadastro((prev) => ({
      ...prev,
      unidadeGestoraId: optionUnidade.value,
      tipoNaturezaJuridica: optionUnidade.tipoNaturezaJuridica,
    }));
  };

  const handleSelectTipoTransferencia = (optionTipoTranferencia) => {
    console.log(optionTipoTranferencia);
    setInfosCadastro((prev) => ({
      ...prev,
      tipoTransferencia: optionTipoTranferencia.label,
    }));
  };

  console.log(currentClient);
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Transferência Concedida</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            placeholder="Unidade Gestora Recebedora"
            label="Unidade Gestora Recebedora"
            value={inputUnidadeGestoraValue}
            options={
              optionsUnidadesGestoras.length
                ? optionsUnidadesGestoras
                : [{ label: "Unidade Gestora Recebedora", value: null }]
            }
            onSelect={(option) => {
              handleSelectUnidadeGestora(option);
              setInputUnidadeGestoraValue(option.value);
            }}
          />
        </CCol>
        <CCol md={4}>
          <FloatingLabelInput
            disabled
            placeholder="Natureza Jurídica"
            label="Natureza Jurídica"
            value={infosCadastro.tipoNaturezaJuridica}
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            placeholder="Tipo de Transferência"
            label="Tipo de Transferência"
            value={inputTipoTransferenciaValue}
            options={[
              { label: "Tipo de Transferência", value: null },
              ...optionsTipoTransferencia,
            ]}
            onSelect={(option) => {
              handleSelectTipoTransferencia(option);
              setInputTipoTransferenciaValue(option.value);
            }}
          />
        </CCol>
      </RowForm>
    </>
  );
};
