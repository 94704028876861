import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import InputSvg from "../../../../components/Grid/InputSvg";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { useFetchData } from "../../../../hooks/useFetchData";
import { useNavigate } from "react-router-dom";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import IconEdit from "../../../../components/ListaDeInputs/IconEdit";
import IconDelete from "../../../../components/ListaDeInputs/IconDelete";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { optionsTipoLancamento } from "./optionsTipoLancamento";
import { toast, ToastContainer } from "react-toastify";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import api from "../../../../utils/api";

function ConsultaGuiaReceita() {
  const { currentYear } = useCurrentYear();
  const { currentClient } = useContext(CurrentClientContext);

  const navigate = useNavigate();

  const { data: dataGuiaReceita, loading } = useFetchData(
    `/guiaDeReceita/${currentClient.clienteId}/${currentYear}`
  );

  const [dataGuiaReceitaFormatted, setDataGuiaReceitaFormatted] = useState([
    {
      numero: "",
      id: "",
      natureza: "",
      tipoLancamento: "",
      date: "",
      devedor: "",
      contaBancaria: "",
      valor: "",
      saldo: "",
      isDeleteGuia:''
    },
  ]);
  const [modalOpened, setModalOpened] = useState(false);
  const [dataDelete, setDataDelete] = useState({ id: 0 });
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [dataGuiaReceitaFiltered, setDataGuiaReceitaFiltered] = useState([
    {
      numero: "",
      id: "",
      natureza: "",
      tipoLancamento: "",
      date: "",
      devedor: "",
      contaBancaria: "",
      valor: "",
      saldo: "",
      isDeleteGuia:''
    },
  ]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = dataGuiaReceitaFormatted.filter((item) => {
      return (
        item.numero.includes(value) ||
        item.natureza.includes(value) ||
        item.natureza.replaceAll(".", "").includes(value.replaceAll(".", "")) ||
        item.devedor.toLowerCase().includes(value.toLowerCase()) ||
        item.contaBancaria.includes(value) ||
        item.valor.includes(value.replaceAll(",", "."))
      );
    });
    setDataGuiaReceitaFiltered(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = dataGuiaReceitaFormatted.slice(startIndex, endIndex);
  const currentFilteredItens = dataGuiaReceitaFiltered.slice(
    startIndex,
    endIndex
  );

  const pages = Math.ceil(dataGuiaReceitaFormatted.length / itensPorPagina);
  const filteredPages = Math.ceil(
    dataGuiaReceitaFiltered.length / itensPorPagina
  );

  const handleGeneratePdf = (receitaId) => {
    console.log(receitaId);
    toast.info("Relatório não implementado");
  };

  const handleNavigatePageAnulacao = (guiaId, saldoGuia) => {
    if (!saldoGuia) {
      toast.info("Esta Guia não possui saldo");
      return;
    }
    navigate(`/contabilidade/receita/anulacao-de-receita/cadastro/${guiaId}`);
  };

  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }
  const handleDeleteGuiaReceita =(id)=>{
    openModal();
    setDataDelete({ id });
  }

  const deleteGuiaReceita = async () => {
    console.log("delete");
    try {
      await api.delete(`guiaDeReceita/delete/${dataDelete.id}`);
      const filterDataGuiaReceita = dataGuiaReceitaFormatted.filter(guia => guia.id !== dataDelete.id)
      setDataGuiaReceitaFormatted(filterDataGuiaReceita)
      toast.success("Registro deletado com sucesso.");

    } catch (error) {
      console.log(error);
      toast.error("Algo deu errado ao deletar o registro.");
    }
  };

  useEffect(() => {
    if (dataGuiaReceita) {
      console.log(dataGuiaReceita);
      const guiaReceitaFormatted = dataGuiaReceita.map((item) => {
        const date = item.dataCriacao.split("T")[0];
        const [year, month, day] = date.split("-");
        const dateFormatted = `${day}/${month}/${year}`;

        return {
          numero: item.numero,
          id: item.id,
          natureza: `${item.naturezaInfo.nr} - ${item.naturezaInfo.especificacao}`,
          tipoLancamento: item.tipoDeLancamento,
          date: dateFormatted,
          devedor: `${item.devedorInfo.nomeCompleto}`,
          contaBancaria: `${item.contaBancariaInfo.numeroConta}-${item.contaBancariaInfo.digito} - ${item.contaBancariaInfo.nome}`,
          valor: item.valor,
          saldo:item.saldo,
          isDeleteGuia:item.delete === 'true' ? true:false
        };
      });
      console.log(guiaReceitaFormatted);
      
      setDataGuiaReceitaFormatted(guiaReceitaFormatted);
    }
  }, [dataGuiaReceita]);

  console.log(currentItens);

  const renderInfosTable = (infosTable) => {
    return (
      <tr key={infosTable.id}>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {infosTable.numero}
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={infosTable.natureza}
        >
          {infosTable.natureza.length > 60
            ? `${infosTable.natureza.substring(0, 60)}...`
            : `${infosTable.natureza}`}
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={
            optionsTipoLancamento.find(
              (tipoLancamento) =>
                tipoLancamento.value === infosTable.tipoLancamento
            )?.label
          }
        >
          {infosTable.tipoLancamento}
        </td>

        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {infosTable.date}
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={infosTable.devedor}
        >
          {infosTable.devedor}
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={infosTable.contaBancaria}
        >
          {infosTable.contaBancaria}
        </td>

        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {`R$ ${FormatValueToLocaleString(parseFloat(infosTable.valor))}`}
        </td>
        <td>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              flexShrink: "0",
              gap: "2px",
            }}
          >
            <IconEdit
              height={22}
              onClick={() =>
                navigate(
                  `/contabilidade/receita/guia-de-receita/visualizar/${infosTable.id}`
                )
              }
            />
            <Icon
              width={20}
              icon="fa-solid:print"
              onClick={() => {
                handleGeneratePdf(infosTable.id);
              }}
            />
            <Icon
              icon="gg:copy"
              color="#3E347B"
              height="22"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(
                  `/contabilidade/receita/guia-de-receita/replicar/${infosTable.id}`
                )
              }
            />
            <Icon
              icon="fluent:document-dismiss-16-regular"
              height="22"
              style={{ color: "#f00", cursor: "pointer" }}
              onClick={() =>
                handleNavigatePageAnulacao(infosTable.id, infosTable.saldo)
              }
            />
            <IconDelete height={22} disabled={!infosTable.isDeleteGuia} onClick={() =>handleDeleteGuiaReceita(infosTable.id)}/>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Guia de Receita"}
        PaginaSubtitulo={"Consulta"}
        BotaoSaveAtivo={false}
        RowTitle={"Guia de Receita Orçamentária - GRO"}
        BotaoNovoFunction={() =>
          navigate("/contabilidade/receita/guia-de-receita/cadastro")
        }
      />
       <ModalDeleteData
         modalOpened={modalOpened}
         closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={deleteGuiaReceita}
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        {currentItens.length > 0 && (
          <Table responsive style={{ marginTop: "-10px" }}>
            <thead>
              <tr>
                <TituloTable>Número</TituloTable>
                <TituloTable>Natureza da Receita</TituloTable>
                <TituloTable>Tipo de Lançamento</TituloTable>
                <TituloTable>Data</TituloTable>
                <TituloTable>Devedor</TituloTable>
                <TituloTable>Conta Bancária</TituloTable>
                <TituloTable>Valor</TituloTable>
                <TituloTableAction>Ação</TituloTableAction>
              </tr>
            </thead>

            <BodyTable>
              {searchTerm
                ? currentFilteredItens.map((item) => {
                    if (!item.id) return null;

                    return renderInfosTable(item);
                  })
                : currentItens.map((item) => {
                    if (!item.id) return null;

                    return renderInfosTable(item);
                  })}
            </BodyTable>
          </Table>
        )}
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm
              ? dataGuiaReceitaFiltered.length
              : dataGuiaReceitaFormatted
              ? dataGuiaReceitaFormatted.length
              : 0}{" "}
            registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaGuiaReceita;
