import React, { useContext, useEffect, useState } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowForm,
} from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import { TestButton } from "../../../../utils/TestButton";
import IconsEditInfo from "../../../../components/ListaDeInputs/IconsEditInfo";
import api from "../../../../utils/api";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import { toast } from "react-toastify";

const AbaPagamento = ({ values, setValues }) => {
  const { currentYear } = useContext(CurrentYearContext);
  const { currentClient } = useContext(CurrentClientContext);
  const [inputDocumento, setInputDocumento] = useState("");
  const [pagamento, setPagamento] = useState({
    tipoDocumento: "Liquidação",
    documento: "",
    parcela: "",
    valor: "",
  });
  const [liquidacoesOptions, setLiquidacoesOptions] = useState([]);
  const [gdeDespesaOptions, setGdeDespesaOptions] = useState([]);

  const fetchLiquidacoes = async () => {
    try {
      const response = await api.get(
        `/liquidacaoDeEmpenho/cliente/${currentClient.clienteId}`
      );
      setLiquidacoesOptions(response.data);
      console.log("Liquidação: ", response.data);
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message ||
          "Não foi possível carregar as liquidações de empenho"
      );
    }
  };

  const fetchGde = async () => {
    try {
      const response = await api.get(
        `gde/cliente/${currentClient.clienteId}/${currentYear}`
      );
      setGdeDespesaOptions(response.data);
      console.log("GDE:", response.data);
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message ||
          "Não foi possível carregar as despesas GDE"
      );
    }
  };

  const handleAddPagamento = () => {
    setValues((prevValues) => ({
      ...prevValues,
      pagamentos: [pagamento, ...prevValues.pagamentos],
    }));
    clearValues();
  };

  const clearValues = () => {
    setPagamento((prevValues) => ({
      ...prevValues,
      documento: "",
      parcela: "",
      valor: "",
    }));
    setInputDocumento("");
  };

  useEffect(() => {
    fetchLiquidacoes();
    fetchGde();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    clearValues();
  }, [pagamento?.tipoDocumento]);

  return (
    <>
      <TestButton onClick={() => console.log(pagamento)}>Pagamentos</TestButton>
      <RowForm className="mt-1" $borderRadiusType={2}>
        <RowAninhada>
          <CCol md={2}>
            <PTitulosInputs>Tipo de Documento</PTitulosInputs>
            <FloatingLabelInput
              tipo="input_select"
              new
              height="30px"
              value={pagamento?.tipoDocumento}
              options={[
                { label: "Tipo de Documento", value: null },
                { label: "Liquidação", value: "Liquidação" },
                { label: "GDE Despesa", value: "GDE Despesa" },
              ]}
              onSelect={(option) => {
                console.log(option);
                setPagamento((prevValues) => ({
                  ...prevValues,
                  tipoDocumento: option.value,
                }));
              }}
              radiusborder={
                values?.pagamentos?.length === 0 ? "5px" : "5px 5px 0 0"
              }
            />
          </CCol>
          <CCol>
            <PTitulosInputs>Documento</PTitulosInputs>
            <MyAutoComplete
              options={
                pagamento?.tipoDocumento === "Liquidação"
                  ? liquidacoesOptions
                  : pagamento?.tipoDocumento === "GDE Despesa"
                  ? gdeDespesaOptions
                  : []
              }
              labelFormat={(item) =>
                pagamento?.tipoDocumento === "Liquidação"
                  ? `${item?.empenho?.numero} ${item?.liquidacao} - ${item?.empenho?.fornecedorInfo?.dados?.nomeCompleto}`
                  : pagamento?.tipoDocumento === "GDE Despesa"
                  ? `${item?.numero} - ${item?.beneficiarioInfo?.nomeCompleto}`
                  : null
              }
              inputValue={inputDocumento}
              setInputValue={setInputDocumento}
              onOptionSelect={(option) => {
                setPagamento((prevValues) => ({
                  ...prevValues,
                  documento: option,
                }));
              }}
              radiusborder={
                values?.pagamentos?.length === 0 ? "5px" : "5px 5px 0 0"
              }
            />
          </CCol>
          <CCol md={1}>
            <PTitulosInputs>Parcela</PTitulosInputs>
            <FormControlListaDeInputs
              value={pagamento?.parcela}
              onChange={(e) => {
                const formatedValue = e.target.value.replace(/\D/g, "");
                setPagamento((prevValues) => ({
                  ...prevValues,
                  parcela: formatedValue,
                }));
              }}
              radiusborder={
                values?.pagamentos?.length === 0 ? "5px" : "5px 5px 0 0"
              }
            />
          </CCol>
          <CCol md={3}>
            <PTitulosInputs>Valor</PTitulosInputs>
            <InputFormatRealBrasileiro
              listaDeInputs
              onChange={(_, valor) => {
                // Converte o valor digitado para número
                let valorNumerico = parseFloat(
                  valor.replace(/\./g, "").replace(",", ".")
                );

                // Converte o valor novamente para o formato de string (com a vírgula)
                const valorCorrigido = valorNumerico
                  .toFixed(2)
                  .replace(".", ",");

                // Atualiza o estado com o valor corrigido
                setPagamento((prevValues) => ({
                  ...prevValues,
                  valor: valorCorrigido,
                }));
              }}
              value={pagamento?.valor}
              radiusborder={
                values?.pagamentos?.length === 0 ? "5px" : "5px 5px 0 0"
              }
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <IconAdicionar onClick={() => handleAddPagamento()} />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
        {values?.pagamentos?.map((item, index) => (
          <RowAninhada key={index}>
            <CCol md={2}>
              <FormControlListaDeInputs
                disabled
                height="30px"
                value={item?.tipoDocumento}
                radiusborder={
                  values?.pagamentos?.length === 1
                    ? "0 0 5px 5px"
                    : index === 0
                    ? "0"
                    : "0 0 5px 5px"
                }
              />
            </CCol>
            <CCol>
              <FormControlListaDeInputs
                disabled
                height="30px"
                value={
                  item?.tipoDocumento === "Liquidação"
                    ? `${item?.documento?.empenho?.numero} ${item?.documento?.liquidacao} - ${item?.documento?.empenho?.fornecedorInfo?.dados?.nomeCompleto}`
                    : `null`
                }
                radiusborder={
                  values?.pagamentos?.length === 1
                    ? "0 0 5px 5px"
                    : index === 0
                    ? "0"
                    : "0 0 5px 5px"
                }
              />
            </CCol>
            <CCol md={1}>
              <FormControlListaDeInputs
                disabled
                height="30px"
                value={item?.parcela}
                radiusborder={
                  values?.pagamentos?.length === 1
                    ? "0 0 5px 5px"
                    : index === 0
                    ? "0"
                    : "0 0 5px 5px"
                }
              />
            </CCol>
            <CCol md={3}>
              <InputFormatRealBrasileiro
                listaDeInputs
                disabled
                value={item?.valor}
                radiusborder={
                  values?.pagamentos?.length === 1
                    ? "0 0 5px 5px"
                    : index === 0
                    ? "0"
                    : "0 0 5px 5px"
                }
              />
            </CCol>
            <CColButtonsAcoes2Icons md={1}>
              <IconsEditInfo
                iconSaveShow={false}
                iconEditShow={false}
                iconCancelShow={false}
              />
            </CColButtonsAcoes2Icons>
          </RowAninhada>
        ))}

        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol md={1}></CCol>
          <CCol></CCol>
          <CCol
            md={2}
            style={{
              fontWeight: "500",
              alignItems: "center",
              display: "flex",
            }}
            className={"cor_letra_primaria"}
          >
            Valor Total
          </CCol>
          <CCol md={3}>
            <FormControlListaDeInputs
              style={{
                borderRadius: "10px",
                padding: "15px",
                border: "none",
                textAlign: "end",
              }}
              value={values?.pagamentos
                .reduce((acc, item) => {
                  const valor = item.valor.includes(",")
                    ? parseFloat(
                        item.valor.replace(/\./g, "").replace(",", ".")
                      )
                    : parseFloat(item.valor);
                  return acc + valor;
                }, 0)
                .toFixed(2)
                .replace(".", ",")}
              disabled
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
        </RowAninhada>
      </RowForm>
    </>
  );
};

export default AbaPagamento;
