import { Table } from "react-bootstrap";
import {
  BodyTable,
  TituloTable,
} from "../../../components/TableConsulta/TableConsulta";
import { CCol, RowForm, RowToModal } from "../../../components/Grid/CCol";

export const ProcedimentoContabel = () => {
  return (
    <>
      <RowToModal
        className="row_form mt-3"
        style={{
          borderRadius: "5px 5px 0px 0px",
          padding: "8px 10px",
        }}
      >
        <CCol >
          <span
            style={{ fontSize: "16px", color: "#515c70", fontWeight: "700" }}
          >
            Procedimentos Contábeis
          </span>
        </CCol>
      </RowToModal>
      <RowForm style={{ marginTop: "4px" }}>
        <Table responsive>
          <thead>
            <tr>
              <TituloTable style={{ borderStyle: "none" }}>Título</TituloTable>
              <TituloTable style={{ borderStyle: "none" }}>
                Categoria
              </TituloTable>
              <TituloTable style={{ borderStyle: "none" }}>
                Natureza Saldo
              </TituloTable>
            </tr>
          </thead>
          <BodyTable
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              borderStyle: "none",
              borderColor: "transparent",
              borderBottomWidth: 0,
            }}
          >
            <tr style={{ backgroundColor: "#EBEFFF" }}>
              <td
                style={{
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderBottomLeftRadius: "8px",
                  borderTopLeftRadius: "8px",
                }}
              >
                02051082442001203844901500
              </td>
              <td
                style={{
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                }}
              >
                D 1.1.1.1.1.00.00 Caixa e Equivalentes de Caixa em Moeda
                Nacional
              </td>
              <td
                style={{
                  whiteSpace: "nowrap",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderBottomRightRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                R$ 260.000,00
              </td>
            </tr>
          </BodyTable>
        </Table>
      </RowForm>
    </>
  );
};
