import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import InputSvg from "../../../../components/Grid/InputSvg";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { useFetchData } from "../../../../hooks/useFetchData";
import { Link } from "react-router-dom";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import { optionsTipoAnulacao } from "./optionsTipoAnulacao";

function ConsultaAnulacaoReceita() {
  const { currentYear } = useCurrentYear();
  const { currentClient } = useContext(CurrentClientContext);

  const { data: dataAnulacaoReceita, loading } = useFetchData(
    `anulacaoReceita/cliente/${currentClient.clienteId}/${currentYear}`
  );

  const [dataAnulacaoFormatted, setDataAnulacaoFormatted] = useState([
    {
      id: "",
      tipoAnulacao: "",
      data: "",
      numeroGuia: "",
      valorAnulacao: "",
      natureza: "",
    },
  ]);

  console.log(dataAnulacaoReceita);

  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [dataAnulacaoFiltered, setDataAnulacaoFiltered] = useState([
    {
      id: "",
      tipoAnulacao: "",
      data: "",
      numeroGuia: "",
      valorAnulacao: "",
      natureza: "",
    },
  ]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = dataAnulacaoFormatted.filter((item) => {
      return (
        item.tipoAnulacao.toString().includes(value) ||
        item.numeroGuia.toString().includes(value) ||
        item.natureza.includes(value)
      );
    });
    setDataAnulacaoFiltered(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = dataAnulacaoFormatted.slice(startIndex, endIndex);
  const currentFilteredItens = dataAnulacaoFiltered.slice(startIndex, endIndex);

  const pages = Math.ceil(dataAnulacaoFormatted.length / itensPorPagina);
  const filteredPages = Math.ceil(dataAnulacaoFiltered.length / itensPorPagina);

  useEffect(() => {
    console.log("entroy");

    if (dataAnulacaoReceita) {
      const formattedDataAnulacao = dataAnulacaoReceita
        .map((item) => {
          return item.guiasDeReceita.map((guia) => {
            const date = item.data.split("T")[0];
            const [year, month, day] = date.split("-");
            const dateFormatted = `${day}/${month}/${year}`;
            return {
              id: item.id,
              tipoAnulacao: item.tipoAnulacao,
              data: dateFormatted,
              numeroGuia: guia.guiaReceitaId,
              valorAnulacao: guia.valorAnulacao,
              natureza: `${guia.info.naturezaInfo.nr} - ${guia.info.naturezaInfo.especificacao}`,
            };
          });
        })
        .flat();
      console.log(formattedDataAnulacao);
      setDataAnulacaoFormatted(formattedDataAnulacao);
    }
  }, [dataAnulacaoReceita]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={true}
        TituloPagina={"Anulação de Receita"}
        SubTituloPagina={"Consulta"}
        ToPag={"/contabilidade/receita/anulacao-de-receita/cadastro"}
      />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        {currentItens.length > 0 && (
          <Table responsive style={{ marginTop: "-10px" }}>
            <thead>
              <tr>
                <TituloTable>Guia Anulada</TituloTable>
                <TituloTable>Receita Realizada</TituloTable>
                <TituloTable>Tipo de Anulação</TituloTable>
                <TituloTable>Data</TituloTable>
                <TituloTable>Valor</TituloTable>
                <TituloTableAction>Ação</TituloTableAction>
              </tr>
            </thead>

            <BodyTable>
              {searchTerm
                ? currentFilteredItens.map((item, index) => {
                    if (!item.id) return null;
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            verticalAlign: "middle",
                          }}
                        >
                          {item.numeroGuia}
                        </td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            verticalAlign: "middle",
                          }}
                          title={item.natureza}
                        >
                          {item.natureza.length > 70
                            ? `${item.natureza.substring(0, 70)}...`
                            : `${item.natureza}`}
                        </td>

                        <td
                          style={{
                            whiteSpace: "nowrap",
                            verticalAlign: "middle",
                          }}
                          title={
                            optionsTipoAnulacao.find(
                              (option) => option.type === item.tipoAnulacao
                            )?.label
                          }
                        >
                          {item.tipoAnulacao}
                        </td>

                        <td
                          style={{
                            whiteSpace: "nowrap",
                            verticalAlign: "middle",
                          }}
                          title={item.data}
                        >
                          {item.data}
                        </td>

                        <td
                          style={{
                            whiteSpace: "nowrap",
                            verticalAlign: "middle",
                          }}
                        >
                          {`R$ ${FormatValueToLocaleString(
                            parseFloat(item.valorAnulacao)
                          )}`}
                        </td>
                        <td>
                          <Link
                            title={"editar"}
                            to={`/contabilidade/receita/anulacao-de-receita/visualizar/${item.id}`}
                          >
                            <Icon icon="bx:edit" color="#5971C8" height="22" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                : currentItens.map((item, index) => {
                    if (!item.id) return null;
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            verticalAlign: "middle",
                          }}
                        >
                          {item.numeroGuia}
                        </td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            verticalAlign: "middle",
                          }}
                          title={item.natureza}
                        >
                          {item.natureza.length > 70
                            ? `${item.natureza.substring(0, 70)}...`
                            : `${item.natureza}`}
                        </td>

                        <td
                          style={{
                            whiteSpace: "nowrap",
                            verticalAlign: "middle",
                          }}
                          title={
                            optionsTipoAnulacao.find(
                              (option) => option.type === item.tipoAnulacao
                            )?.label
                          }
                        >
                          {item.tipoAnulacao}
                        </td>

                        <td
                          style={{
                            whiteSpace: "nowrap",
                            verticalAlign: "middle",
                          }}
                          title={item.data}
                        >
                          {item.data}
                        </td>

                        <td
                          style={{
                            whiteSpace: "nowrap",
                            verticalAlign: "middle",
                          }}
                        >
                          {`R$ ${FormatValueToLocaleString(
                            parseFloat(item.valorAnulacao)
                          )}`}
                        </td>
                        <td>
                          <Link
                            title={"editar"}
                            to={`/contabilidade/receita/anulacao-de-receita/visualizar/${item.id}`}
                          >
                            <Icon icon="bx:edit" color="#5971C8" height="22" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
            </BodyTable>
          </Table>
        )}
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm
              ? dataAnulacaoFiltered.length
              : dataAnulacaoFormatted
              ? dataAnulacaoFormatted.length
              : 0}{" "}
            registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaAnulacaoReceita;
