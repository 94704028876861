import React, { useContext, useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import styled from "styled-components";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import { HistoricoAba } from "./HistoricoAba";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { toast, ToastContainer } from "react-toastify";
import api from "../../../../utils/api";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const optionsIndicadorLancamento = [
  {
    label: "GRE",
    value: "GRE",
    path: "gre",
  },
  { label: "GDE", value: "GDE", path: "gde" },
];

const AnulacaoExtra = () => {
  const { currentYear } = useCurrentYear();
  const { currentClient } = useContext(CurrentClientContext);

  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });

  const [inputGuiaAnulada, setInputGuiaAnulada] = useState("");
  const [inputFonteValue, setInputFonteValue] = useState("");

  const [addInfosFonte, setAddInfosFonte] = useState({
    fonteId: "",
    valor: "",
    conta: "",
    titulo: "",
    label: "",
  });

  const [infosCadastro, setInfosCadastro] = useState({
    indicador: "",
    data: "",
    guiaAnuladaId: "",
    valor: "",
    historico: "",
    fontes: [
      {
        fonteId: "",
        valor: "",
        conta: "",
        titulo: "",
        label: "",
      },
    ],
  });

  const [optionsGre, setOptionsGre] = useState([
    {
      id: "",
      numero: "",
      qdrde: "",
      valor: "",
      label: "",
      fontes: [
        {
          fonteId: "",
          valor: "",
          conta: "",
          titulo: "",
          label: "",
        },
      ],
    },
  ]);

  const [optionsGde, setOptionsGde] = useState([
    {
      id: "",
      numero: "",
      qdrde: "",
      valor: "",
      label: "",
      fontes: [
        {
          fonteId: "",
          valor: "",
          conta: "",
          titulo: "",
          label: "",
        },
      ],
    },
  ]);

  const [optionsFonte, setOptionsFonte] = useState([
    {
      fonteId: "",
      valor: "",
      conta: "",
      titulo: "",
      label: "",
    },
  ]);

  const handleSelectIndicador = async (option) => {
    console.log(option);

    // setInputIndicador(option)
    // return
    // setInfosCadastro((prev) => ({
    //   ...prev,
    //   indicador: option.label,
    // }));

    // setAddInfosFonte({
    //   fonteId: "",
    //   valor: "0,00",
    //   conta: "",
    //   titulo: "",
    //   label: "",
    // });
    // setInputFonteValue('')

    const hasInfoGuiaAnulada = infosCadastro.guiaAnuladaId;
    resetFieldsOnchangeIndicador(option, hasInfoGuiaAnulada);
    if (hasInfoGuiaAnulada) {
      // setInfosCadastro((prev) => ({
      //   ...prev,
      //   guiaAnuladaId: "",
      //   valor: "0,00",
      //   fontes: [
      //     {
      //       fonteId: "",
      //       valor: "",
      //       conta: "",
      //       titulo: "",
      //       label: "",
      //     },
      //   ],
      // }));
      // setInputGuiaAnulada("");
      // setOptionsFonte([
      //   {
      //     fonteId: "",
      //     valor: "",
      //     conta: "",
      //     titulo: "",
      //     label: "",
      //   },
      // ]);
      // setAddInfosFonte({
      //   fonteId: "",
      //   valor: "0,00",
      //   conta: "",
      //   titulo: "",
      //   label: "",
      // });
    }

    const hasOptionsGre = optionsGre.filter((gre) => gre.id).length;

    if (hasOptionsGre && option.path === "gre") return;

    const hasOptionsGde = optionsGde.filter((gde) => gde.id).length;

    if (hasOptionsGde && option.path === "gde") return;

    await fetchDataIndicadorSelected(option.path);
  };

  const resetFieldsOnchangeIndicador = (
    optionIndicador,
    hasInfoGuiaAnulada
  ) => {
    if (hasInfoGuiaAnulada) {
      setInfosCadastro((prev) => ({
        ...prev,
        indicador: optionIndicador.label,
        guiaAnuladaId: "",
        valor: "0,00",
        fontes: [
          {
            fonteId: "",
            valor: "",
            conta: "",
            titulo: "",
            label: "",
          },
        ],
      }));
      setInputGuiaAnulada("");
      setOptionsFonte([
        {
          fonteId: "",
          valor: "",
          conta: "",
          titulo: "",
          label: "",
        },
      ]);
      setAddInfosFonte({
        fonteId: "",
        valor: "0,00",
        conta: "",
        titulo: "",
        label: "",
      });
      setInputFonteValue("");
      return;
    }

    setInfosCadastro((prev) => ({
      ...prev,
      indicador: optionIndicador.label,
    }));

    setAddInfosFonte({
      fonteId: "",
      valor: "0,00",
      conta: "",
      titulo: "",
      label: "",
    });
    setInputFonteValue("");
  };

  const handleSelectGuia = (optionGuia) => {
    console.log(optionGuia);

    setInfosCadastro((prev) => ({
      ...prev,
      guiaAnuladaId: optionGuia.id,
      valor: optionGuia.valor,
      fontes: [
        {
          fonteId: "",
          valor: "",
          conta: "",
          titulo: "",
          label: "",
        },
      ],
    }));
    setOptionsFonte(optionGuia.fontes);
  };

  const handleChangeValor = (_, valor) => {
    const valorFloat = parseFloat(valor.replace(",", "."));

    setInfosCadastro((prevState) => ({
      ...prevState,
      valor: valorFloat,
    }));
  };

  const handleClearGuiaAnulada = () => {
    setInfosCadastro((prev) => ({
      ...prev,
      guiaAnuladaId: "",
      valor: "0,00",
      fontes: [
        {
          fonteId: "",
          valor: "",
          conta: "",
          titulo: "",
          label: "",
        },
      ],
    }));
    setOptionsFonte([
      {
        fonteId: "",
        valor: "",
        conta: "",
        titulo: "",
        label: "",
      },
    ]);
    setInputFonteValue("");
    setAddInfosFonte({
      fonteId: "",
      valor: "",
      conta: "",
      titulo: "",
      label: "",
    });
  };

  const fetchDataIndicadorSelected = async (path) => {
    try {
      const resp = await api.get(
        `${path}/cliente/${currentClient.clienteId}/${currentYear}`
      );
      console.log(resp.data);
      const formattedData = {
        gre: formattedDataGre,
        gde: formattedDataGde,
      };
      if (formattedData[path]) {
        formattedData[path](resp.data);
        return;
      }
      throw new Error();
    } catch (error) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde");
      console.log(error);
    }
  };

  const formattedDataGre = (dataGre) => {
    console.log(dataGre);

    const formattedDataGreReceita = dataGre
      .map((item) => {
        const infos = item.gre.qdredeInfo.map((info) => {
          // const numero = item.gre.numero.replace(/^0+/, "");
          return {
            id: item.gre.id,
            numero:item.gre.numero,
            qdrde: info.titulo,
            valor: item.gre.valor,
            label: `${item.gre.numero} - ${info.titulo}`,
            fontes: formattedFontes(item.gre.fontes),
          };
        });
        return infos;
      })
      .flat();
    setOptionsGre(formattedDataGreReceita);
    console.log(formattedDataGreReceita);
  };

  const formattedDataGde = (dataGde) => {
    console.log(dataGde);

    const formattedDataGdeDespesa = dataGde
      .map((gde) => {
        const numero = gde.numero.replace(/^0+/, "");
          return {
            id: gde.id,
            numero,
            qdrde: gde.qdrdeInfo.titulo,
            valor: gde.valor,
            label: `${numero} - ${gde.qdrdeInfo.titulo}`,
            fontes: formattedFontes(gde.qdrdeInfo.fontes)
          };
      })
      .flat();
    setOptionsGde(formattedDataGdeDespesa);
    console.log(formattedDataGdeDespesa);
  };

  const formattedFontes = (fontes) => {
    const fontesRecurso = fontes
      .map((fonte) => {
        return fonte.fonteInfo.map((fonteInfo) => {
          return {
            fonteId: fonte.fonteId,
            valor: fonte.valor,
            conta: fonteInfo.conta,
            titulo: fonteInfo.titulo,
            label: `${fonteInfo.conta} - ${fonteInfo.titulo}`,
          };
        });
      })
      .flat();
    console.log(fontesRecurso);
    return fontesRecurso;
  };

  const handleRegister = () => {
    console.log(infosCadastro);
    toast.info("Cadastro não implementado");
  };
  // console.log(inputIndicador);

  console.log(infosCadastro);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Anulação Extra"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle={"Anulações Extraorçamentária"}
        BotaoNovoAtivo={false}
        ButtonSaveFunction={handleRegister}
      />
      <ToastContainer />

      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput disabled label="Número" placeholder="Número" />
        </CColNumero>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            placeholder="Indicador de Lançamento"
            options={optionsIndicadorLancamento}
            value={infosCadastro.indicador}
            onSelect={(option) => handleSelectIndicador(option)}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            onChange={(e) =>
              setInfosCadastro((prev) => ({
                ...prev,
                data: e.target.value,
              }))
            }
            externalValue={infosCadastro.data}
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            options={
              infosCadastro.indicador.toLowerCase() === "gre"
                ? optionsGre
                : optionsGde
            }
            labelFormat={(option) => option.label}
            labelInput="Guia Anulada"
            style={{ height: "37px" }}
            inputValue={inputGuiaAnulada}
            setInputValue={setInputGuiaAnulada}
            onOptionSelect={handleSelectGuia}
            iconClearFunction={handleClearGuiaAnulada}
          />
        </CCol>
        <CCol md={2}>
          <InputFormatRealBrasileiro
            iconReal
            onChange={handleChangeValor}
            externalValue={infosCadastro.valor}
          />
        </CCol>
      </RowForm>
      <RowSelecaoAbasInferior
        abas={["Histórico", "Fonte de Recursos"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && (
        <HistoricoAba
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
        />
      )}
      {abaSelecionada.nomeAba === "Fonte de Recursos" && (
        <FonteDeRecursosAba
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
          optionsFonte={optionsFonte}
          inputFonteValue={inputFonteValue}
          setInputFonteValue={setInputFonteValue}
          addInfosFonte={addInfosFonte}
          setAddInfosFonte={setAddInfosFonte}
        />
      )}
    </Container>
  );
};

export default AnulacaoExtra;
