import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import { Icon } from "@iconify/react";
import LoadingComponent from "../AssetsComponents.jsx/LoadingComponent";

const StyledInputAutocomplete = styled.input`
  transition: all 0.2s ease-out;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: ${({ radiusborder }) => (radiusborder ? radiusborder : "5px")};
  padding: 2.1px 0px;
  padding-left: 10px;
  padding-right: 35px;
  background: transparent;

  &::placeholder {
    transition: all 0.2s;
    opacity: ${({ labelActive }) => (labelActive ? "1" : "0")};
    color: #b5b5b5;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #e9ecef;
  }
`;

const ContainerAutoComplete = styled.div`
  position: relative;
`;

const ContainerOptions = styled.div`
  width: 100%;
  position: absolute;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 3px 5px #bebebe;
  padding: 10px 0px;
  max-height: 278px;
  overflow-y: auto;
  z-index: 99;

  & p {
    padding: 7px 16px;
    margin: 0;
    cursor: pointer;
  }

  & p:hover,
  & p.selected {
    background-color: #e7e7e7;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const LabelStyled = styled.p`
  pointer-events: none;
  transition: all 0.2s ease-out;
  position: absolute;
  top: 50%;
  margin-left: 11px;
  transform: ${({ active }) =>
    active ? "translate(2px,-152%)" : "translateY(-50%)"};
  background: ${({ disabled }) =>
    disabled
      ? "transparent"
      : "linear-gradient(to bottom,transparent 0,transparent calc(50% - 0.5px),white 50%,white calc(50% + 0.5px), transparent 100%  )"};
  background-size: 100% 4px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  ${({ active }) => active && "font-size: 0.75rem"};
  padding: 0px 3px;
  color: #515c70;
  font-weight: 500;
`;

const Pill = styled.div`
  background-color: #d8d8d8;
  margin-left: 5px;
  padding: 5px;
  color: #6a6a6a;
  font-weight: 500;
  font-size: 13px;
  border-radius: 5px;
`;

const RightLabelTextContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const AutoCompleteFichas = React.forwardRef(
  (
    {
      options,
      labelFormat,
      onOptionSelect,
      radiusborder,
      addNewDataFunction,
      inputValue = "",
      setInputValue,
      iconClearFunction,
      labelInput,
      onKeyDown,
      onKeyDownFunction,
      loadingOptions,
      rightLabelContent,
      filterFunction,
      iconsOff,
      ...propsRestantes
    },
    ref
  ) => {
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [optionsWithLabels, setOptionsWithLabels] = useState([]);
    const [inputFocused, setInputFocused] = useState(false);
    const [labelActive, setLabelActive] = useState(false);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
    const [optionChangedByMouse, setOptionChangedByMouse] = useState(false);
    const [selectedOptionPillValue, setSelectedOptionPillValue] = useState();

    //Ref
    const containerAutoCompleteRef = useRef();
    const defaultRef = useRef();

    const handleInputChange = (e) => {
      setInputValue(e.target.value);

      if (propsRestantes.onChange) {
        propsRestantes.onChange(e);
      }
    };

    const handleAddButtonFunction = useCallback(() => {
      if (addNewDataFunction) {
        addNewDataFunction();
      }
    }, [addNewDataFunction]);

    const handleOptionLabel = () => {
      const arrayDeLabels = options.map(labelFormat);

      const arrayOptionsWithLabel = options.map((item, index) => ({
        label: arrayDeLabels[index],
        ...item,
      }));

      setOptionsWithLabels(arrayOptionsWithLabel);
    };

    const handleIconClear = () => {
      setInputValue("");
      setSelectedOptionPillValue();

      if (iconClearFunction) {
        iconClearFunction();
      }
    };

    const handleOptionSelect = (item) => {
      setInputValue(item.label);
      setSelectedOptionIndex(0);
      setInputFocused(false);
      setSelectedOptionPillValue(item.pill);

      if (onOptionSelect) {
        onOptionSelect(item);
      }
    };

    const filterOptions = () => {
      if (!filterFunction) {
        // console.log("não tem filter function");
        const filtragemGeral = optionsWithLabels
          .filter(
            (item) =>
              item.label.toLowerCase().includes(inputValue.toLowerCase()) ||
              item.label
                .toLowerCase()
                .replace(/\./g, "")
                .includes(inputValue.toLowerCase())
          )
          .sort((a, b) => {
            const cleanedInputValue = inputValue
              .toLowerCase()
              .replace(/\./g, "");
            const cleanedA = a.label.toLowerCase().replace(/\./g, "");
            const cleanedB = b.label.toLowerCase().replace(/\./g, "");

            const startsWithA = cleanedA.startsWith(cleanedInputValue);
            const startsWithB = cleanedB.startsWith(cleanedInputValue);

            if (startsWithA && !startsWithB) return -1;
            if (!startsWithA && startsWithB) return 1;

            return 0;
          });

        // console.log(filtragemGeral);

        const optionsSemFichas = filtragemGeral.filter(
          (item) => item.ficha === undefined
        );

        const fichaUnica = optionsWithLabels.find(
          (item) => item.ficha?.ficha.toString() === inputValue
        );

        if (fichaUnica) {
          setFilteredOptions([fichaUnica, ...optionsSemFichas]);
        } else {
          setFilteredOptions([...optionsSemFichas]);
        }
      } else {
        const dataArray = filterFunction(optionsWithLabels);

        setFilteredOptions(dataArray);
      }
    };

    useEffect(() => {
      if (optionsWithLabels.length > 0) {
        filterOptions();
      }
      //eslint-disable-next-line
    }, [inputValue, optionsWithLabels]);

    useEffect(() => {
      if (inputValue.length > 0 || inputFocused) {
        setLabelActive(true);
      } else {
        setLabelActive(false);
      }
    }, [inputValue, inputFocused]);

    useEffect(() => {
      if (labelFormat || options.length > 0) {
        handleOptionLabel();
      }

      if (options.length === 0) {
        setFilteredOptions([]);
      }
      //eslint-disable-next-line
    }, [labelFormat, options]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          containerAutoCompleteRef.current &&
          !containerAutoCompleteRef.current.contains(event.target)
        ) {
          handleClickForaDaDiv();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };

      //eslint-disable-next-line
    }, []);

    const handleClickForaDaDiv = () => {
      setInputFocused(false);
    };

    const handleKeyDownInternal = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (selectedOptionIndex !== -1) {
          handleOptionSelect(filteredOptions[selectedOptionIndex]);
        }
        setInputFocused(false);
      }
      if (onKeyDown) {
        onKeyDown(event);
      }
      if (onKeyDownFunction && !inputFocused) {
        onKeyDownFunction(event);
      }
    };

    useEffect(() => {
      const handleArrowKeys = (event) => {
        setOptionChangedByMouse(false);
        if (event.key === "ArrowDown") {
          setSelectedOptionIndex((prevIndex) =>
            prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex
          );
        } else if (event.key === "ArrowUp") {
          setSelectedOptionIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : prevIndex
          );
        }
      };

      document.addEventListener("keydown", handleArrowKeys);

      return () => {
        document.removeEventListener("keydown", handleArrowKeys);
      };
    }, [filteredOptions]);

    useEffect(() => {
      if (inputFocused && filteredOptions.length > 0) {
        setSelectedOptionIndex(0);
      }
    }, [inputFocused, filteredOptions]);

    useEffect(() => {
      if (inputFocused && selectedOptionIndex !== -1 && !optionChangedByMouse) {
        const optionElement = document.querySelector(
          `#option-${selectedOptionIndex}`
        );
        if (optionElement) {
          const container = document.querySelector("#options-container");
          const scrollOffset = optionElement.offsetTop - container.offsetTop;
          container.scrollTop = scrollOffset;
        }
      }
    }, [selectedOptionIndex, inputFocused, optionChangedByMouse]);

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (
          inputFocused &&
          event.altKey &&
          (event.key === "=" || event.key === "+")
        ) {
          handleAddButtonFunction();
          if (ref) {
            ref.current.blur();
          } else {
            defaultRef.current.blur();
          }
        }
      };

      if (inputFocused) {
        document.addEventListener("keydown", handleKeyDown);
      } else {
        document.removeEventListener("keydown", handleKeyDown);
      }

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [handleAddButtonFunction, inputFocused, ref]);

    const generateRightLabelText = (item) => {
      if (rightLabelContent) {
        if (typeof rightLabelContent === "function") {
          return rightLabelContent(item);
        } else if (
          typeof rightLabelContent === "string" ||
          typeof rightLabelContent === "number"
        ) {
          return rightLabelContent;
        }
      }
    };

    return (
      <ContainerAutoComplete ref={containerAutoCompleteRef}>
        <LabelStyled active={labelActive} disabled={propsRestantes.disabled}>
          {labelInput}
        </LabelStyled>
        {inputValue.length === 0 && (
          <SearchIcon
            sx={{
              top: "50%",
              transform: "translateY(-50%)",
              right: "7px",
              position: "absolute",
              color: "#515C70",
            }}
          />
        )}

        {inputValue.length > 0 &&
          filteredOptions.length === 0 &&
          addNewDataFunction &&
          !iconsOff && (
            <Icon
              onClick={handleAddButtonFunction}
              icon="ic:baseline-add-box"
              color="#105200"
              height="24"
              title={"Criar novo registro de cpf"}
              style={{
                position: "absolute",
                right: "32",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                marginLeft: "5px",
                zIndex: "1",
              }}
            />
          )}
        {inputValue.length > 0 && !iconsOff && (
          <Icon
            onClick={() => {
              handleIconClear();
            }}
            icon="mdi:cancel-bold"
            width={24}
            style={{
              color: "#0062ff",
              position: "absolute",
              right: "7px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              marginLeft: "5px",
              zIndex: "1",
            }}
          />
        )}
        {selectedOptionPillValue && (
          <Pill
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: "35px",
              zIndex: "1",
            }}
          >
            {selectedOptionPillValue}
          </Pill>
        )}

        <StyledInputAutocomplete
          ref={ref ? ref : defaultRef}
          labelActive={labelActive}
          value={inputValue}
          style={propsRestantes.style}
          radiusborder={radiusborder}
          type="text"
          onChange={handleInputChange}
          onFocus={() => setInputFocused(true)}
          onBlur={() => {
            if (!optionChangedByMouse) {
              setInputFocused(false);
            }
          }}
          disabled={propsRestantes.disabled}
          placeholder={propsRestantes.placeholder}
          onKeyDown={handleKeyDownInternal}
        />

        {inputFocused && (
          <>
            {loadingOptions && (
              <ContainerOptions id="options-container">
                <LoadingComponent size={25} />{" "}
              </ContainerOptions>
            )}
            {!loadingOptions && inputValue.length > 0 && (
              <ContainerOptions id="options-container">
                {filteredOptions.map((item, index) => (
                  <p
                    id={`option-${index}`}
                    key={index}
                    className={index === selectedOptionIndex ? "selected" : ""}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() => handleOptionSelect(item)}
                    onMouseMove={() => {
                      setSelectedOptionIndex(index);
                      setOptionChangedByMouse(true);
                    }}
                  >
                    {item.label}
                    <RightLabelTextContainer>
                      {generateRightLabelText(item)}
                      {item.pill && <Pill>{item.pill}</Pill>}
                    </RightLabelTextContainer>
                  </p>
                ))}
                {inputValue.length > 0 && filteredOptions.length === 0 && (
                  <div style={{ padding: "5px 12px" }}>
                    Não foi encontrado nenhum registro com esses caracteres.
                  </div>
                )}
              </ContainerOptions>
            )}
          </>
        )}
      </ContainerAutoComplete>
    );
  }
);

export default AutoCompleteFichas;
