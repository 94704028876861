export const selectUnidadesMedidasOptions = [
  { value: "Un - Unidade", label: "Un - Unidade" },
  { value: "% - Percentual", label: "% - Percentual" },
  { value: "KG - Quilograma", label: "KG - Quilograma" },
  { value: "R$ - Reais", label: "R$ - Reais" },
  { value: "L - Litros", label: "L - Litros" },
  { value: "m - Metros Linear", label: "m - Metros Linear" },
  { value: "KM - Quilômetros", label: "KM - Quilômetros" },
  { value: "T - Toneladas", label: "T - Toneladas" },
  { value: "m² - Metros Quadrados", label: "m² - Metros Quadrados" },
  { value: "h - Horas", label: "h - Horas" },
];
