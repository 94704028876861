import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import { ToastContainer } from "react-toastify";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { formatToCurrency } from "../../../../utils/FormatacaoDeDados/FormatToCurrency";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import { TestButton } from "../../../../utils/TestButton";
import { getCpfsCnpjsPorCliente } from "../../../../services/gets/getCpfsCnpjsPorCliente";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { useNavigate } from "react-router-dom";
import api from "../../../../utils/api";

function NotaDeEmpenhoConsulta() {
  const navigate = useNavigate();
  const { currentYear } = useContext(CurrentYearContext);
  const { currentClient } = useContext(CurrentClientContext);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [produtosAquisicoesFiltrados, setProdutosAquisicoesFiltrados] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [notaEmpenho, setNotaEmpenho] = useState([]);
  const [optionsFichas, setOptionsFichas] = useState([]);
  const [optionsCpf, setOptionsCpf] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/notaEmpenho/${currentClient.clienteId}/${currentYear}`
      );
      console.log(response.data);
      setNotaEmpenho(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = notaEmpenho.filter((item) => {
      console.log(item.numero.includes(value));
      return (
        item?.numero?.includes(value) ||
        item?.fornecedorInfo.dados?.nomeCompleto
          ?.toLowerCase()
          .includes(value.toLowerCase()) ||
        optionsFichas
          .find((obj) => obj.value === item.fichaId)
          ?.ficha?.acaoGovernamentalInfo?.nAcao.includes(value) ||
        optionsFichas
          .find((obj) => obj.value === item.fichaId)
          ?.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo.slice(
            0,
            -3
          )
          .includes(value) ||
        optionsFichas
          .find((obj) => obj.value === item.fichaId)
          ?.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo.slice(
            0,
            -3
          )
          .replace(/\./g, "")
          .includes(value) ||
        String(
          new Date(item.data).toLocaleDateString("pt-BR", {
            timeZone: "UTC",
          })
        ).includes(value) ||
        String(
          new Date(item.data).toLocaleDateString("pt-BR", {
            timeZone: "UTC",
          })
        )
          .replace(/\//g, "")
          .includes(value) ||
        formatToCurrency(item?.valor).includes(value) ||
        formatToCurrency(item?.valor).replace(/[.,]/g, "").includes(value)
      );
      // return (
      //   String(item?.numero).includes(value) ||
      //   item?.fornecedorInfo?.dados?.nomeCompleto
      //     ?.toLowerCase()
      //     .includes(value.toLowerCase()) ||
      //   item.codigoItemContaPcasp.includes(value) ||
      //   item.codigoItemContaPcasp.replace(/\./g, "").includes(value)
      // );
    });
    setProdutosAquisicoesFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = notaEmpenho.slice(startIndex, endIndex);
  const currentFilteredItens = produtosAquisicoesFiltrados.slice(
    startIndex,
    endIndex
  );

  const pages = Math.ceil(notaEmpenho.length / itensPorPagina);
  const filteredPages = Math.ceil(
    produtosAquisicoesFiltrados.length / itensPorPagina
  );

  const fetchQddFichas = async () => {
    try {
      const resp = await api.get(
        `/qdd/${currentClient.clienteId}/${currentYear}`
      );
      const dados = resp.data;

      const arrayDeFichas = dados.map((item) => {
        const fichaReferente = item.despesaFixadaQDD.fichasQDD;
        return {
          label: `Ficha ${fichaReferente.numeroFicha}`,
          value: fichaReferente.id,
          ficha: item,
        };
      });

      setOptionsFichas(arrayDeFichas);
      console.log(arrayDeFichas);
    } catch (error) {
      console.error("Erro ao buscar fichas:", error);
    }
  };

  const fetchCpfs = async (reqDeModal) => {
    const data = await getCpfsCnpjsPorCliente(currentClient.clienteId);

    if (data) {
      setOptionsCpf(
        data.map((item) => ({
          id: item.pessoaId,
          nome: item.nomeCompleto,
          cpf: FormatCpfPaste(item.cpf),
        }))
      );
    }
  };

  useEffect(() => {
    fetchQddFichas();
    fetchCpfs();
    // eslint-disable-next-line
  }, []);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <TestButton onClick={() => console.log("TESTE".toLowerCase())}>
        Estou testando aqui
      </TestButton>
      <button
        onClick={() =>
          navigate("/contabilidade/despesa/consulta-teste/nota-de-empenho")
        }
      >
        Ir para consulta teste
      </button>
      <HeaderOptions
        withRow={true}
        TituloPagina={"Nota de Empenho"}
        SubTituloPagina={"Consulta"}
        ToPag={"/contabilidade/despesa/nota-de-empenho"}
      />
      {/* <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={deleteObra}
      /> */}
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
            {/* <div style={{ marginLeft: "15px" }}>
              Não encontrou a obra que buscava?{" "}
              <button
                onClick={() => getTCEData()}
                style={{
                  all: "unset",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              >
                clique aqui.
              </button>
            </div> */}
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"Pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loading ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Número</TituloTable>
              <TituloTable>Fornecedor</TituloTable>
              <TituloTable>Ação</TituloTable>
              <TituloTable>Elemento da Despesa</TituloTable>
              <TituloTableAction>Data</TituloTableAction>
              <TituloTableAction>Valor</TituloTableAction>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }} title={item.numero}>
                      {item.numero}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={`${
                        optionsCpf.find(
                          (option) => option.id === parseInt(item.fornecedor)
                        )?.cpf
                      } - ${
                        optionsCpf.find(
                          (option) => option.id === parseInt(item.fornecedor)
                        )?.nome
                      }`}
                    >
                      {optionsCpf.find(
                        (option) => option.id === parseInt(item.fornecedor)
                      )?.nome || `Fornecedor ${item.fornecedor} não encontrado`}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={
                        optionsFichas.find((obj) => obj.value === item.fichaId)
                          ?.ficha.acaoGovernamentalInfo.titulo || item.fichaId
                      }
                    >
                      {optionsFichas.find((obj) => obj.value === item.fichaId)
                        ?.ficha.acaoGovernamentalInfo.nAcao || item.fichaId}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={
                        `${
                          optionsFichas.find(
                            (obj) => obj.value === item.fichaId
                          )?.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
                            .nome
                        } - ${
                          optionsFichas.find(
                            (obj) => obj.value === item.fichaId
                          )?.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
                            .funcao
                        }` || item.fichaId
                      }
                    >
                      {optionsFichas
                        .find((obj) => obj.value === item.fichaId)
                        ?.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo.slice(
                          0,
                          -3
                        ) || item.fichaId}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={new Date(item.data).toLocaleDateString("pt-BR", {
                        timeZone: "UTC",
                      })}
                    >
                      {new Date(item.data).toLocaleDateString("pt-BR", {
                        timeZone: "UTC",
                      })}
                    </td>

                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={formatToCurrency(item?.valor)}
                    >
                      {formatToCurrency(item?.valor)}
                    </td>
                    <td>
                      <ButtonsAcoesListas
                        FuncaoIconDelete={() => {
                          /*
                          openModal();
                          setDataDelete(item);
                        */ console.log("delete");
                        }}
                        CaminhoPagReplica={`/contabilidade/despesa/nota-de-empenho/${item.id}`}
                        CaminhoPagUpdate={`/contabilidade/despesa/nota-de-empenho/${item.id}`}
                      />
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }} title={item.numero}>
                      {item.numero}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={`${
                        optionsCpf.find(
                          (option) => option.id === parseInt(item.fornecedor)
                        )?.cpf
                      } - ${
                        optionsCpf.find(
                          (option) => option.id === parseInt(item.fornecedor)
                        )?.nome
                      }`}
                    >
                      {optionsCpf.find(
                        (option) => option.id === parseInt(item.fornecedor)
                      )?.nome || `Fornecedor ${item.fornecedor} não encontrado`}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={
                        optionsFichas.find((obj) => obj.value === item.fichaId)
                          ?.ficha.acaoGovernamentalInfo.titulo || item.fichaId
                      }
                    >
                      {optionsFichas.find((obj) => obj.value === item.fichaId)
                        ?.ficha.acaoGovernamentalInfo.nAcao ||
                        "Nº de Ação não Encontrado"}
                      {/* ESTOU AQUI */}
                    </td>

                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={
                        `${
                          optionsFichas.find(
                            (obj) => obj.value === item.fichaId
                          )?.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
                            .nome
                        } - ${
                          optionsFichas.find(
                            (obj) => obj.value === item.fichaId
                          )?.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
                            .funcao
                        }` || item.fichaId
                      }
                    >
                      {optionsFichas
                        .find((obj) => obj.value === item.fichaId)
                        ?.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo.slice(
                          0,
                          -3
                        ) || item.fichaId}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={new Date(item.data).toLocaleDateString("pt-BR", {
                        timeZone: "UTC",
                      })}
                    >
                      {new Date(item.data).toLocaleDateString("pt-BR", {
                        timeZone: "UTC",
                      })}
                    </td>

                    <td
                      style={{ whiteSpace: "nowrap" }}
                      title={formatToCurrency(item?.valor)}
                    >
                      {formatToCurrency(item?.valor)}
                    </td>
                    <td>
                      <ButtonsAcoesListas
                        FuncaoIconDelete={() => {
                          /*
                          openModal();
                          setDataDelete(item);
                        */ console.log("delete");
                        }}
                        CaminhoPagReplica={`/contabilidade/despesa/nota-de-empenho/${item.id}`}
                        CaminhoPagUpdate={`/contabilidade/despesa/nota-de-empenho/${item.id}`}
                        CaminhoPagAnulacao={`/contabilidade/despesa/nota-de-empenho/${item.id}`}
                      />
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm
              ? produtosAquisicoesFiltrados.length
              : notaEmpenho.length}{" "}
            registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default NotaDeEmpenhoConsulta;
