import React, {
  useCallback,
  // useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Container } from "react-bootstrap";

import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowForm,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";

import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";

import { Icon } from "@iconify/react/dist/iconify.js";

import { useFetchData } from "../../../../hooks/useFetchData";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import { MotivoAba } from "./MotivoAba";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";

import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";

import { toast, ToastContainer } from "react-toastify";
// import api from "../../../../utils/api";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import api from "../../../../utils/api";
import { useLocation, useParams } from "react-router-dom";

const optionsTipoAnulacao = [
  {
    label: "1 - Anulação - Remove o Registro Contábil",
    type: 1,
    value: "1 - Anulação - Remove o Registro Contábil",
  },
  {
    label: "2 - Restituição - Dedução da Receita Orçamentária",
    type: 2,
    value: "2 - Restituição - Dedução da Receita Orçamentária",
  },
];

const AnulacaoDeReceita = () => {
  const dataAtual = ReturnDataAtual()
    .replace(/\//g, "-")
    .split("-")
    .reverse()
    .join("-");

  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();

  const { id: paramsIdGuiaAnulacaoReceita } = useParams();
  const location = useLocation();
  const pathNameCadastro = location.pathname.includes("cadastro");

  const { data: dataGuiaReceita } = useFetchData(
    `/guiaDeReceita/${currentClient.clienteId}/${currentYear}`
  );
  // const [optionTipoAnulacao, setOptionTipoAnulacao] = useState(
  //   optionsTipoAnulacao[0].value
  // );
  const [optionsFonteRecurso, setOptionsFonteRecurso] = useState([
    {
      numeroGuia: "",
      fonteId: "",
      codigo: "",
      titulo: "",
      porcentagem: "",
      valor: "",
    },
  ]);

  const [optionsGuiaReceita, setOptionsGuiaReceita] = useState([
    {
      numeroGuia: null,
      saldoGuia: null,
      idNatureza: null,
      codigoNatureza: null,
      tituloNatureza: null,
      idContaBancaria: null,
      fontesGuiaReceita: [
        {
          numeroGuia: "",
          fonteId: "",
          codigo: "",
          titulo: "",
          porcentagem: "",
          valor: "",
        },
      ],
      contasBancariaGuiaReceita: {
        numeroGuia: "",
        idContaBancaria: "",
        nameContaBancaria: "",
        agenciaContaBancaria: "",
      },
    },
  ]);

  const [inputGuiaReceita, setInputGuiaReceita] = useState("");
  const [inputContaBancaria, setInputContaBancaria] = useState("");

  const [indexEditGuiaReceita, setIndexEditGuiaReceita] = useState(null);
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Motivo",
    numeroAba: 0,
  });
  const [valuesAddGuiaReceita, setValuesAddGuiaReceita] = useState({
    infosGuiaReceita: {
      numeroGuia: "",
      saldoGuia: "",
      idNatureza: "",
      label: "",
      valorAnulacao: "",
    },
    fontesGuiaReceita: [
      {
        numeroGuia: "",
        fonteId: "",
        codigo: "",
        titulo: "",
        porcentagem: "",
        valor: "",
      },
    ],
    contasBancariaGuiaReceita: [
      {
        numeroGuia: "",
        idContaBancaria: "",
        nameContaBancaria: "",
        agenciaContaBancaria: "",
      },
    ],
  });

  const [valuesNaturezaAntesDaEdicao, setValuesNaturezaAntesDaEdicao] =
    useState({
      saldoGuia: "0,00",
      valorAnulacao: "0,00",
    });

  const [valuesAnulacaoReceita, setValuesAnulacaoReceita] = useState({
    numero: "",
    tipoAnulacao: optionsTipoAnulacao[0].value,
    data: dataAtual,
    docFinanceiro: "",
    clienteId: currentClient.clienteId,
    contaOrigemId: "",
    motivo: "",
    guiaReceitas: [
      {
        numeroGuia: "",
        saldoGuia: "",
        idNatureza: "",
        label: "",
        valorAnulacao: "",
        idContaBancaria: "",
        nameContaBancaria: "",
        agenciaContaBancaria: "",
      },
    ],
    fontesGuiaReceita: [
      {
        numeroGuia: "",
        fonteId: "",
        codigo: "",
        titulo: "",
        porcentagem: "",
        valor: "",
      },
    ],
    contasBancariaGuiaReceita: [
      {
        numeroGuia: "",
        idContaBancaria: "",
        nameContaBancaria: "",
        agenciaContaBancaria: "",
      },
    ],
  });

  const optionTipoAnulacaoIsRestituicao =
    "2 - Restituição - Dedução da Receita Orçamentária" ===
    valuesAnulacaoReceita.tipoAnulacao;

  const handleSelectAnulacaoReceita = (option) => {
    console.log(option);
    // setOptionTipoAnulacao(option.value);
    setValuesAnulacaoReceita((prevState) => ({
      ...prevState,
      tipoAnulacao: option.value,
    }));
  };

  const handleSelectGuiaReceita = (option) => {
    console.log(option);

    setValuesAddGuiaReceita({
      infosGuiaReceita: {
        // idContaBancaria: option.idContaBancaria,
        // agenciaContaBancaria: option.agenciaContaBancaria,
        // nameContaBancaria: option.nameContaBancaria,
        idNatureza: option.idNatureza,
        label: option.label,
        numeroGuia: option.numeroGuia,
        saldoGuia: option.saldoGuia,
        valorAnulacao: !optionTipoAnulacaoIsRestituicao
          ? option.saldoGuia
          : "0,00",
      },
      fontesGuiaReceita: option.fontesGuiaReceita,
      contasBancariaGuiaReceita: option.contasBancariaGuiaReceita,
    });
  };

  const handleSelectContaBancaria = (option) => {
    console.log(option);

    setValuesAnulacaoReceita((prevState) => ({
      ...prevState,
      contaOrigemId: option.idContaBancaria,
    }));
  };

  const handleAddGuiaReceita = () => {
    console.log(valuesAddGuiaReceita);
    const hasSaldoGuia = valuesAddGuiaReceita.infosGuiaReceita.saldoGuia;
    if (!hasSaldoGuia) {
      toast.error("Esta Guia não possui saldo");
      return;
    }

    if (!validateFieldsGuiaReceita()) return;

    if (optionTipoAnulacaoIsRestituicao && !validateSaldoAnulacao()) return;

    if (!checkExistingGuiaReceitas()) {
      setInputContaBancaria(
        `${valuesAddGuiaReceita.contasBancariaGuiaReceita.nameContaBancaria} - ${valuesAddGuiaReceita.contasBancariaGuiaReceita.agenciaContaBancaria}`
      );
      setValuesAnulacaoReceita({
        ...valuesAnulacaoReceita,
        contaOrigemId:
          valuesAddGuiaReceita.contasBancariaGuiaReceita.idContaBancaria,
        guiaReceitas: [valuesAddGuiaReceita.infosGuiaReceita],
        fontesGuiaReceita: [
          ...valuesAddGuiaReceita.fontesGuiaReceita,
          ...valuesAnulacaoReceita.fontesGuiaReceita,
        ],
        contasBancariaGuiaReceita: [
          valuesAddGuiaReceita.contasBancariaGuiaReceita,
          ...valuesAnulacaoReceita.contasBancariaGuiaReceita,
        ],
      });
      setOptionsFonteRecurso([
        ...valuesAddGuiaReceita.fontesGuiaReceita,
        ...valuesAnulacaoReceita.fontesGuiaReceita,
      ]);
      resetGuiaReceitaFields();
      return;
    }

    if (guiaAlreadyExists()) {
      toast.error("Esta Guia já foi adicionada.");
      return;
    }

    if (!validateContaBancaria()) {
      toast.error("Adicione Receitas com a mesma Natureza e Conta Bancária.");
      return;
    }

    addGuiaReceita();
  };

  const validateFieldsGuiaReceita = () => {
    const hasValues = Object.values(
      valuesAddGuiaReceita.infosGuiaReceita
    ).every((item) => item !== "");

    if (!hasValues) {
      toast.error(
        "Preencha todos os campos corretamente para adicionar a Guia Orçamentária."
      );
      return false;
    }

    return true;
  };

  const validateSaldoAnulacao = () => {
    const saldoMenorValorAnulacao =
      parseFloat(valuesAddGuiaReceita.infosGuiaReceita.saldoGuia) <
      parseFloat(valuesAddGuiaReceita.infosGuiaReceita.valorAnulacao);

    if (saldoMenorValorAnulacao) {
      toast.error("O valor de anulação não pode ser maior que o saldo.");
      return false;
    }

    return true;
  };

  const checkExistingGuiaReceitas = () => {
    const filterGuiaReceita = valuesAnulacaoReceita.guiaReceitas.filter(
      (item) => item.idNatureza
    );

    if (!filterGuiaReceita.length) {
      return false;
    }

    return true;
  };

  const guiaAlreadyExists = () => {
    return valuesAnulacaoReceita.guiaReceitas.some(
      (item) =>
        item.numeroGuia === valuesAddGuiaReceita.infosGuiaReceita.numeroGuia
    );
  };

  const validateContaBancaria = () => {
    return valuesAnulacaoReceita.guiaReceitas.every(
      (item) =>
        item.idContaBancaria ===
          valuesAddGuiaReceita.infosGuiaReceita.idContaBancaria &&
        item.idNatureza === valuesAddGuiaReceita.infosGuiaReceita.idNatureza
    );
  };

  const addGuiaReceita = () => {
    setValuesAnulacaoReceita({
      ...valuesAnulacaoReceita,
      contaOrigemId:
        valuesAddGuiaReceita.contasBancariaGuiaReceita.idContaBancaria,
      guiaReceitas: [
        valuesAddGuiaReceita.infosGuiaReceita,
        ...valuesAnulacaoReceita.guiaReceitas,
      ],
      fontesGuiaReceita: [
        ...valuesAddGuiaReceita.fontesGuiaReceita,
        ...valuesAnulacaoReceita.fontesGuiaReceita,
      ],
      contasBancariaGuiaReceita: [
        valuesAddGuiaReceita.contasBancariaGuiaReceita,
        ...valuesAnulacaoReceita.contasBancariaGuiaReceita,
      ],
    });
    resetGuiaReceitaFields();
  };

  const resetGuiaReceitaFields = () => {
    setValuesAddGuiaReceita({
      ...valuesAddGuiaReceita,
      infosGuiaReceita: {
        idNatureza: "",
        label: "",
        numeroGuia: "",
        saldoGuia: "0,00",
        valorAnulacao: "0,00",
      },
    });
    setInputGuiaReceita("");
  };

  const handleEditGuiaReceita = (indexGuiaReceita) => {
    console.log(indexGuiaReceita);
    const { saldoGuia, valorAnulacao } =
      valuesAnulacaoReceita.guiaReceitas[indexGuiaReceita];
    setValuesNaturezaAntesDaEdicao({ saldoGuia, valorAnulacao });
    setIndexEditGuiaReceita(indexGuiaReceita);
  };

  const handleDeleteGuiaReceita = (indexGuiaReceita, option) => {
    console.log(indexGuiaReceita);
    console.log(option);
    const guiaReceitas = valuesAnulacaoReceita.guiaReceitas.filter(
      (_, index) => index !== indexGuiaReceita
    );
    const fontesGuiaReceita = valuesAnulacaoReceita.fontesGuiaReceita.filter(
      (item) => item.numeroGuia !== option.numeroGuia
    );

    const contasBancariaGuiaReceita =
      valuesAnulacaoReceita.contasBancariaGuiaReceita.filter(
        (item) => item.numeroGuia !== option.numeroGuia && item.numeroGuia
      );

    setValuesAnulacaoReceita({
      ...valuesAnulacaoReceita,
      guiaReceitas,
      fontesGuiaReceita,
      contasBancariaGuiaReceita,
    });

    setOptionsFonteRecurso(fontesGuiaReceita);

    if (!contasBancariaGuiaReceita.length) {
      setInputContaBancaria("");
    }
  };

  const handleSaveEditGuiaReceita = (option) => {
    console.log(option);

    if (!optionTipoAnulacaoIsRestituicao) {
      setIndexEditGuiaReceita(null);
      return;
    }
    const saldoMenorValorAnulacao =
      parseFloat(option.saldoGuia) < parseFloat(option.valorAnulacao);

    if (saldoMenorValorAnulacao) {
      toast.error("O valor de anulação não pode ser maior que o saldo.");
      return;
    }
    setIndexEditGuiaReceita(null);
  };

  const handleCancelEditGuiaReceita = () => {
    const guiaReceitas = valuesAnulacaoReceita.guiaReceitas.map(
      (item, index) => {
        if (index === indexEditGuiaReceita) {
          return {
            ...item,
            saldoGuia: valuesNaturezaAntesDaEdicao.saldoGuia,
            valorAnulacao: valuesNaturezaAntesDaEdicao.valorAnulacao,
          };
        }
        return item;
      }
    );

    setValuesAnulacaoReceita({ ...valuesAnulacaoReceita, guiaReceitas });
    setIndexEditGuiaReceita(null);
  };

  const handleEditValorSaldoGuia = (indexGuiaReceita, saldoGuia) => {
    const guiaReceitas = valuesAnulacaoReceita.guiaReceitas.map(
      (item, index) => {
        if (index === indexGuiaReceita) {
          return {
            ...item,
            saldoGuia,
          };
        }
        return item;
      }
    );

    setValuesAnulacaoReceita({ ...valuesAnulacaoReceita, guiaReceitas });
  };

  const handleEditValorAnulacao = (indexGuiaReceita, valorAnulacao) => {
    const guiaReceitas = valuesAnulacaoReceita.guiaReceitas.map(
      (item, index) => {
        if (index === indexGuiaReceita) {
          return {
            ...item,
            valorAnulacao,
          };
        }
        return item;
      }
    );

    setValuesAnulacaoReceita({ ...valuesAnulacaoReceita, guiaReceitas });
  };

  const handleResetValuesCaixa = () => {
    console.log(optionTipoAnulacaoIsRestituicao);
    if (!optionTipoAnulacaoIsRestituicao) return;

    console.log(optionTipoAnulacaoIsRestituicao);

    setInputContaBancaria("");
  };

  const handleRegisterGuiaReceita = async () => {
    console.log(valuesAnulacaoReceita);

    const hasValuesAnulacaoReceita = Object.entries(valuesAnulacaoReceita)
      .filter(([key, value]) => key !== "numero")
      .filter(([key, value]) => typeof value !== "object" || value === null)
      .some(([key, value]) => typeof value === "string" && value.trim() === "");

    console.log(hasValuesAnulacaoReceita);

    const valuesReceitas = valuesAnulacaoReceita.guiaReceitas.filter(
      (item) => item.idNatureza
    );

    if (!valuesReceitas.length) {
      toast.error("Adicione Receitas para serem anuladas.");
      return;
    }

    const valuesFonteRecursos = valuesAnulacaoReceita.fontesGuiaReceita.filter(
      (item) => item.fonteId
    );

    if (!valuesFonteRecursos.length) {
      toast.error("Adicione as Fontes de Recursos.");
      return;
    }

    const valuesContaBancaria =
      valuesAnulacaoReceita.contasBancariaGuiaReceita.filter(
        (item) => item.idContaBancaria
      );

    if (!valuesContaBancaria.length) {
      toast.error("Adicione uma Conta Bancária.");
      return;
    }

    const hasDocFinanceiro =
      optionTipoAnulacaoIsRestituicao && !valuesAnulacaoReceita.docFinanceiro;

    if (hasDocFinanceiro || !valuesAnulacaoReceita.motivo) {
      toast.error("Informe todos os campos.");
      return;
    }

    const completeWithZerosDocFinanceiro = String(
      valuesAnulacaoReceita.docFinanceiro
    ).padEnd(11, "0");

    const guiaDeReceita = formattedGuiaReceitaByPost(valuesReceitas);
    console.log(guiaDeReceita);

    const fontes = formattedFonteRecursosByPost(valuesFonteRecursos);
    console.log(fontes);
    console.log(valuesFonteRecursos);

    console.log(parseFloat("0,01".replace(",", ".")));

    const infosPostAnulacaoReceita = {
      clienteId: valuesAnulacaoReceita.clienteId,
      tipoAnulacao: parseInt(valuesAnulacaoReceita.tipoAnulacao[0]),
      data: formattedDate(valuesAnulacaoReceita.data),
      totalAnulacao: totalAnulacaoReceita,
      contaOrigemId: valuesAnulacaoReceita.contaOrigemId,
      motivo: valuesAnulacaoReceita.motivo,
      docFinanceiro: parseInt(completeWithZerosDocFinanceiro),
      guiaDeReceita,
      fontes,
    };
    console.log(infosPostAnulacaoReceita);

    if (paramsIdGuiaAnulacaoReceita && !pathNameCadastro) {
      console.log(infosPostAnulacaoReceita);
      await putAnulacaoReceita(infosPostAnulacaoReceita);
      return;
    }
    console.log(infosPostAnulacaoReceita);
    await postAnulacaoReceita(infosPostAnulacaoReceita);
  };

  const formattedDate = (date) => {
    const separatorDate = date.includes("/") ? "/" : "-";

    if (separatorDate === "-") {
      const [year, month, day] = date.split(separatorDate);
      return `${year}-${month}-${day}`;
    }

    const [day, month, year] = date.split(separatorDate);
    return `${year}-${month}-${day}`;
  };

  const formattedGuiaReceitaByPost = (guias) => {
    const formattedGuias = guias.map((item) => {
      return {
        guiaReceitaId: item.numeroGuia,
        saldoGuia: parseFloat(item.saldoGuia),
        valorAnulacao: parseFloat(item.valorAnulacao),
      };
    });
    return formattedGuias;
  };

  const formattedFonteRecursosByPost = (fontes) => {
    const formattedFontes = fontes.map((item) => {
      return {
        guiaId: item.numeroGuia,
        fonteId: item.fonteId,
        percentual: parseFloat(item.porcentagem.replace(/,/g, ".")),
        valor: parseFloat(item.valor.replace(/,/g, ".")),
      };
    });
    return formattedFontes;
  };

  const postAnulacaoReceita = async (infosAnulacaoReceita) => {
    try {
      await api.post("anulacaoReceita", infosAnulacaoReceita);
      toast.success("Cadastro realizado com sucesso.");
      resetFieldsAnulacaoReceita();
      setInputContaBancaria("");
    } catch (error) {
      if (error.response.status === 500) {
        toast.error("Erro ao criar o cadastro. Esta Guia já foi anulada.");
        return;
      }
      console.log(error);
      toast.error("Erro ao criar o cadastro. Tente novamente mais tarde.");
    }
  };

  const putAnulacaoReceita = async (infosAnulacaoReceita) => {
    try {
      await api.put(
        `anulacaoReceita/update/${paramsIdGuiaAnulacaoReceita}`,
        infosAnulacaoReceita
      );

      toast.success("Registro atualizado com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar o registro. Tente novamente mais tarde.");
    }
  };

  const resetFieldsAnulacaoReceita = () => {
    setValuesAnulacaoReceita({
      ...valuesAnulacaoReceita,
      docFinanceiro: "",
      contaOrigemId: "",
      motivo: "",
      guiaReceitas: [
        {
          numeroGuia: "",
          saldoGuia: "",
          idNatureza: "",
          label: "",
          valorAnulacao: "",
          idContaBancaria: "",
          nameContaBancaria: "",
          agenciaContaBancaria: "",
        },
      ],
      fontesGuiaReceita: [
        {
          numeroGuia: "",
          fonteId: "",
          codigo: "",
          titulo: "",
          porcentagem: "",
          valor: "",
        },
      ],
      contasBancariaGuiaReceita: [
        {
          numeroGuia: "",
          idContaBancaria: "",
          nameContaBancaria: "",
          agenciaContaBancaria: "",
        },
      ],
    });
  };

  const onChangeTextDocFinanceiro = (value) => {
    console.log(value);

    const inputValue = value.replace(/\D/g, "");
    const maskedValue = inputValue.slice(0, 11);
    setValuesAnulacaoReceita((prevState) => ({
      ...prevState,
      docFinanceiro: maskedValue,
    }));
  };

  const formattedValuesAnulacaoReceitaById = (dataAnulacaoReceita) => {
    const typeAnulacaoReceita = optionsTipoAnulacao.find(
      (item) => item.type === dataAnulacaoReceita.tipoAnulacao
    );

    if (!typeAnulacaoReceita) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
      return;
    }
    console.log(dataAnulacaoReceita);

    const date = dataAnulacaoReceita.data.split("T")[0];
    const [year, month, day] = date.split("-");
    const dateFormatted = `${day}/${month}/${year}`;

    const guiaReceitas = dataAnulacaoReceita.guiasDeReceita.map((item) => {
      return {
        numeroGuia: item.guiaReceitaId,
        saldoGuia: item.saldoGuia,
        idNatureza: item.info.naturezaId,
        label: `${item.info.naturezaInfo.nr} - ${item.info.naturezaInfo.especificacao}`,
        valorAnulacao: item.valorAnulacao,
        idContaBancaria: dataAnulacaoReceita.contaOrigemInfo.id,
        nameContaBancaria: dataAnulacaoReceita.contaOrigemInfo.nome,
        agenciaContaBancaria: dataAnulacaoReceita.contaOrigemInfo.numeroConta,
      };
    });

    console.log(guiaReceitas);

    const fontesGuiaReceita = dataAnulacaoReceita.fontes
      .map((item) => {
        return item.fonteInfo.map((fonte) => {
          return {
            numeroGuia: item.guiaId,
            fonteId: item.fonteId,
            codigo: fonte.conta,
            titulo: fonte.titulo,
            porcentagem: item.percentual,
            valor: item.valor,
          };
        });
      })
      .flat();

    console.log(fontesGuiaReceita);

    const numeroGuia = dataAnulacaoReceita.guiasDeReceita[0].guiaReceitaId;

    const contaBancariaGuiaReceita = {
      numeroGuia,
      idContaBancaria: dataAnulacaoReceita.contaOrigemInfo.id,
      nameContaBancaria: dataAnulacaoReceita.contaOrigemInfo.nome,
      agenciaContaBancaria: dataAnulacaoReceita.contaOrigemInfo.numeroConta,
    };

    const infosAnulacao = {
      numero: dataAnulacaoReceita.id,
      tipoAnulacao: typeAnulacaoReceita.label,
      data: dateFormatted,
      docFinanceiro: dataAnulacaoReceita.docFinanceiro,
      clienteId: dataAnulacaoReceita.clienteId,
      contaOrigemId: dataAnulacaoReceita.contaOrigemId,
      motivo: dataAnulacaoReceita.motivo,
      guiaReceitas,
      fontesGuiaReceita,
      contasBancariaGuiaReceita: [contaBancariaGuiaReceita],
    };
    setInputContaBancaria(
      `${contaBancariaGuiaReceita.agenciaContaBancaria} - ${contaBancariaGuiaReceita.nameContaBancaria}`
    );
    setOptionsFonteRecurso(fontesGuiaReceita);

    return infosAnulacao;
  };

  const addGuiaReceitaId = useCallback(() => {
    console.log(paramsIdGuiaAnulacaoReceita);
    console.log(optionsGuiaReceita);

    const guiaReceita = optionsGuiaReceita.find(
      (item) => item.numeroGuia === Number(paramsIdGuiaAnulacaoReceita)
    );

    if (!guiaReceita) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
      return;
    }
    const infosGuiaReceita = {
      numeroGuia: guiaReceita.numeroGuia,
      saldoGuia: guiaReceita.saldoGuia,
      idNatureza: guiaReceita.idNatureza,
      label: `${guiaReceita.codigoNatureza} - ${guiaReceita.tituloNatureza}`,
      valorAnulacao: guiaReceita.saldoGuia,
      idContaBancaria: guiaReceita.idContaBancaria,
      nameContaBancaria:
        guiaReceita.contasBancariaGuiaReceita.nameContaBancaria,
      agenciaContaBancaria:
        guiaReceita.contasBancariaGuiaReceita.agenciaContaBancaria,
    };

    console.log(guiaReceita);
    setValuesAnulacaoReceita((prevState) => ({
      ...prevState,
      contaOrigemId: guiaReceita.idContaBancaria,
      guiaReceitas: [infosGuiaReceita],
      fontesGuiaReceita: guiaReceita.fontesGuiaReceita,
      contasBancariaGuiaReceita: [guiaReceita.contasBancariaGuiaReceita],
    }));
    setInputContaBancaria(
      `${guiaReceita.contasBancariaGuiaReceita.agenciaContaBancaria} - ${guiaReceita.contasBancariaGuiaReceita.nameContaBancaria}`
    );
    setOptionsFonteRecurso(guiaReceita.fontesGuiaReceita);
  }, [optionsGuiaReceita, paramsIdGuiaAnulacaoReceita]);

  const fetchDataAnulacaoReceitaById = useCallback(async () => {
    try {
      const resp = await api.get(
        `/anulacaoReceita/${paramsIdGuiaAnulacaoReceita}/${currentYear}`
      );
      const data = resp.data;
      const infosAnulacaoReceita = formattedValuesAnulacaoReceitaById(data);
      console.log(infosAnulacaoReceita);
      setValuesAnulacaoReceita(infosAnulacaoReceita);
    } catch (error) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
    }
  }, [currentYear, paramsIdGuiaAnulacaoReceita]);

  useEffect(() => {
    if (dataGuiaReceita) {
      const formattedOptions = dataGuiaReceita.map((receita) => {
        const fontesGuiaReceita = receita.fontes.map((fonte) => {
          return {
            numeroGuia: receita.id,
            fonteId: fonte.fonteId,
            codigo: fonte.info.conta,
            titulo: fonte.info.titulo,
            porcentagem: "0,00",
            valor: fonte.valor,
          };
        });
        const contasBancariaGuiaReceita = {
          idContaBancaria: receita.contaBancariaInfo.id,
          nameContaBancaria: receita.contaBancariaInfo.nome,
          agenciaContaBancaria: receita.contaBancariaInfo.numeroConta,
          numeroGuia: receita.id,
        };
        return {
          numeroGuia: receita.id,
          saldoGuia: receita.saldo,
          idNatureza: receita.naturezaId,
          codigoNatureza: receita.naturezaInfo.nr,
          tituloNatureza: receita.naturezaInfo.especificacao,
          idContaBancaria: receita.contaBancariaId,
          nameContaBancaria: receita.contaBancariaInfo.nome,
          agenciaContaBancaria: receita.contaBancariaInfo.numeroConta,
          fontesGuiaReceita,
          contasBancariaGuiaReceita,
        };
      });
      console.log(formattedOptions);
      setOptionsGuiaReceita(formattedOptions);
    }
  }, [dataGuiaReceita]);

  useEffect(() => {
    if (paramsIdGuiaAnulacaoReceita && !pathNameCadastro) {
      fetchDataAnulacaoReceitaById();
      return;
    }
    const hasOptionsGuiaReceita = optionsGuiaReceita.every(
      (item) => item.numeroGuia !== null
    );
    if (
      paramsIdGuiaAnulacaoReceita &&
      pathNameCadastro &&
      hasOptionsGuiaReceita
    ) {
      console.log(optionsGuiaReceita);

      addGuiaReceitaId();
    }
  }, [
    addGuiaReceitaId,
    fetchDataAnulacaoReceitaById,
    optionsGuiaReceita,
    paramsIdGuiaAnulacaoReceita,
    pathNameCadastro,
  ]);

  const totalAnulacaoReceita = useMemo(() => {
    const total = valuesAnulacaoReceita.guiaReceitas.reduce((acc, item) => {
      // console.log(parseFloat(item.valorAnulacao.replace(",",".")));
      const valorAnulacao =
        typeof item.valorAnulacao === "string"
          ? item.valorAnulacao.replace(",", ".")
          : String(item.valorAnulacao || "0");
      acc += parseFloat(valorAnulacao) || 0;
      return acc;
    }, 0);

    return total;
  }, [valuesAnulacaoReceita.guiaReceitas]);

  // const contas = dataGuiaReceita
  //   ?.map((item) => {
  //     if (item.contaBancariaId === 9) {
  //       return {
  //         idCaixaContaBancaria: item.contaBancariaId,
  //         nameContaBancaria: item.contaBancariaInfo.nome,
  //         agenciaContaBancaria: item.contaBancariaInfo.numeroConta,
  //       };
  //     }
  //     return null;
  //   })
  //   .filter((item) => item);
  // console.log(contas);

  // useEffect(() => {
  //   fetchContas();
  // }, [fetchContas]);

  // console.log(naturezaValues);
  console.log(!optionTipoAnulacaoIsRestituicao);
  console.log(valuesAnulacaoReceita);

  // const optionsContaBancaria = valuesAnulacaoReceita.guiaReceitas.map(
  //   (item) => {
  //     return {
  //       idCaixaContaBancaria: item.idContaBancaria,
  //       nameContaBancaria: item.nameContaBancaria,
  //       agenciaContaBancaria: item.agenciaContaBancaria,
  //     };
  //   }
  // );
  // console.log(optionsContaBancaria);
  console.log(inputContaBancaria);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70", marginBottom: "10px" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        PaginaSubtitulo={"Cadastro"}
        NomePaginaTitulo={"Anulação de Receita"}
        ButtonSaveFunction={handleRegisterGuiaReceita}
        PaginaConsulta={"/contabilidade/receita/anulacao-de-receita/consulta"}
        BotaoNovoAtivo={false}
      />
      <ToastContainer />

      <RowForm className="mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            label="Número"
            placeholder="Número"
            maxLength="7"
            value={valuesAnulacaoReceita.numero}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            placeholder="Tipo de Anulação"
            label="Tipo de Anulação"
            value={valuesAnulacaoReceita.tipoAnulacao}
            options={[
              { label: "Tipo de Anulação", value: null },
              ...optionsTipoAnulacao,
            ]}
            onSelect={(option) => handleSelectAnulacaoReceita(option)}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            onChange={(e) =>
              setValuesAnulacaoReceita((prev) => ({
                ...prev,
                data: e.target.value,
              }))
            }
            externalValue={valuesAnulacaoReceita.data}
          />
        </CCol>
      </RowForm>

      <RowForm className="mt-1">
        <CCol md={1}>
          <PTitulosInputs>Guia</PTitulosInputs>
        </CCol>
        <CCol>
          <PTitulosInputs>Receita Realizada</PTitulosInputs>
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Saldo da Guia</PTitulosInputs>
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Valor Anulação</PTitulosInputs>
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
        </CColButtonsAcoes2Icons>

        <RowAninhada>
          <CCol md={1}>
            <FormControlListaDeInputs
              disabled
              value={valuesAddGuiaReceita.infosGuiaReceita.numeroGuia}
            />
          </CCol>
          <CCol>
            <MyAutoComplete
              inputValue={inputGuiaReceita}
              setInputValue={setInputGuiaReceita}
              options={optionsGuiaReceita}
              labelFormat={(option) =>
                `${option.codigoNatureza} - ${option.tituloNatureza}`
              }
              onOptionSelect={(option) => {
                handleSelectGuiaReceita(option);
              }}
              iconClearFunction={resetGuiaReceitaFields}
            />
          </CCol>
          <CCol md={2}>
            <InputFormatRealBrasileiro
              listaDeInputs
              disabled
              externalValue={valuesAddGuiaReceita.infosGuiaReceita.saldoGuia}
              onChange={(_, valor) =>
                setValuesAddGuiaReceita((prev) => ({
                  ...prev,
                  infosGuiaReceita: {
                    ...prev.infosGuiaReceita,
                    saldoGuia: valor,
                  },
                }))
              }
            />
          </CCol>
          <CCol md={2}>
            <InputFormatRealBrasileiro
              listaDeInputs
              disabled={!optionTipoAnulacaoIsRestituicao}
              externalValue={
                valuesAddGuiaReceita.infosGuiaReceita.valorAnulacao
              }
              onChange={(_, valor) =>
                setValuesAddGuiaReceita((prev) => ({
                  ...prev,
                  infosGuiaReceita: {
                    ...prev.infosGuiaReceita,
                    valorAnulacao: valor,
                  },
                }))
              }
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <IconAdicionar onClick={handleAddGuiaReceita} />
          </CColButtonsAcoes2Icons>
        </RowAninhada>

        {valuesAnulacaoReceita.guiaReceitas.map((item, index, array) => {
          if (!item.idNatureza) return null;
          return (
            <RowAninhada key={index}>
              <CCol md={1}>
                <FormControlListaDeInputs
                  disabled
                  value={item.numeroGuia}
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  value={item.label}
                  disabled
                />
              </CCol>
              <CCol md={2}>
                <InputFormatRealBrasileiro
                  listaDeInputs
                  externalValue={item.saldoGuia}
                  // disabled={index !== indexEditGuiaReceita}
                  disabled
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  onChange={(_, valor) =>
                    handleEditValorSaldoGuia(index, valor)
                  }
                />
              </CCol>
              <CCol md={2}>
                <InputFormatRealBrasileiro
                  listaDeInputs
                  externalValue={item.valorAnulacao}
                  disabled={
                    !optionTipoAnulacaoIsRestituicao ||
                    index !== indexEditGuiaReceita
                  }
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  onChange={(_, valor) => handleEditValorAnulacao(index, valor)}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {indexEditGuiaReceita !== index && (
                  <>
                    <Icon
                      onClick={() => handleEditGuiaReceita(index)}
                      icon="bx:edit"
                      color="#5971C8"
                      height="26"
                      style={{
                        cursor: "pointer",
                        marginTop: "3px",
                      }}
                    />
                    <Icon
                      onClick={() => handleDeleteGuiaReceita(index, item)}
                      icon="ic:baseline-delete"
                      color="#E79900"
                      height="26"
                      style={{
                        cursor: "pointer",
                        marginLeft: "3px",
                        marginTop: "2px",
                      }}
                    />
                  </>
                )}
                {indexEditGuiaReceita !== null &&
                  indexEditGuiaReceita === index && (
                    <>
                      <Icon
                        onClick={() => handleSaveEditGuiaReceita(item)}
                        icon="fluent:save-24-filled"
                        color="#008CFF"
                        height="26"
                        style={{
                          cursor: "pointer",
                          marginTop: "3px",
                        }}
                      />
                      <Icon
                        icon="ic:baseline-cancel"
                        onClick={() => handleCancelEditGuiaReceita(index)}
                        height="26"
                        color="#F24E1E"
                        style={{
                          display:
                            indexEditGuiaReceita !== index
                              ? "none"
                              : "inline-block",
                          pointerEvents:
                            indexEditGuiaReceita !== index ? "none" : "auto",
                          cursor:
                            indexEditGuiaReceita !== index
                              ? "not-allowed"
                              : "pointer",
                          marginLeft: "2px",
                          marginRight: "-5px",
                          marginTop: "3px",
                        }}
                      />
                    </>
                  )}
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          );
        })}

        <RowAninhada style={{ marginTop: "16px" }}>
          <CCol md={1}></CCol>
          <CCol></CCol>
          <CCol
            md={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ margin: 0, padding: 0, fontWeight: 600 }}>
              Total Anulação
            </p>
          </CCol>
          <CCol md={2}>
            <FormControlListaDeInputs
              disabled
              style={{ border: "none", borderRadius: 20, textAlign: "right" }}
              value={`R$ ${FormatValueToLocaleString(totalAnulacaoReceita)}`}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
        </RowAninhada>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={["Motivo", "Fonte de Recursos"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />
      {abaSelecionada.nomeAba === "Motivo" && (
        <MotivoAba
          infosAnulacaoReceita={valuesAnulacaoReceita}
          setInfosAnulacaoReceita={setValuesAnulacaoReceita}
        />
      )}
      {abaSelecionada.nomeAba === "Fonte de Recursos" && (
        <FonteDeRecursosAba
          infosAnulacaoReceita={valuesAnulacaoReceita}
          setInfosAnulacaoReceita={setValuesAnulacaoReceita}
          optionsFonteRecurso={optionsFonteRecurso}
          optionTipoAnulacaoIsRestituicao={optionTipoAnulacaoIsRestituicao}
        />
      )}

      <RowTituloAba>
        <div>
          <span>Origem Financeira</span>
        </div>
      </RowTituloAba>

      <RowForm style={{ marginTop: "5px" }} $borderRadiusType={3}>
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            placeholder="Caixa e Equivalente Caixa"
            labelInput="Caixa e Equivalente Caixa"
            disabled={!optionTipoAnulacaoIsRestituicao}
            options={valuesAnulacaoReceita.contasBancariaGuiaReceita}
            labelFormat={(item) =>
              `${item.agenciaContaBancaria} - ${item.nameContaBancaria}`
            }
            inputValue={inputContaBancaria}
            setInputValue={setInputContaBancaria}
            onOptionSelect={(option) => handleSelectContaBancaria(option)}
            iconClearFunction={handleResetValuesCaixa}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            type="text"
            label="Doc Financeiro"
            placeholder="Doc Financeiro"
            value={valuesAnulacaoReceita.docFinanceiro}
            onChange={(e) => onChangeTextDocFinanceiro(e.target.value)}
          />
        </CCol>
      </RowForm>
    </Container>
  );
};

export default AnulacaoDeReceita;
