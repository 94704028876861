import React from "react";
import { RowForm } from "../../../../components/Grid/CCol";
import { TituloTable } from "../../../../components/TableConsulta/TableConsulta";
import { Table } from "react-bootstrap";
import { BodyTable } from "../../../Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/EstruturaReceita";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";

const ProcedimentosContabeisAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Procedimentos Contábeis</span>
        </div>
      </RowTituloAba>
      <RowForm style={{ marginTop: "4px" }}>
        <Table responsive>
          <thead>
            <tr>
              <TituloTable style={{ borderStyle: "none" }}>Título</TituloTable>
              <TituloTable style={{ borderStyle: "none" }}>
                Categoria
              </TituloTable>
              <TituloTable style={{ borderStyle: "none" }}>
                Natureza Saldo
              </TituloTable>
            </tr>
          </thead>
          <BodyTable
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              borderStyle: "none",
              borderColor: "transparent",
              borderBottomWidth: 0,
            }}
          >
            <tr style={{ backgroundColor: "#EBEFFF" }}>
              <td
                style={{
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderBottomLeftRadius: "8px",
                  borderTopLeftRadius: "8px",
                }}
              >
                02051082442001203844901500
              </td>
              <td
                style={{
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                }}
              >
                D 1.1.1.1.1.00.00 Caixa e Equivalentes de Caixa em Moeda
                Nacional
              </td>
              <td
                style={{
                  whiteSpace: "nowrap",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderBottomRightRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                R$ 260.000,00
              </td>
            </tr>
          </BodyTable>
        </Table>
      </RowForm>
    </>
  );
};

export default ProcedimentosContabeisAba;
