export const optionsTipoLancamento = [
  { label: "1 - Lançamento de Receita", value: 1 },
  {
    label: "3 - Dedução de Receita do Fundeb",
    value: 3,
  },
  {
    label: "4 - Dedução de Receita de Rendimentos de Investimentos",
    value: 4,
  },
  {
    label: "5 - Outras Deduções de Receita",
    value: 5,
  },
]
