import React, { useContext, useEffect, useState } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowForm,
} from "../../../../components/Grid/CCol";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { selectUnidadesMedidasOptions } from "../../../../utils/DadosGerais/unidadesMedidas";
// import { TestButton } from "../../../../utils/TestButton";
import { toast } from "react-toastify";
import { TestButton } from "../../../../utils/TestButton";
import IconsEditInfo from "../../../../components/ListaDeInputs/IconsEditInfo";
import api from "../../../../utils/api";

const ProdutoEAquisicaoAba = ({
  produtoAquisicao = [],
  setProdutoAquisicao,
  valorTotal,
  numero,
  liquidacaoAtualId,
}) => {
  const { currentClient } = useContext(CurrentClientContext);
  const [inputFornecedor, setInputFornecedor] = useState();
  const [produtoAquisicaoDraft, setProdutoAquisicaoDraft] = useState([]);
  const [produtoAquisicaoOptions, setProdutoAquisicaoOptions] = useState([]);
  const [produtoAquisicaoValues, setProdutoAquisicaoValues] = useState({
    quantidade: 0,
    produtoAquisicao: "",
    unidadeMedida: null,
    valor: "",
  });
  const [somaValores, setSomaValores] = useState(0);

  const handleEdit = (index) => {
    console.log(index);

    // Atualiza o estado de produtoAquisicao
    setProdutoAquisicao((prevState) =>
      prevState.map((item, i) =>
        i === index ? { ...item, editing: !item.editing } : item
      )
    );

    // Atualiza o produtoAquisicaoDraft no índice correspondente
    setProdutoAquisicaoDraft((prevDraft) => {
      const updatedDraft = [...prevDraft];
      updatedDraft[index] = {
        ...updatedDraft[index],
        editing: !updatedDraft[index]?.editing, // Alterna o valor de editing no índice correto
      };
      return updatedDraft;
    });
  };

  const handleSave = (index) => {
    console.log(index);
    setProdutoAquisicao((prevState) =>
      prevState.map((item, i) =>
        i === index
          ? { ...produtoAquisicaoDraft[index], editing: !item.editing }
          : item
      )
    );
  };

  useEffect(() => {
    setProdutoAquisicaoDraft((prevDraft) => {
      const diferenca = produtoAquisicao.length - prevDraft.length;

      if (diferenca > 0) {
        const novosValores = Array(diferenca).fill(null);
        return [...novosValores, ...prevDraft];
      }

      return prevDraft;
    });
  }, [produtoAquisicao]);

  useEffect(() => {
    const soma = produtoAquisicao.reduce((acc, item) => {
      const valor = item.valor.includes(",")
        ? parseFloat(item.valor.replace(/\./g, "").replace(",", "."))
        : parseFloat(item.valor);

      return acc + valor;
    }, 0);

    setSomaValores(valorTotal - soma);

    // if (numero) {
    //   produtoAquisicao.forEach((item, index) => {
    //     produtoAquisicao[index] = {
    //       ...item,
    //     };
    //   });
    // }
  }, [produtoAquisicao, valorTotal, numero]);

  const fetchProdutosAquisicoes = async () => {
    try {
      const response = await api.get(
        `/produtoAquisicao/${currentClient.clienteId}`
      );
      const produtoAquisicao = response.data;
      console.log("PRODUTO AQUISIÇÃO", produtoAquisicao);
      setProdutoAquisicaoOptions(
        produtoAquisicao.map((item) => ({
          label: `${item.numero} - ${item.produtoAquisicao}`,
          value: item.numero,
          id: item.id,
          unidadeMedida: item.unidadeDeMedida,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddProdutoAquisicao = () => {
    console.log(valorTotal);
    if (
      produtoAquisicaoValues.unidadeMedida !== null &&
      parseInt(produtoAquisicaoValues.quantidade) > 0 &&
      parseFloat(
        produtoAquisicaoValues.valor.replace(/\./g, "").replace(",", ".")
      ) > 0
    ) {
      // Verifica se o item já existe na lista
      const exists = produtoAquisicao.some(
        (item) =>
          item.produtoAquisicao.value ===
          produtoAquisicaoValues.produtoAquisicao.value
      );

      if (exists) {
        toast.warn("O produto ou aquisição já foi adicionado.");
        return;
      }

      // Adiciona o novo item ao início da lista
      setProdutoAquisicao((prevValues) => [
        produtoAquisicaoValues,
        ...prevValues,
      ]);

      // Reseta os valores dos inputs
      setProdutoAquisicaoValues({
        quantidade: "",
        produtoAquisicao: "",
        unidadeMedida: null,
        valor: "0.00",
      });
      setInputFornecedor("");
    }
  };

  useEffect(() => {
    fetchProdutosAquisicoes();
    // eslint-disable-next-line
  }, []);

  const formatCurrency = (value) => {
    // Remove qualquer caractere não numérico
    const numericValue = value.replace(/[^0-9]/g, "");

    // Remove os zeros à esquerda
    const trimmedValue = numericValue.replace(/^0+/, "");

    // Adiciona zeros à esquerda para garantir que tenhamos pelo menos 3 dígitos
    const paddedValue = trimmedValue.padStart(3, "0");

    // Divide os últimos dois dígitos do restante
    const cents = paddedValue.slice(-2);
    const integerPart = paddedValue.slice(0, -2);

    // Adiciona pontos a cada três dígitos na parte inteira
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      "."
    );

    // Formata o valor no estilo brasileiro
    const formattedValue = `${formattedIntegerPart || "0"},${cents}`;

    return formattedValue;
  };

  return (
    <>
      <TestButton onClick={() => console.log(produtoAquisicaoDraft)}>
        teste
      </TestButton>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Produto e Aquisição</span>
        </div>
      </RowTituloAba>
      <RowForm className="mt-1" $borderRadiusType={2}>
        <CCol md={6}>
          <PTitulosInputs>Produto e Aquisição</PTitulosInputs>
          <MyAutoComplete
            disabled={liquidacaoAtualId}
            options={produtoAquisicaoOptions}
            labelFormat={(item) => `${item.value} - ${item.label}`}
            value={produtoAquisicaoValues.produtoAquisicao}
            inputValue={inputFornecedor}
            setInputValue={setInputFornecedor}
            onOptionSelect={(option) =>
              setProdutoAquisicaoValues((prevValues) => ({
                ...prevValues,
                produtoAquisicao: option,
                unidadeMedida: option.unidadeMedida,
              }))
            }
            radiusborder={produtoAquisicao.length >= 1 ? "5px 5px 0 0" : "5px"}
          />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Unidade de Medida</PTitulosInputs>
          <FloatingLabelInput
            disabled
            height="30px"
            tipo="input_select"
            new
            value={produtoAquisicaoValues.unidadeMedida}
            options={[
              { label: "Selecione", value: null },
              ...selectUnidadesMedidasOptions,
            ]}
            onSelect={(option) => {
              console.log(option);
              setProdutoAquisicaoValues((prevValues) => ({
                ...prevValues,
                unidadeMedida: option.value,
              }));
            }}
            radiusborder={produtoAquisicao.length >= 1 ? "5px 5px 0 0" : "5px"}
            noArrow
          />
        </CCol>
        <CCol md={1}>
          <PTitulosInputs>Quantidade</PTitulosInputs>
          <FormControlListaDeInputs
            disabled={liquidacaoAtualId}
            radiusborder={produtoAquisicao.length >= 1 ? "5px 5px 0 0" : "5px"}
            value={produtoAquisicaoValues.quantidade}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, ""); // Remove qualquer caractere que não seja número
              setProdutoAquisicaoValues((prevValues) => ({
                ...prevValues,
                quantidade: parseInt(value) > 0 ? parseInt(value) : "",
              }));
            }}
          />
        </CCol>
        <CCol>
          <PTitulosInputs>Valor</PTitulosInputs>
          <InputFormatRealBrasileiro
            disabled={liquidacaoAtualId}
            listaDeInputs
            radiusborder={produtoAquisicao.length >= 1 ? "5px 5px 0 0" : "5px"}
            value={produtoAquisicaoValues.valor}
            onChange={(_, valor) => {
              // Converte o valor digitado para número
              let valorNumerico = parseFloat(
                valor.replace(/\./g, "").replace(",", ".")
              );

              // Se o valor for maior que somaValores, ajusta para somaValores
              if (valorNumerico > somaValores) {
                valorNumerico = somaValores;
              }

              // Converte o valor novamente para o formato de string (com a vírgula)
              const valorCorrigido = valorNumerico.toFixed(2).replace(".", ",");

              // Atualiza o estado com o valor corrigido
              setProdutoAquisicaoValues((prevValues) => ({
                ...prevValues,
                valor: formatCurrency(valorCorrigido),
              }));
            }}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar onClick={() => handleAddProdutoAquisicao()} />
        </CColButtonsAcoes2Icons>
      </RowForm>

      {produtoAquisicao.map((item, index) => {
        return (
          <RowForm style={{ paddingTop: "0", marginTop: "-15px" }} key={index}>
            <CCol md={6}>
              {!item.editing ? (
                <FormControlListaDeInputs
                  disabled
                  radiusborder={
                    produtoAquisicao.length === 1
                      ? "0 0 5px 5px"
                      : index + 1 === produtoAquisicao.length
                      ? "0 0 5px 5px"
                      : "0"
                  }
                  value={item.produtoAquisicao.label}
                />
              ) : (
                <MyAutoComplete
                  options={produtoAquisicaoOptions}
                  labelFormat={(item) => `${item.value} - ${item.label}`}
                  value={produtoAquisicaoDraft[index].produtoAquisicao}
                  inputValue={produtoAquisicaoDraft[index]?.fornecedorInput}
                  setInputValue={(newValue) => {
                    setProdutoAquisicaoDraft((prevDraft) => {
                      const updatedDraft = [...prevDraft];
                      updatedDraft[index] = {
                        ...updatedDraft[index],
                        fornecedorInput: newValue,
                      };
                      return updatedDraft;
                    });
                  }}
                  onOptionSelect={(option) => {
                    setProdutoAquisicaoDraft((prevDraft) => {
                      const updatedDraft = [...prevDraft];
                      updatedDraft[index] = {
                        ...updatedDraft[index],
                        fornecedor: option,
                        unidadeMedida: option.unidadeMedida,
                      };
                      return updatedDraft;
                    });
                  }}
                  radiusborder={
                    produtoAquisicao.length === 1
                      ? "0 0 5px 5px"
                      : index + 1 === produtoAquisicao.length
                      ? "0 0 5px 5px"
                      : "0"
                  }
                />
              )}
            </CCol>
            <CCol md={2}>
              <FormControlListaDeInputs
                disabled
                radiusborder={
                  produtoAquisicao.length === 1
                    ? "0 0 5px 5px"
                    : index + 1 === produtoAquisicao.length
                    ? "0 0 5px 5px"
                    : "0"
                }
                value={
                  !item.editing
                    ? item.unidadeMedida
                    : produtoAquisicaoDraft[index]?.unidadeMedida
                }
              />
            </CCol>
            <CCol md={1}>
              <FormControlListaDeInputs
                disabled={!item.editing}
                radiusborder={
                  produtoAquisicao.length === 1
                    ? "0 0 5px 5px"
                    : index + 1 === produtoAquisicao.length
                    ? "0 0 5px 5px"
                    : "0"
                }
                value={
                  !item.editing
                    ? item.quantidade
                    : produtoAquisicaoDraft[index]?.quantidade
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  setProdutoAquisicaoDraft((prevDraft) => {
                    const updatedDraft = [...prevDraft];
                    updatedDraft[index] = {
                      ...updatedDraft[index],
                      quantidade: newValue,
                    };
                    return updatedDraft;
                  });
                }}
              />
            </CCol>
            <CCol>
              {!item.editing ? (
                <FormControlListaDeInputs
                  disabled
                  radiusborder={
                    produtoAquisicao.length === 1
                      ? "0 0 5px 5px"
                      : index + 1 === produtoAquisicao.length
                      ? "0 0 5px 5px"
                      : "0"
                  }
                  value={
                    !item.editing
                      ? item.valor
                      : produtoAquisicaoDraft[index]?.valor
                  }
                  style={{ textAlign: "right" }}
                />
              ) : (
                <InputFormatRealBrasileiro
                  listaDeInputs
                  radiusborder={
                    produtoAquisicao.length >= 1 ? "5px 5px 0 0" : "5px"
                  }
                  value={produtoAquisicaoDraft[index].valor}
                  onChange={(_, valor) => {
                    // Converte o valor digitado para número
                    let valorNumerico = parseFloat(
                      valor.replace(/\./g, "").replace(",", ".")
                    );

                    // Se o valor for maior que somaValores, ajusta para somaValores
                    if (
                      valorNumerico >
                      somaValores + produtoAquisicao[index].valor
                    ) {
                      valorNumerico =
                        somaValores + produtoAquisicao[index].valor;
                    }

                    // Converte o valor novamente para o formato de string (com a vírgula)
                    const valorCorrigido = valorNumerico
                      .toFixed(2)
                      .replace(".", ",");

                    // Atualiza o estado com o valor corrigido
                    setProdutoAquisicaoDraft((prevDraft) => {
                      const updatedDraft = [...prevDraft]; // Fazendo uma cópia do array para garantir a imutabilidade
                      updatedDraft[index] = {
                        ...updatedDraft[index], // Mantém os outros valores do item
                        valor: valorCorrigido, // Atualiza o valor com o valor corrigido
                      };
                      return updatedDraft; // Retorna o array atualizado
                    });
                  }}
                />
              )}
            </CCol>
            <CColButtonsAcoes2Icons md={1}>
              <IconsEditInfo
                functionIconDelete={() =>
                  setProdutoAquisicao((prev) =>
                    prev.filter((_, i) => i !== index)
                  )
                }
                functionIconEdit={() => handleEdit(index)}
                functionIconCancel={() => handleEdit(index)}
                edicaoAtiva={item.editing}
                functionIconSave={() => handleSave(index)}
                iconEditShow={false}
                iconDeleteShow={!liquidacaoAtualId}
              />
            </CColButtonsAcoes2Icons>
          </RowForm>
        );
      })}
    </>
  );
};

export default ProdutoEAquisicaoAba;
