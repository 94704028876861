import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";

const IconsEditInfo = ({
  edicaoAtiva,
  functionIconEdit,
  iconEditShow = true,
  functionIconDelete,
  iconDeleteShow = true,
  functionIconSave,
  iconSaveShow = true,
  functionIconCancel,
  iconCancelShow = true,
}) => {
  return (
    <>
      {!edicaoAtiva && (
        <>
          {iconEditShow && (
            <Icon
              onClick={functionIconEdit}
              icon="bx:edit"
              color="#5971C8"
              height="30"
              style={{
                cursor: "pointer",
              }}
            />
          )}
          {iconDeleteShow && (
            <Icon
              onClick={functionIconDelete}
              icon="ic:baseline-delete"
              color="#E79900"
              height="30"
              style={{
                cursor: "pointer",
              }}
            />
          )}{" "}
        </>
      )}

      {edicaoAtiva && (
        <>
          {iconSaveShow && (
            <Icon
              onClick={functionIconSave}
              icon="fluent:save-24-filled"
              color="#008CFF"
              height="30"
              style={{
                cursor: "pointer",
              }}
            />
          )}
          {iconCancelShow && (
            <Icon
              onClick={functionIconCancel}
              icon="ic:baseline-cancel"
              height="30"
              color="#F24E1E"
              style={{
                cursor: "pointer",
              }}
            />
          )}{" "}
        </>
      )}
    </>
  );
};

export default IconsEditInfo;
