import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import { NavLink } from "react-router-dom";
import ButtonDropOrHover from "./ButtonDropOrHover";
import { NavLinksMenores, TitulosNavs } from "./SideBarComponent";

const ContabilidadeNav = ({ checkSidebar }) => {
  const arrayDropdownsPrincipais = [
    {
      titulo: "Receita",
      options: [
        {
          titulo: "Guia de Receita",
          path: "/contabilidade/receita/guia-de-receita/consulta",
        },
        {
          titulo: "Anulação de Receita",
          path: "/contabilidade/receita/anulacao-de-receita/consulta",
        },
      ],
      icon: "fluent:document-arrow-right-20-filled",
    },
    {
      titulo: "Despesa",
      options: [
        {
          titulo: "Nota de Empenho",
          path: "/contabilidade/despesa/consulta/nota-de-empenho",
        },
        {
          titulo: "Liquidação de Empenho",
          path: "/contabilidade/despesa/consulta/liquidacao-de-empenho",
        },
        {
          titulo: "Anulação da Despesa",
          path: "/contabilidade/despesa/consulta/anulacao-da-despesa",
        },
        {
          titulo: "Atualização Orçamentária",
          path: "/contabilidade/despesa/atualizacao-orcamentaria",
        },
      ],
      icon: "fluent:document-checkmark-20-filled",
    },

    {
      titulo: "Extra",
      options: [
        {
          titulo: "GRE - Receita",
          path: "/contabilidade/extra/gre-receita/consulta",
        },
        {
          titulo: "GDE - Despesa",
          path: "/contabilidade/extra/gde-despesa/consulta",
        },
        {
          titulo: "Anulação Extra",
          path: "/contabilidade/extra/anulacao-extra",
        },
        {
          titulo: "QDRDE",
          path: "/contabilidade/extra/qdrde/consulta",
        },
      ],
      icon: "fluent:document-text-extract-20-filled",
    },
    {
      titulo: "Restos a Pagar",
      options: [
        {
          titulo: "GRP - Despesa",
          path: "/contabilidade/restos-a-pagar/grp-despesa",
        },
        {
          titulo: "Liquidação RP",
          path: "/contabilidade/restos-a-pagar/liquidacao-rp",
        },
        {
          titulo: "Anulação RP",
          path: "/contabilidade/restos-a-pagar/anulacao-rp",
        },
        {
          titulo: "Inscrição RP",
          path: "/contabilidade/restos-a-pagar/inscricao-rp",
        },
      ],
      icon: "fluent:document-checkmark-20-filled",
    },
    {
      titulo: "Financeiro",
      options: [
        {
          titulo: "Pagamento",
          path: "/contabilidade/financeiro/pagamento",
        },
        {
          titulo: "Conciliação",
          path: "/contabilidade/financeiro/conciliacao",
        },
        {
          titulo: "Anulação Pagamento",
          path: "/contabilidade/financeiro/anulacao-pagamento",
        },
        {
          titulo: "Conta Bancária",
          path: "/contabilidade/financeiro/conta-bancaria/consulta",
        },
      ],
      icon: "fluent:document-arrow-left-20-filled",
    },
  ];

  return (
    <>
      <NavLink to={"/contabilidade/inicio"} className={"link_direto"}>
        <Icon icon="ion:home" width={25} />
        <span style={{ marginLeft: "3px" }}>Início</span>
      </NavLink>
      {arrayDropdownsPrincipais.map((item, index) => (
        <ButtonDropOrHover
          key={index}
          checkSidebar={checkSidebar}
          tituloButton={item.titulo}
          navOptions={item.options}
          iconSrc={item.icon}
        />
      ))}
      <NavLink to={"/contabilidade/pcasp"} className={"link_direto"}>
        <Icon icon="oi:list-rich" width={22} style={{ marginLeft: "2px" }} />
        <span style={{ marginLeft: "4px" }}>PCASP</span>
      </NavLink>

      <TitulosNavs>Recorrentes</TitulosNavs>
      <NavLinksMenores>
        <NavLink to={"/contabilidade/cadastros/eva"} className={"link_direto"}>
          <Icon icon="tabler:copy-plus-filled" width={25} />
          <span style={{ marginLeft: "3px" }}>EVA</span>
        </NavLink>
        <NavLink to={"/contabilidade/cadastros/evb"} className={"link_direto"}>
          <Icon icon="tabler:copy-plus" width={25} />
          <span style={{ marginLeft: "3px" }}>EVB</span>
        </NavLink>
      </NavLinksMenores>

      <TitulosNavs>Impressos</TitulosNavs>
      <NavLinksMenores>
        <NavLink to={"/contabilidade/pcasp"} className={"link_direto"}>
          <Icon icon="gg:loadbar-doc" width={25} />
          <span style={{ marginLeft: "3px" }}>Relatórios</span>
        </NavLink>
        <NavLink to={"/contabilidade/pcasp"} className={"link_direto"}>
          <Icon icon="mdi:file-document-box-check-outline" width={25} />
          <span style={{ marginLeft: "3px" }}>Leis e Documentos</span>
        </NavLink>
      </NavLinksMenores>

      <TitulosNavs>Ferramentas</TitulosNavs>

      <ButtonDropOrHover
        smallerType
        checkSidebar={checkSidebar}
        tituloButton={"Cadastros"}
        iconSrc={"ic:round-addchart"}
        navOptions={[
          {
            titulo: "QDR - Receita",
            path: "/contabilidade/cadastros/qdr-receita/consulta",
          },
          {
            titulo: "QDD - Despesa",
            path: "/contabilidade/consulta/qdd-despesa",
          },
          {
            titulo: "Fonte de Recursos",
            path: "/contabilidade/cadastros/fonte-de-recursos/consulta",
          },
          {
            titulo: "Licitação",
            path: "/contabilidade/cadastros/licitacao/consulta",
          },
          {
            titulo: "Divida Pública",
            path: "/contabilidade/cadastros/divida-publica",
          },
          {
            titulo: "Garantias Concedidas",
            path: "/contabilidade/cadastros/garantias-concedidas",
          },
          {
            titulo: "Obra",
            path: "/contabilidade/cadastros/obras/consulta",
          },
          {
            titulo: "Produtos - Aquisição",
            path: "/contabilidade/cadastros/produtos-aquisicao/consulta ",
          },
          {
            titulo: "CPF - CNPJ",
            path: "/contabilidade/cadastros/cpf-cnpj/consulta",
          },
          {
            titulo: "Desconto na Fonte",
            path: "/contabilidade/cadastros/desconto-na-fonte",
          },
          {
            titulo: "Dívida Pública",
            path: "/contabilidade/cadastros/divida-publica",
          },
          {
            titulo: "Convênio",
            path: "/contabilidade/cadastros/convenio/consulta",
          },
          {
            titulo: "Natureza de Rendimentos",
            path: "/contabilidade/cadastros/natureza-de-rendimentos",
          },
        ]}
      />
      <NavLinksMenores>
        <NavLink
          to={"/contabilidade/cadastro/utilitarios/"}
          className={"link_direto"}
        >
          <Icon
            icon={/*"tabler:file-import"*/ "carbon:gui-management"}
            width={25}
          />
          <span style={{ marginLeft: "3px" }}>Utilitários</span>
        </NavLink>
      </NavLinksMenores>
    </>
  );
};

export default ContabilidadeNav;
