export const optionsTipoAnulacao = [
  {
    label: "1 - Anulação - Remove o Registro Contábil",
    type: 1,
    value: "1 - Anulação - Remove o Registro Contábil",
  },
  {
    label: "2 - Restituição - Dedução da Receita Orçamentária",
    type: 2,
    value: "2 - Restituição - Dedução da Receita Orçamentária",
  },
];