import React, { useCallback, useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { Container } from "react-bootstrap";

import styled from "styled-components";

import { toast, ToastContainer } from "react-toastify";

import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";

import { HistoricoAba } from "./HistoricoAba";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";
import { TransferenciaRecebidaAba } from "./TransferenciaRecebidaAba";
import { RetencaoAba } from "./RetencaoAba";

import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import api from "../../../../utils/api";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { CurrentCityContext } from "../../../../contexts/CurrentCityContext";

import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import { optionsTranferencias } from "./optionsTranferenciasRecebidas";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const GreReceita = () => {
  const dataAtual = ReturnDataAtual()
    .replace(/\//g, "-")
    .split("-")
    .reverse()
    .join("-");

  const { id: paramsIdGreReceita } = useParams();

  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();
  const { userAuthData } = useContext(AuthContext);
  const { currentCity } = useContext(CurrentCityContext);

  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });

  const [optionsContribuinte, setOptionsContribuintes] = useState([
    { id: "", name: "", cpf: "" },
  ]);
  const [optionsContaBancariaCaixa, setOptionsContaBancariaCaixa] = useState([
    { id: "", nameConta: "", agencia: "" },
  ]);

  const [optionsQdrde, setOptionsQdrde] = useState([
    {
      id: "",
      titulo: "",
      funcao: "",
      qdrdeIsTranferencia: false,
      fontes: [
        { fonteId: null, valor: "", data: "", label: "", percentage: "" },
      ],
    },
  ]);

  const [optionsFonteDeRecursos, setOptionsFonteDeRecurso] = useState([
    { label: "", value: null },
  ]);

  const [optionsUnidadesGestoras, setOptionsUnidadesGestoras] = useState([
    { label: "", value: null, tipoNaturezaJuridica: "" },
  ]);

  const [optionsTipoTransferencia, setOptionsTipoTranferencias] =
    useState(optionsTranferencias);

  const [infosCadastro, setInfosCadastro] = useState({
    clienteId: currentClient.clienteId,
    num: "",
    evb: "",
    qdrdeId: null,
    qdrdeIsTranferencia: false,
    funcao: "",
    data: dataAtual,
    remessa: "",
    cpfContribuinteId: null,
    infoCaixaId: null,
    docFinanceiro: "",
    valor: 0,
    tipoTransferencia: "",
    historico: "",
    unidadeGestoraId: "",
    tipoNaturezaJuridica: "",
    fontes: [{ fonteId: null, valor: "", data: "", label: "", percentage: "" }],
  });

  const [inputContribuinte, setInputContribuinte] = useState("");
  const [inputContaBancaria, setInputContaBancaria] = useState("");
  const [inputQdrde, setInputQdrde] = useState("");
  const [inputUnidadeGestoraValue, setInputUnidadeGestoraValue] =
    useState(null);
  const [inputTipoTransferenciaValue, setInputTipoTransferenciaValue] =
    useState(null);

  const handleSelectQdrde = (option) => {
    const { fontes, funcao, qdrdeIsTranferencia } = option;
    const optionsFontes = formattedOptionsFonteDeRecursos(option.fontes);
    setOptionsFonteDeRecurso(optionsFontes);
    setInfosCadastro((prev) => ({
      ...prev,
      qdrdeId: option.id,
      funcao,
      fontes,
      qdrdeIsTranferencia,
    }));
    if (
      !qdrdeIsTranferencia &&
      abaSelecionada.nomeAba === "Transferência Recebida"
    )
      setAbaSelecionada({ nomeAba: "Histórico", numeroAba: 0 });
  };

  const handleSelectContribuinte = (optionContrubuinte) => {
    setInfosCadastro((prev) => ({
      ...prev,
      cpfContribuinteId: optionContrubuinte.id,
    }));
  };

  const handleSelectContaBancaria = (optionContaBancaria) => {
    setInfosCadastro((prev) => ({
      ...prev,
      infoCaixaId: optionContaBancaria.id,
    }));
  };

  const handleChangeValorReceita = (_, valor) => {
    const fontesDeRecursos = infosCadastro.fontes.filter(
      (item) => item.fonteId !== null
    );

    if (fontesDeRecursos.length === 1) {
      const fontes = fontesDeRecursos.map((item) => {
        return {
          ...item,
          valor,
        };
      });

      setInfosCadastro((prevState) => ({
        ...prevState,
        fontes,
        valor,
      }));

      return;
    }

    console.log(valor);
    setInfosCadastro((prev) => ({
      ...prev,
      valor,
    }));
  };

  const formattedDate = (date) => {
    const separatorDate = date.includes("/") ? "/" : "-";

    if (separatorDate === "-") {
      const [year, month, day] = date.split(separatorDate);
      return `${year}-${month}-${day}`;
    }

    const [day, month, year] = date.split(separatorDate);

    return `${year}-${month}-${day}`;
  };

  const handleRegisterGre = async () => {
    console.log(infosCadastro);

    const infosPostGre = {
      clienteId: infosCadastro.clienteId,
      qdrdeId: infosCadastro.qdrdeId,
      data: formattedDate(infosCadastro.data),
      contribuinteId: infosCadastro.cpfContribuinteId,
      caixaId: infosCadastro.infoCaixaId,
      docFinanceiro: infosCadastro.docFinanceiro,
      valor: infosCadastro.valor,
      historico: infosCadastro.historico,
      fontes: infosCadastro.fontes,
    };

    const emptyInfosCadastro = Object.values(infosPostGre).some(
      (info) => info === ""
    );
    console.log(emptyInfosCadastro);
    if (emptyInfosCadastro) {
      toast.info("Preencha todas as informações");
      return;
    }

    const hasFonteRecurso = infosPostGre.fontes.every((fonte) => fonte.fonteId);

    if (!hasFonteRecurso) {
      toast.info("Adicione uma Fonte de Recursos.");
      return;
    }

    const formattedFonteRecurso = infosPostGre.fontes.map((fonte) => {
      return {
        fonteId: fonte.fonteId,
        percentual: fonte.percentage.replace(/,/g, "."),
        valor: fonte.valor.replace(/,/g, "."),
      };
    });

    const completeWithZerosDocFinanceiro = String(
      infosCadastro.docFinanceiro
    ).padEnd(11, "0");

    const infos = {
      ...infosPostGre,
      docFinanceiro: completeWithZerosDocFinanceiro,
      fontes: formattedFonteRecurso,
      valor: infosPostGre.valor.replace(/,/g, "."),
      tpTransferencia: infosCadastro.qdrdeIsTranferencia
        ? infosCadastro.tipoTransferencia
        : "",
      unidadeGestoraId: infosCadastro.qdrdeIsTranferencia
        ? infosCadastro.unidadeGestoraId
        : "",
    };

    if (paramsIdGreReceita) {
      console.log(infos);

      await putInfosReceita(infos);
      return;
    }

    console.log(infos);

    await postInfosReceita(infos);
  };

  const resetFieldsGreReceita = () => {
    console.log(infosCadastro);

    const resetInfosCadastro = {
      clienteId: currentClient.clienteId,
      num: "",
      evb: "",
      qdrdeId: null,
      qdrdeIsTranferencia: false,
      funcao: "",
      data: dataAtual,
      remessa: "",
      cpfContribuinteId: null,
      infoCaixaId: null,
      docFinanceiro: "",
      valor: "0,00",
      tipoTransferencia: "",
      historico: "",
      unidadeGestoraId: "",
      tipoNaturezaJuridica: "",
      fontes: [
        { fonteId: null, valor: "", data: "", label: "", percentage: "" },
      ],
    };
    setInfosCadastro(resetInfosCadastro);
    setInputQdrde("");
    setInputContribuinte("");
    setInputContaBancaria("");
    setInputTipoTransferenciaValue(null);
    setInputUnidadeGestoraValue(null);
  };

  const postInfosReceita = async (postInfos) => {
    try {
      await api.post("gre", postInfos);
      resetFieldsGreReceita();
      toast.success("Cadastro realizado com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao criar o cadastro. Tente novamente mais tarde.");
    }
  };

  const putInfosReceita = async (putInfos) => {
    try {
      await api.put(`gre/${paramsIdGreReceita}`, putInfos);
      toast.success("Registro atualizado com sucesso");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar registro. Tente novamente mais tarde.");
    }
  };

  const formattedOptionsFonteDeRecursos = (fonts) => {
    const filteredFonts = fonts.filter((item) => item.fonteId !== null);

    if (!filteredFonts.length) return [{ label: "", value: null }];

    const formattedFonts = filteredFonts.map((item) => {
      return {
        label: item.label,
        value: item.fonteId,
      };
    });
    return formattedFonts;
  };

  const formattedFonts = (data) => {
    const fonts = data.map((item) => {
      const fontInfo = item.fonteInfo.find((font) => font.id === item.fonteId);
      const date = new Date(item.dataCriacao);
      return {
        data: date.toISOString().split("T")[0],
        fonteId: item.fonteId,
        valor: item.valor,
        label: fontInfo ? `${fontInfo.conta} - ${fontInfo.titulo}` : "",
        percentage: "0,00",
      };
    });
    return fonts;
  };

  const onChangeTextDocFinanceiro = (value) => {
    const inputValue = value.replace(/\D/g, "");
    const maskedValue = inputValue.slice(0, 11);
    setInfosCadastro((prevState) => ({
      ...prevState,
      docFinanceiro: maskedValue,
    }));
  };

  const getDataQdrde = useCallback(async () => {
    try {
      const resp = await api.get(
        `qdrde/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;
      console.log(data);

      const dataQdrde = data.map((item) => {
        const titulo = `${item.categoriaContaPcaspInfo[0].conta} - ${item.titulo}`;
        const fontes = formattedFonts(item.fontes);
        const qdrdeIsTranferencia = item.categoriaContaPcaspInfo[0].titulo
          .toLowerCase()
          .includes("transferências");
        return {
          id: item.id,
          titulo,
          funcao: item.categoriaContaPcaspInfo[0].funcao,
          fontes,
          qdrdeIsTranferencia,
        };
      });
      setOptionsQdrde(dataQdrde);
    } catch (error) {
      console.log(error);
    }
  }, [currentClient, currentYear]);

  const fetchOptionsContribuinte = useCallback(async () => {
    try {
      const resp = await api.get(`cpf/por-cliente/${currentClient.clienteId}`);
      const data = resp.data;
      const optionsCpf = data.map((item) => {
        return {
          id: item.pessoaId,
          name: item.nomeCompleto,
          cpf: FormatCpfPaste(item.cpf),
        };
      });

      setOptionsContribuintes(optionsCpf);
    } catch (error) {
      console.log(error);
    }
  }, [currentClient]);

  const fetchContas = useCallback(async () => {
    try {
      const resp = await api.get(
        `contaBancaria/cliente/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;
      const optionsContaBancaria = data.map((item) => {
        return {
          id: item.id,
          nameConta: item.nome,
          agencia: item.numeroConta,
        };
      });
      setOptionsContaBancariaCaixa(optionsContaBancaria);
    } catch (error) {
      console.log(error);
    }
  }, [currentClient, currentYear]);

  const formattedUnidadesGestoras = useCallback(() => {
    const unidades = userAuthData.clientes
      .map((item) => {
        const hasCityUnidade =
          item.endereco.municipio === currentCity.municipio &&
          item.endereco.estado === currentCity.estado;
        const currentClientSelected =
          currentClient.clienteId === item.clienteId;

        if (!hasCityUnidade || currentClientSelected)
          return { label: "", value: null };
        return {
          label: item.nomeFantasia,
          value: item.clienteId,
          tipoNaturezaJuridica: item.tipoNaturezaJuridica,
        };
      })
      .filter((item) => item.value);
    console.log(unidades);

    setOptionsUnidadesGestoras(unidades);
  }, [currentCity, userAuthData, currentClient]);

  const formattedGreReceitaById = (data) => {
    console.log(optionsQdrde);

    const infosContaQdrde = optionsQdrde.find(
      (option) => option.id === data.gre.qdrdeId
    );
    console.log(infosContaQdrde);

    if (!infosContaQdrde) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
    }

    const infosContribuinte = optionsContribuinte.find(
      (option) => option.id === data.gre.contribuinteId
    );

    if (!infosContribuinte) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
    }
    console.log(infosContribuinte);

    const infosCaixa = optionsContaBancariaCaixa.find(
      (option) => option.id === data.gre.caixaId
    );

    if (!infosCaixa) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
    }
    console.log(infosCaixa);

    const unidadeGestora = optionsUnidadesGestoras.find(
      (unidade) => unidade.value === data.gre.unidadeGestoraId
    );

    console.log(unidadeGestora);

    if (!unidadeGestora && data.gre.unidadeGestoraId !== null) {
      toast.error("Erro ao buscar Unidade. Tente novamente mais tarde.");
      return;
    }

    const date = data.gre.data.split("T")[0];
    const [year, month, day] = date.split("-");
    const dateFormatted = `${day}/${month}/${year}`;

    const formattedFonteRecurso = data.gre.fontes
      .map((fonte) => {
        const date = fonte.dataCriacao.split("T")[0];
        const [year, month, day] = date.split("-");
        const dateFormatted = `${day}/${month}/${year}`;

        const fonteInfos = fonte.fonteInfo.map((fonteInfo) => {
          return {
            fonteId: fonteInfo.id,
            valor: fonte.valor,
            data: dateFormatted,
            label: `${fonteInfo.conta} - ${fonteInfo.titulo}`,
            percentage: fonte.percentual,
          };
        });
        return fonteInfos;
      })
      .flat();

    const infosGreReceita = {
      clienteId: data.gre.clienteId,
      num: data.gre.id,
      evb: "",
      qdrdeId: data.gre.qdrdeId,
      qdrdeIsTranferencia: infosContaQdrde.qdrdeIsTranferencia,
      funcao: infosContaQdrde.funcao,
      data: dateFormatted,
      remessa: "",
      cpfContribuinteId: data.gre.contribuinteId,
      infoCaixaId: data.gre.caixaId,
      docFinanceiro: data.gre.docFinanceiro,
      valor: data.gre.valor,
      tipoTransferencia: data.gre.tpTransferencia ?? "",
      historico: data.gre.historico,
      unidadeGestoraId: data.gre.unidadeGestoraId ?? "",
      tipoNaturezaJuridica: unidadeGestora?.tipoNaturezaJuridica ?? "",
      fontes: formattedFonteRecurso,
    };
    console.log(infosGreReceita);
    const fonteOptions = formattedOptionsFonteDeRecursos(
      infosGreReceita.fontes
    );
    const filterOptionsTipoTranferencia = optionsTipoTransferencia.filter(
      (option) => option.label === data.gre.tpTransferencia
    );
    setOptionsFonteDeRecurso(fonteOptions);
    setInfosCadastro(infosGreReceita);
    setInputQdrde(`${infosContaQdrde.titulo}`);
    setInputContribuinte(
      `${infosContribuinte.cpf} - ${infosContribuinte.name}`
    );
    setInputContaBancaria(`${infosCaixa.nameConta} - ${infosCaixa.agencia}`);
    setInputUnidadeGestoraValue(unidadeGestora.value);
    setOptionsTipoTranferencias(
      filterOptionsTipoTranferencia.length
        ? filterOptionsTipoTranferencia
        : optionsTipoTransferencia
    );
    setInputTipoTransferenciaValue(parseInt(data.gre.tpTransferencia[0]) ?? "");
  };
  console.log(inputQdrde);
  console.log(inputContribuinte);
  console.log(inputContaBancaria);

  const fetchGreReceitaById = useCallback(async () => {
    console.log("oi");

    try {
      const resp = await api.get(`gre/${paramsIdGreReceita}`);
      const data = resp.data;
      const hasOptionsQdrde = optionsQdrde.filter((item) => item.id).length;
      const hasOptionsContribuinte = optionsContribuinte.filter(
        (item) => item.id
      ).length;
      const hasOptionsCaixa = optionsContaBancariaCaixa.filter(
        (item) => item.id
      ).length;
      if (hasOptionsQdrde && hasOptionsCaixa && hasOptionsContribuinte) {
        formattedGreReceitaById(data);
      }
      console.log(data);
    } catch (error) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    optionsContaBancariaCaixa,
    optionsContribuinte,
    optionsQdrde,
    paramsIdGreReceita,
  ]);

  useEffect(() => {
    formattedUnidadesGestoras();
  }, [formattedUnidadesGestoras]);

  useEffect(() => {
    fetchOptionsContribuinte();
    fetchContas();
    getDataQdrde();
  }, [fetchContas, fetchOptionsContribuinte, getDataQdrde]);

  useEffect(() => {
    if (paramsIdGreReceita) {
      fetchGreReceitaById();
    }
  }, [fetchGreReceitaById, paramsIdGreReceita]);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"GRE - Receita"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle="Receita Extraorçamentária - GRE"
        ButtonSaveFunction={handleRegisterGre}
        PaginaConsulta={"/contabilidade/extra/gre-receita/consulta"}
        BotaoNovoAtivo={false}
      />
      <ToastContainer />
      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput
            disabled
            placeholder="Número"
            label="Número"
            value={infosCadastro.num}
          />
        </CColNumero>
        <CCol md={2}>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="EVB"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="QDRDE"
            options={optionsQdrde}
            labelFormat={(item) => `${item.titulo} `}
            inputValue={inputQdrde}
            setInputValue={setInputQdrde}
            onOptionSelect={handleSelectQdrde}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            onChange={(e) =>
              setInfosCadastro((prev) => ({
                ...prev,
                data: e.target.value,
              }))
            }
            externalValue={infosCadastro.data}
          />
        </CCol>
      </RowForm>

      {infosCadastro.funcao && (
        <RowInfosAcao>
          <CCol>
            <p>
              <span>{infosCadastro.funcao}</span>
            </p>
          </CCol>
        </RowInfosAcao>
      )}

      <RowForm style={{ marginTop: "-14px" }}>
        <CColNumero md={1}>
          <FloatingLabelInput disabled placeholder="Remessa" label="Remessa" />
        </CColNumero>
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="Contribuinte"
            options={optionsContribuinte}
            labelFormat={(item) => `${item.cpf} - ${item.name}`}
            inputValue={inputContribuinte}
            setInputValue={setInputContribuinte}
            onOptionSelect={handleSelectContribuinte}
          />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="Caixa e Equivalente Caixa"
            options={optionsContaBancariaCaixa}
            labelFormat={(item) => `${item.nameConta} - ${item.agencia}`}
            inputValue={inputContaBancaria}
            setInputValue={setInputContaBancaria}
            onOptionSelect={handleSelectContaBancaria}
          />
        </CCol>

        <CCol md={2}>
          <FloatingLabelInput
            type="text"
            label="Doc Financeiro"
            placeholder="Doc Financeiro"
            value={infosCadastro.docFinanceiro}
            onChange={(e) => onChangeTextDocFinanceiro(e.target.value)}
          />
        </CCol>

        <CCol md={2}>
          <InputFormatRealBrasileiro
            externalValue={infosCadastro.valor}
            disabled={infosCadastro.qdrdeId === null}
            onChange={handleChangeValorReceita}
          />
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={[
          "Histórico",
          "Fonte de Recursos",
          `${
            infosCadastro.qdrdeIsTranferencia ? "Transferência Recebida" : ""
          }`,
          "Retenção",
        ]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && (
        <HistoricoAba
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
        />
      )}
      {abaSelecionada.nomeAba === "Fonte de Recursos" && (
        <FonteDeRecursosAba
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
          optionsFonteDeRecursos={optionsFonteDeRecursos}
        />
      )}
      {abaSelecionada.nomeAba === "Transferência Recebida" && (
        <TransferenciaRecebidaAba
          optionsUnidadesGestoras={optionsUnidadesGestoras}
          // handleSelectTipoTransferencia={handleSelectTipoTransferencia}
          // handleSelectUnidadeGestora={handleSelectUnidadeGestora}
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
          currentClient={currentClient}
          inputUnidadeGestoraValue={inputUnidadeGestoraValue}
          setInputUnidadeGestoraValue={setInputUnidadeGestoraValue}
          inputTipoTransferenciaValue={inputTipoTransferenciaValue}
          setInputTipoTransferenciaValue={setInputTipoTransferenciaValue}
          optionsTipoTransferencia={optionsTipoTransferencia}
          setOptionsTipoTranferencias={setOptionsTipoTranferencias}
          // handleOptionUnidadeGestora={handleOptionUnidadeGestora}
        />
      )}
      {abaSelecionada.nomeAba === "Retenção" && <RetencaoAba />}
    </Container>
  );
};

export default GreReceita;
