import axios from "axios";

const api = axios.create({
  baseURL: "https://api.savest.com.br/",
  // baseURL: "http://localhost:3000",
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

api.interceptors.request.use(
  (config) => {
    // Atualiza o cabeçalho de autorização com o token atual
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
